import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./NewLogin.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
import "./css/util.css";
import "./css/main.css";
import { swalErrorMessage } from "../../Constant/swalMessage";
import { Link, useHistory } from "react-router-dom";
import {
  googleLoginAction,
  loginAction,
} from "../NewDevelopment/Actions/CommonActions";
import { checkValidationAccountAction } from "../NewDevelopment/Store/Account/Account.action";
import { GoogleLogin } from "react-google-login";
import img from "./images/vodex1.svg";
import {
  goToMentionedRoutes,
  isVodexLoggedin,
} from "../NewDevelopment/CommonComponents/Common";
function NewLogin() {
  const dispatch = useDispatch();
  const history = useHistory();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { publicKey },
  } = reducer;
  const [spin, setSpin] = useState(false);
  const [googleButtonDisable, setGooogleButtonDisable] = useState(false);
  const [state, setState] = useState<any>({
    email: "",
    password: "",
  });
  const { email, password } = state;
  useEffect(() => {
    const init = async () => {
      setSpin(true);
      let token = localStorage.getItem("token");
      if (token) {
        let res: any = await dispatch(
          checkValidationAccountAction({ token: `Bearer ${token}` })
        );
        if (res?.errMsg === "authorized") {
          if (res) {
            const { accounts, lastLogin, _id } = res.data;
            let find =
              accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
              accounts[0];
            if (find && !find.accountName) {
              localStorage.setItem("account-id", find?._id);
              history.push(`/account-name`);
            } else {
              await goToMentionedRoutes(find, _id, history, dispatch);
            }
          }
        }
      }

      setSpin(false);
    };

    if (publicKey) init();
  }, [publicKey]);
  const signIn = async (e) => {
    e.preventDefault();
    setSpin(true);
    try {
      let obj = {
        email: email.trim(),
        password: password,
      };
      let res: any = await dispatch(loginAction(obj));
      if (res) {
        const { accounts, lastLogin, _id } = res;
        let find =
          accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
          accounts[0];
        if (find && !find.accountName) {
          localStorage.setItem("account-id", find?._id);
          history.push(`/account-name`);
        } else {
          await goToMentionedRoutes(find, _id, history, dispatch);
        }
      }
    } catch (error) {
      swalErrorMessage("Email or Password is incorrect");
    }
    setSpin(false);
  };

  const handleChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  useEffect(() => {
    history.push("/");
  }, []);

  const onFailure = (error) => {
    // alert(error);
    swalErrorMessage(error?.err);
  };

  const googleResponse = async (response) => {
    setGooogleButtonDisable(true);
    if (response.tokenId) {
      let obj = {
        token: response.tokenId,
      };
      let res: any = await dispatch(googleLoginAction(obj));
      if (res) {
        const { accounts, lastLogin, _id } = res;
        let find =
          accounts?.find((acc) => acc?._id === lastLogin?.accountId) ||
          accounts[0];
        if (find && !find.accountName) {
          localStorage.setItem("account-id", find?._id);
          history.push(`/account-name`);
        } else {
          await goToMentionedRoutes(find, _id, history, dispatch);
        }
      }
    }
    setGooogleButtonDisable(false);
  };

  return (
    <div className="limiter">
      <div
        className={`container-login100  ${
          !isVodexLoggedin() && "whitelabel-background"
        }`}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-30 p-b-54">
          <form className="login100-form validate-form" onSubmit={signIn}>
            {isVodexLoggedin() && (
              <span className="login100-form-title p-b-1">
                <img src={img} height={"140px"} />
              </span>
            )}
            <span className="login100-form-title p-b-10">Sign In</span>

            <div
              className="wrap-input100 validate-input m-b-23"
              data-validate="Username is reauired"
            >
              <span className="label-input100">Email</span>
              <input
                className="input100"
                type="email"
                name="email"
                placeholder="Type your email"
                required={true}
                value={email}
                onChange={(e) =>
                  handleChange("email", e.target.value.toLowerCase())
                }
              />
              <span className="focus-input100" data-symbol="&#xf206;"></span>
            </div>

            <div
              className="wrap-input100 validate-input"
              data-validate="Password is required"
            >
              <span className="label-input100">Password</span>
              <input
                className="input100"
                type="password"
                name="password"
                placeholder="Type your password"
                required={true}
                value={password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <span className="focus-input100" data-symbol="&#xf190;"></span>
            </div>

            <div className="text-right p-t-8 p-b-31">
              <Link to={"/reset-password"}>Forgot password?</Link>
            </div>

            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn"></div>
                <button
                  className="login100-form-btn"
                  type="submit"
                  disabled={spin}
                >
                  {spin ? "Signing In" : "Sign In"}
                </button>
              </div>
            </div>
          </form>

          <GoogleLogin
            disabled={googleButtonDisable}
            className="googleLogin"
            clientId={process.env.REACT_APP_PUBLIC_GOOGLE_CLIENT_ID || ""}
            onSuccess={googleResponse}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
            icon={true}
          />

          <div className="flex-col-c p-t-38">
            <Link to="/register" className="txt1 p-b-17">
              Don't have an account?{" "}
              <span style={{ color: "#ff5e00" }}>Sign up</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewLogin;
