import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { swalWarningMessage } from "../../../../Constant/swalMessage";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const UpdateProfile = ({
  open,
  formData,
  setFormData,
  handleModalClose,
  updateUserDetails,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { firstName, lastName, phone, email, address, city, pincode } =
    formData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (["phone", "pincode"].includes(name)) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/[a-zA-Z]/g, ""),
      }));
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const checkWhiteSpace = (str) => {
    return !str || !str.trim();
  };

  const handleSubmit = () => {
    let obj = {
      firstName,
      lastName,
      phone,
      email,
      city,
      address,
      pincode,
    };

    for (let key in obj) {
      if (checkWhiteSpace(obj[key])) {
        swalWarningMessage("All fields are required.");
        return;
      }
    }
    updateUserDetails(formData);
  };

  const handleClose = () => {
    setIsVisible(false);
    handleModalClose();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return ReactDOM.createPortal(
    <Box
      ref={containerRef}
      sx={{
        position: "fixed",
        bottom: "20px",
        left: open ? "280px" : "90px",
        width: "500px",
        bgcolor: "white",
        boxShadow: 24,
        borderRadius: "8px",
        zIndex: 100,
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(20px)",
        transition: "opacity 0.3s ease, transform 0.3s ease",
        maxHeight: "calc(80vh - 0)",
      }}
    >
      <Box
        sx={{
          pt: 2,
          pb: 1.5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "150px",
          "@media (max-height: 600px)": {
            pt: 1.5,
            pb: 1,
          },
        }}
      >
        <Avatar
          sx={{
            backgroundColor: "#D04D00",
            width: 60,
            height: 60,
            fontSize: "2rem",
            mb: 1,
            transition: "transform 0.3s ease",
            "&:hover": { transform: "scale(1.1)" },
          }}
        >
          {formData.email?.charAt(0)?.toUpperCase()}
        </Avatar>
        <Typography className="font-16-600-grey">
          {formData.firstName} {formData.lastName}
        </Typography>
        <Typography className="font-16-grey" gutterBottom>
          {formData.email}
        </Typography>
      </Box>
      <Box
        px={2.5}
        pt={1.5}
        borderTop="1px solid #D1D9EF"
        borderBottom="1px solid #D1D9EF"
        sx={{
          maxHeight: "calc(70vh - 0px)",
          overflowY: "auto",
          scrollbarWidth: "thin",
          scrollbarColor: "#CCD2DE transparent",
          "@media (max-height: 600px)": {
            maxHeight: "calc(60vh + 10px)",
          },
          "@media (max-height: 550px)": {
            maxHeight: "calc(50vh - 0px)",
          },
          "@media (max-height: 450px)": {
            maxHeight: "calc(40vh - 0px)",
          },
        }}
      >
        <Box display="flex" gap={2.5}>
          <Box width={1 / 2} mb={1}>
            <Typography className="font-14-gery mb-1">First Name</Typography>
            <TextField
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your first name"
              sx={commonStyles.inputField}
              inputProps={{ maxLength: 25 }}
            />
          </Box>
          <Box width={1 / 2} mb={1}>
            <Typography className="font-14-gery mb-1">Last Name</Typography>
            <TextField
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your last name"
              sx={commonStyles.inputField}
              inputProps={{ maxLength: 25 }}
            />
          </Box>
        </Box>
        <Box mb={1}>
          <Typography className="font-14-gery mb-1">Email</Typography>
          <TextField
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="mailid@gmail.com"
            sx={commonStyles.inputField}
            disabled
            inputProps={{ maxLength: 50 }}
          />
        </Box>
        <Box mb={1}>
          <Typography className="font-14-gery mb-1">Phone number</Typography>
          <TextField
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your Phone Number"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 18 }}
          />
        </Box>
        <Box mb={1}>
          <Typography className="font-14-gery mb-1">Address</Typography>
          <TextField
            name="address"
            value={formData.address}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Enter your address here"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 100 }}
          />
        </Box>
        <Box display="flex" gap={2.5}>
          <Box width={1 / 2} mb={1}>
            <Typography className="font-14-gery mb-1">City</Typography>
            <TextField
              name="city"
              value={formData.city}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your city"
              sx={commonStyles.inputField}
              inputProps={{ maxLength: 35 }}
            />
          </Box>
          <Box width={1 / 2} mb={1}>
            <Typography className="font-14-gery mb-1">Pincode</Typography>
            <TextField
              name="pincode"
              value={formData.pincode}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              placeholder="Enter your Pincode"
              sx={commonStyles.inputField}
              inputProps={{ maxLength: 10 }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          px: 2.5,
          pb: 1.5,
          display: "flex",
          justifyContent: "flex-end",
          mt: 1.5,
          gap: 2,
          "@media (max-height: 600px)": {
            pb: 1,
          },
        }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            backgroundColor: "white",
            color: "#555555",
            borderRadius: "8px",
            textTransform: "capitalize",
            boxShadow: "none",
            height: "40px",
            borderColor: "#CCD2DE",
            "&:hover": {
              backgroundColor: "white",
              borderColor: "#CCD2DE",
            },
          }}
        >
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            fontSize: 13,
            fontWeight: 500,
            backgroundColor: "#FF5E00BF",
            color: "white",
            borderRadius: "8px",
            textTransform: "capitalize",
            boxShadow: "none",
            height: "40px",
            "&:hover": {
              backgroundColor: "#FF5E00BF",
              boxShadow: "none",
            },
          }}
        >
          Update profile
        </Button>
      </Box>
    </Box>,
    document.body
  );
};

export default UpdateProfile;
