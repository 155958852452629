import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneInputs = ({ disabled, handleChange, value }) => {
  return (
    <PhoneInput
      disabled={disabled}
      specialLabel={""}
      country={"us"}
      onChange={handleChange}
      value={value}
      enableLongNumbers={true}
      inputProps={{
        name: "phone",
        required: true,
      }}
      buttonStyle={{
        background: "#f4f4f4",
        padding: "10px",
        border: "1px solid #E6EBF7",
        backgroundColor: "#F5F7FB",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
      }}
      inputStyle={{
        width: "100%",
        padding: "20px 80px",
        border: "1px solid #CCD2DE",
      }}
      containerStyle={{
        borderRadius: "8px",
      }}
    />
  );
};

export default PhoneInputs;
