import React, { useEffect, useState } from "react";
import { FilterList } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Spreadsheet from "react-spreadsheet";
import { LoadingSlides } from "../CommonComponents/Common";
import CustomScrollbars from "../CommonComponents/CustomScrollbars";
import CommonModal from "../CommonComponents/Modal/CommonModal";
import BaseButton from "../Common/Buttons/BaseButton";

interface SheetsProps {
  sheetsData: { value: string }[][];
  currentPage: number;
  handlePrevious: () => void;
  handleNext: () => void;
  filters: Array<{ columnName: string; operator: string; value: string }>;
  setFilters: React.Dispatch<
    React.SetStateAction<
      Array<{ columnName: string; operator: string; value: string }>
    >
  >;
  fetchSheetsData: any;
  fetchSheetsData1: any;
  sheetsModalLoading: boolean;
}

const Sheets: React.FC<SheetsProps> = ({
  sheetsData,
  currentPage,
  handlePrevious,
  handleNext,
  filters,
  setFilters,
  fetchSheetsData,
  fetchSheetsData1,
  sheetsModalLoading,
}) => {
  const [filteredData, setFilteredData] = useState(sheetsData);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const columnHeaders =
    sheetsData.length > 0 ? sheetsData[0].map((cell) => cell.value) : [];

  useEffect(() => {
    fetchSheetsData([]);
  }, [currentPage]);

  useEffect(() => {
    setFilteredData(sheetsData);
  }, [sheetsData]);

  const applyFilters = async () => {
    setFilterModalOpen(false);
    await fetchSheetsData(filters);
  };

  const updateFilter = (index: number, e) => {
    const { name, value } = e.target;
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [name]: value };
    setFilters(newFilters);
  };

  const addFilter = () => {
    const lastFilter = filters[0];

    if (!lastFilter.columnName || !lastFilter.operator || !lastFilter.value) {
      return;
    }

    setFilters([
      { columnName: "", operator: "contains", value: "" },
      ...filters,
    ]);
  };

  const removeFilter = (index: number) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const clearFilters = () => {
    setFilters([{ columnName: "", operator: "contains", value: "" }]);
    fetchSheetsData([]);
    setFilterModalOpen(false);
  };

  const modalClose = () => {
    setFilterModalOpen(false);
  };

  const modalOpen = () => {
    setFilters([...filters]);
    setFilterModalOpen(true);
  };

  const isValidURL = (value: string) => {
    try {
      return Boolean(new URL(value));
    } catch (_) {
      return false;
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" alignItems="center" pb={1}>
        <IconButton onClick={modalOpen}>
          <FilterList />
        </IconButton>
      </Box>

      {filterModalOpen && (
        <CommonModal
          show={filterModalOpen}
          toggle={modalClose}
          customClassName="sheets-filters"
          heading="Apply Filters"
          body={
            <Box display="flex" flexDirection="column" gap={1}>
              {filters?.map((filter, index) => (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  key={index}
                >
                  <Select
                    name="columnName"
                    value={filter.columnName}
                    onChange={(e) => updateFilter(index, e)}
                    displayEmpty
                    size="small"
                    sx={styles.select}
                  >
                    <MenuItem value="">
                      <em>Select Column</em>
                    </MenuItem>
                    {columnHeaders.map((header, i) => (
                      <MenuItem key={i} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>

                  <Select
                    name="operator"
                    value={filter.operator}
                    onChange={(e) => updateFilter(index, e)}
                    size="small"
                    sx={styles.select}
                  >
                    <MenuItem value="=">=</MenuItem>
                    <MenuItem value="!=">!=</MenuItem>
                    <MenuItem value="<">{"<"}</MenuItem>
                    <MenuItem value=">">{">"}</MenuItem>
                    <MenuItem value="contains">Contains</MenuItem>
                  </Select>

                  <TextField
                    size="small"
                    name="value"
                    placeholder="Enter value"
                    value={filter.value || ""}
                    onChange={(e) => updateFilter(index, e)}
                    sx={styles.textField}
                  />
                  {index !== 0 && (
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => removeFilter(index)}
                      sx={styles.removeButton}
                    >
                      X
                    </Button>
                  )}
                  {index === 0 && (
                    <Button
                      variant="outlined"
                      onClick={addFilter}
                      sx={styles.filterButton}
                      disabled={
                        !filter?.columnName ||
                        !filter?.operator ||
                        !filter?.value
                      }
                    >
                      + Add
                    </Button>
                  )}
                </Stack>
              ))}

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={clearFilters}
                    sx={styles.actionButton}
                  >
                    Clear Filters
                  </Button>

                  <BaseButton
                    title="Apply Filters"
                    variant="contained"
                    handleClick={applyFilters}
                  />
                </Stack>
              </Box>
            </Box>
          }
          footer={false}
          handleSave={() => {}}
          size={660}
        />
      )}

      <CustomScrollbars height="50vh">
        {sheetsModalLoading ? (
          <Box height="80%" className="flex-xy-center">
            <LoadingSlides bgColor="#FF5E00" />
          </Box>
        ) : !filteredData || filteredData.length === 1 ? (
          <Typography textAlign="center" padding={2}>
            No Data Available
          </Typography>
        ) : (
          <Box className="spreadsheet-container">
            <Spreadsheet
              data={filteredData}
              DataViewer={({ cell }: any) =>
                isValidURL(cell?.value) ? (
                  <a
                    href={cell.value}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#007BFF",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      if (!e.ctrlKey && !e.metaKey) {
                        e.preventDefault();
                        window.open(cell.value, "_blank");
                      }
                    }}
                  >
                    {cell.value}
                  </a>
                ) : (
                  <span>{cell?.value}</span>
                )
              }
            />
          </Box>
        )}
      </CustomScrollbars>

      <Stack
        direction="row"
        sx={styles.bottomContainer}
        className="pagination-container"
      >
        <Box color="#555555" display="flex" gap={1.5} alignItems="center">
          <Typography fontSize={12} fontWeight={500}>
            Total{" "}
          </Typography>
          <Typography
            component="span"
            fontSize={12}
            fontWeight={500}
            border={1}
            borderColor="#CCD2DE"
            px={1.5}
            py={0.25}
            borderRadius={2}
          >
            {filteredData?.length > 0
              ? `${filteredData?.length - 1} row(s)`
              : "0 row(s)"}
          </Typography>
        </Box>

        <Box display="flex" gap={3}>
          <Button
            variant="outlined"
            onClick={handlePrevious}
            disabled={currentPage === 1}
            sx={styles.paginationButton}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            onClick={handleNext}
            sx={styles.paginationButton}
          >
            Next
          </Button>
        </Box>
      </Stack>
    </>
  );
};

const styles = {
  filterButton: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    border: "1px solid #B0B0B0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
      border: "1px solid #B0B0B0",
    },
  },
  select: {
    minWidth: 150,
    "& .MuiSelect-select": {
      fontSize: 13,
      color: "#333333",
    },
  },
  textField: {
    minWidth: 150,
    "& .MuiInputBase-input": {
      fontSize: 13,
      color: "#333333",
    },
  },
  removeButton: {
    minWidth: 40,
    fontSize: 12,
  },
  actionButton: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    border: "1px solid #B0B0B0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
      border: "1px solid #B0B0B0",
    },
  },
  bottomContainer: {
    p: 1,
    paddingLeft: 3,
    border: "1px solid #CCD2DE",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  paginationButton: {
    color: "#666666",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    border: "1px solid #CCD2DE",
    padding: "4px 20px",
    "&:hover": {
      borderColor: "#5AA552",
    },
  },
};

export default Sheets;
