import React, { useEffect } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import { ReactComponent as FilterIcon } from "../../../assets/icons/sort.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh.svg";
import useDebounce from "../../Hooks/useDebounce";
import SearchBar from "../Common/Search/SearchBar";
import BaseButton from "../Common/Buttons/BaseButton";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const CallHistoryHeader = ({
  setIsReady,
  setSearchText,
  exportToCSV,
  toggleFilterDrawer,
  totalCalls,
  exportLoad,
  searchTerm,
  setSearchTerm,
  applyRefresh,
  loading,
}) => {
  const debouncedSearchTerm = useDebounce(searchTerm, 3000);

  useEffect(() => {
    setIsReady(true);
    setSearchText(debouncedSearchTerm);
    localStorage.setItem("callHistorySearchTerm", debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      sx={{ marginBottom: 2 }}
    >
      <Box className="flex-xy-center" gap={2}>
        <Typography className="font-20-grey">Call History</Typography>
        <Typography
          className="font-14-lightgrey"
          component="span"
          sx={commonStyles.textInfo}
        >
          Total calls{" "}
          <span className="font-20-700-lightgrey">{totalCalls || 0}</span>
        </Typography>
      </Box>
      <Box display="flex" gap={1}>
        <SearchBar
          searchText={searchTerm}
          handleChange={(e) => setSearchTerm(e.target.value)}
        />
        <BaseButton
          title="Export"
          variant="outlined"
          startIcon={<ExportIcon height={14} width={14} />}
          loading={exportLoad}
          handleClick={exportToCSV}
          styles={{ px: 2, fontSize: 12 }}
        />
        <IconButton
          title="Refresh"
          onClick={applyRefresh}
          sx={refreshButtonStyles}
          disabled={loading}
        >
          <RefreshIcon className="refresh-icon" height={14} width={14} />
        </IconButton>
        <IconButton
          title="Apply Filters"
          onClick={toggleFilterDrawer}
          sx={{
            px: 1.05,
            py: 0,
            borderRadius: 2,
            color: "#D1D9EF",
            border: 1,
            "&:hover": {
              borderColor: "#666",
            },
          }}
        >
          <FilterIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};

export default CallHistoryHeader;

const refreshButtonStyles = {
  borderRadius: 2,
  px: 1.25,
  color: "#D1D9EF",
  border: 1,
  "&:hover": {
    borderColor: "#666",
  },
};
