import React from "react";
import Canvas from "./components/Canvas";
import "../../../assets/css/page/MultiAgent.scss";
function MultiAgent() {
  return (
    <div className="m-0 p-0">
      <Canvas />
    </div>
  );
}

export default MultiAgent;
