import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import OnboardingContainer from "./Container";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const AgentSlide = ({ obj }) => {
  const { mainState, onChange, activeIndex, previous, next } = obj;
  const { agentName, agentId, organisationName, greetingMessage } = mainState;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      next();
    }
  };
  return (
    <OnboardingContainer>
      <Box mx={2}>
        <Typography className="font-18-darkGrey" textAlign={"center"}>
          Welcome to the future of calling! Let's customize your experience!
        </Typography>

        <Box mt={3}>
          <form onChange={onChange}>
            <Box bgcolor={"#FFFFFF"} className="input-container">
              {!agentName && (
                <span className="custom-placeholder">
                  <strong>Name your bot*</strong>{" "}
                  {`(AI agent name for introduction to customers)`}
                </span>
              )}
              <input
                name="agentName"
                className="common-ip"
                disabled={agentId ? true : false}
                value={agentName}
                onKeyDown={handleKeyPress}
                maxLength={25}
              />
            </Box>
            <Box bgcolor={"#FFFFFF"} mt={2} className="input-container">
              {!organisationName && (
                <span className="custom-placeholder">
                  <strong>Your organization name* </strong>{" "}
                  {`(Company name for AI agent)`}
                </span>
              )}
              <input
                className="common-ip"
                name="organisationName"
                disabled={agentId ? true : false}
                value={organisationName}
                onKeyDown={handleKeyPress}
                maxLength={55}
              />
            </Box>
            <Box bgcolor={"#FFFFFF"} mt={2} className="input-container">
              {!greetingMessage && (
                <span className="custom-placeholder">
                  <strong>Greeting message* </strong>{" "}
                  {` (How should your AI agent greet customers)`}
                </span>
              )}
              <input
                className="common-ip"
                name="greetingMessage"
                disabled={agentId ? true : false}
                value={greetingMessage}
                onKeyDown={handleKeyPress}
              />
            </Box>

            <Box mt={3} mx={{ xs: 0, sm: 4 }}>
              {activeIndex !== 3 && (
                <Grid container spacing={2}>
                  {activeIndex !== 0 && (
                    <Grid item xs={12} sm={6}>
                      <Button
                        sx={{ ...commonStyles.clearButton, width: "100%" }}
                        onClick={previous}
                      >
                        Previous
                      </Button>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <Button
                      sx={{ ...commonStyles.saveButton, width: "100%" }}
                      onClick={next}
                    >
                      Save and Next
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </OnboardingContainer>
  );
};

export default AgentSlide;
