import React, { lazy, useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { axiosClient } from "../../../Constant/apiConstant";
import { changeMenu } from "../Store/Account/Account.action";
import { getProjectByIdAction } from "../Projects/Store/projects.action";
import SkeletonLodingWithCards from "../../../Constant/SkeletonLoding";
import {
  getProjectAccess,
  isEmpty,
  isVodexLoggedin,
} from "../CommonComponents/Common";
import { getCallSettingAction } from "./CallSettings/Store/callSetting.action";
import "./index.css";
import { useParams } from "react-router-dom";
import AudienceList from "../Audiences/AudienceList";
import Messaging from "../ProjectSettings/Messaging";
import Campaigns from "../Campaigns/Campaign/Campaign";
import AllCampaignRuns from "../Campaigns/AllCampaignRuns/AllCampaignRuns";
import CampaignDetails from "../Campaigns/CampaignDetails/CampaignDetails";
import CampaignRunList from "../Campaigns/CampaignRuns/CampaignRunList";
import ScheduleCampaign from "../Campaigns/Schedule/ScheduleCampaign";
import MultiAgent from "../MultiAgent/MultiAgent";
import Integrations from "../Integration/Integrations";
import ProjectDashboard from "../ProjectDashboard/ProjectDashboard";
import { getAgentAction } from "./Agents/Store/Agent.action";
import DashboardHome from "../LeadDashboard/DashboardHome";
const Agents = lazy(() => import("../Agents/AgentList"));
const Recordings = lazy(() => import("./RecordingSection/Recordings"));
const CallSettings = lazy(() => import("../ProjectSettings/CallSettings"));
const Integration = lazy(() => import("./Integration/Integration"));
const Appointment = lazy(() => import("./Appointments/Appointment"));
const GeneratePrompt = lazy(() => import("./Agents/GeneratePrompt"));
const Dialerconfig = lazy(() => import("./DialerConfig/Dialerconfig"));

const Index: React.FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const [spin, setSpin] = useState(false);
  const {
    AccountReducer: {
      userDetails: { _id },
      selectedAccount,
      accountRoles,
    },
  } = reducer;

  useEffect(() => {
    const init = async () => {
      await getProjectAccess(projectId, accountRoles, dispatch);
    };
    if (!isEmpty(accountRoles)) {
      init();
    }
  }, [accountRoles, projectId]);

  useEffect(() => {
    if (projectId) {
      axiosClient.defaults.headers.common["projectId"] = projectId;
    }
  }, [projectId]);
  useEffect(() => {
    const init = async () => {
      if (!isEmpty(selectedAccount)) {
        setSpin(true);
        await dispatch(getProjectByIdAction(projectId));
        setSpin(false);
        await dispatch(getAgentAction(projectId));
        await dispatch(getCallSettingAction(projectId));
        dispatch(changeMenu("project"));
      }
    };
    init();
  }, [selectedAccount, projectId]);

  useEffect(() => {
    axiosClient.defaults.headers.common[
      "dbUrl"
    ] = `${selectedAccount?.accountUrl}`;
  }, [selectedAccount]);

  return spin ? (
    <SkeletonLodingWithCards count={3} />
  ) : (
    <>
      {" "}
      {/* <div>
        {!showPopup && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="keep-mounted-modal-title"
                variant="h6"
                component="h2"
              >
                <img src={img1} />

                <div className="designed-modal">
                  <p className="ml-2" style={{ color: "white" }}>
                    {" "}
                    Want to try our new model's?
                    <Link
                      to={`call-settings?modal=true`}
                      onClick={handleClose}
                      className="ml-2"
                    >
                      Click here
                    </Link>
                    <br />
                    <input id="check" type="checkbox" />
                    <label
                      style={{ fontSize: "14px", marginLeft: "10px" }}
                      htmlFor="check"
                    >
                      Don't show this again{" "}
                    </label>
                  </p>

                  <div className="d-flex justify-content-center">
                    <button className="custom-btn" onClick={handleClick}>
                      <span className="transition"></span>
                      <span className="gradient"></span>
                      <span className="label">Close</span>
                    </button>
                  </div>
                </div>
              </Typography>
            </Box>
          </Modal>
        )}
      </div> */}
      <Switch>
        {/* {!isVodexLoggedin() && ( */}
        <Route path="/account/project/:projectId/appointment-config">
          <Appointment />
        </Route>
        {/* // )} */}

        <Route path="/account/project/:projectId/generate-prompt">
          <GeneratePrompt />
        </Route>
        <Route path="/account/project/:projectId/multi-agents">
          <MultiAgent />
        </Route>
        <Route path="/account/project/:projectId/integrations">
          <Integrations />
        </Route>
        <Route path="/account/project/:projectId/leads">
          <DashboardHome />
        </Route>
        <Route path="/account/project/:projectId/agents">
          <Agents />
        </Route>
        <Route path="/account/project/:projectId/dashboard">
          <ProjectDashboard />
        </Route>
        <Route path="/account/project/:projectId/schedule-campaign">
          <ScheduleCampaign />
        </Route>
        <Route path="/account/project/:projectId/all-runs-details">
          <AllCampaignRuns />
        </Route>
        <Route path="/account/project/:projectId/campaign-runs-details">
          <CampaignDetails />
        </Route>
        <Route path="/account/project/:projectId/campaign-runs">
          <CampaignRunList />
        </Route>
        <Route path="/account/project/:projectId/campaigns">
          <Campaigns />
        </Route>
        {/* <Route path="/account/project/:projectId/sms-settings">
          <SMS />
        </Route>
        <Route path="/account/project/:projectId/whatsapp-settings">
          <WhatsappSetting />
        </Route>
         <Route path="/account/project/:projectId/advance-settings">
        <AdvanceSettings />
      </Route>  */}
        <Route path="/account/project/:projectId/common">
          <Messaging />
        </Route>
        <Route path="/account/project/:projectId/integration">
          <Integration />
        </Route>
        <Route path="/account/project/:projectId/call-settings">
          <CallSettings />
        </Route>
        <Route path="/account/project/:projectId/recordings">
          <Recordings />
        </Route>
        <Route path="/account/project/:projectId/audiences">
          <AudienceList />
        </Route>
        <Route path="/account/project/:projectId/dialer-config">
          <Dialerconfig />
        </Route>
      </Switch>
    </>
  );
};

export default Index;
