import { type } from "os";
import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../../Constant/swalMessage";
import {
  addManualAudienceExcel,
  attchReferenceService,
  checkForCampaignValidationService,
  checkPromptService,
  checkQueueService,
  clearCacheReferenceService,
  clearQueueService,
  deleteCampaignService,
  deleteScheduleCampaignService,
  favouriteCampaignService,
  gatewaySwitchService,
  getAllCampaignDetailsService,
  getCallHistoryCountService,
  getCallHistoryService,
  getCallLogsService,
  getCampaignCountService,
  getCampaignDetailsByIdService,
  getCampaignDetailsService,
  getCampaignEntityListService,
  getCampaignEntityService,
  getCampaignRunsByIdService,
  getCampaignRunsCountService,
  getCampaignRunsLiveService,
  getCampaignRunsService,
  getCampaignService,
  getCpsValueService,
  getExistingExcelService,
  getExportCampaignService,
  getInsightsCampaignService,
  getScheduleCampaignService,
  increaseCapacityService,
  increaseCpsService,
  lastCampaignRunListService,
  saveCampaignService,
  saveFilteredCampaignService,
  saveInsightsCampaignService,
  saveScheduleCampaignService,
  stopCampaignRunService,
  triggerCampaignService,
} from "../../../services/campaign.services";
import { CampaignType } from "./Campaign.type";

export const clearCampaignReducerAction = () => async (dispatch) => {
  await dispatch({
    type: CampaignType.CLEAR_CAMPAIGN_REDUCER,
    payload: {},
  });
};

export const getCampaignAction = (id, obj) => async (dispatch) => {
  let res = await getCampaignService(id, obj);
  if (res) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_DATA,
      payload: {
        data: res?.data?.doc || [],
        totalCount: res?.data?.totalCount || 0,
        campaignRun: res?.data?.campaignRun || 0,
        campaignRunDetails: res?.data?.campaignRunDetails || 0,
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
      },
    });
  }
};
export const getCallHistoryAction = (obj) => async (dispatch) => {
  const res = await getCallHistoryService(obj);
  if (res.status === 200) {
    await dispatch({
      type: CampaignType.GET_CALL_HISTORY_DATA,
      payload: {
        data: res?.data || [],
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
      },
    });
    return res?.data;
  } else {
    await dispatch({
      type: CampaignType.GET_CALL_HISTORY_DATA,
      payload: {
        data: [],
      },
    });
    swalErrorMessage(res?.errMsg);
  }
};

export const exportCallHistoryAction = (obj) => async () => {
  let res = await getCallHistoryService(obj);
  if (res) {
    return res?.data;
  }
};

export const getCallHistoryCountAction = (obj) => async (dispatch) => {
  const res = await getCallHistoryCountService(obj);

  if (res.status === 200) {
    await dispatch({
      type: CampaignType.GET_CALL_HISTORY_COUNT_DATA,
      payload: {
        totalCount: res?.data[0]?.count || 0,
      },
    });
  }else{
    await dispatch({
      type: CampaignType.GET_CALL_HISTORY_COUNT_DATA,
      payload: {
        totalCount: 0,
      },
    });
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignCountAction = (id, obj) => async (dispatch) => {
  let res = await getCampaignCountService(id, obj);
  if (res) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_COUNT_DATA,
      payload: {
        totalCount: res?.data?.campaignCount || 0,
        campaignRun: res?.data?.getCampaignRunCount || 0,
        campaignRunDetails: res?.data?.campaignRunDetailsCount || 0,
      },
    });
  }
};

export const saveCampaignAction = (obj) => async () => {
  let res = await saveCampaignService(obj);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const deleteCampaignAction = (id) => async () => {
  let res = await deleteCampaignService(id);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const checkForCampaignValidationAction = (id) => async () => {
  let res = await checkForCampaignValidationService(id);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const triggerCampaignAction = (data, postObj) => async () => {
  let res = await triggerCampaignService(data, postObj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignRunsAction = (id, campId, obj) => async (dispatch) => {
  let res = await getCampaignRunsService(id, campId, obj);
  if (res) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_RUNS_DATA,
      payload: {
        data: res?.data?.campaignResponse || [],
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
      },
    });
    return res;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignRunsByIdAction = (id, campId, obj) => async () => {
  let res = await getCampaignRunsByIdService(id, campId, obj);
  if (res) {
    return res?.data?.campaignResponse;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignRunsCountAction = (id, campId) => async (dispatch) => {
  let res = await getCampaignRunsCountService(id, campId);
  if (res) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_RUNS_COUNT_DATA,
      payload: {
        totalCount: res?.data?.campaignRunCount[0] || 0,
      },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getAllCampaignRunsAction = (id, campId, obj) => async () => {
  let res = await getCampaignRunsService(id, campId, obj);
  if (res) {
    return res?.data?.campaignResponse;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const stopCampaignRunAction = (obj) => async () => {
  let res = await stopCampaignRunService(obj);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveScheduleCampaignAction = (obj) => async () => {
  let res = await saveScheduleCampaignService(obj);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getScheduleCampaignAction = (id) => async (dispatch) => {
  let res = await getScheduleCampaignService(id);
  if (res?.status === 200) {
    await dispatch({
      type: CampaignType.GET_SCHEDULED_CAMPAIGN_DATA,
      payload: res?.data || [],
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const deleteScheduleCampaignAction = (id) => async () => {
  let res = await deleteScheduleCampaignService(id);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignDetailsAction =
  (projectId, id, obj) => async (dispatch) => {
    let res = await getCampaignDetailsService(projectId, id, obj);
    const campaignsData = {
      data: res?.data?.doc || [],
      totalCount: res?.data?.totalCount,
      countsData: res?.data?.countsData,
      searchText: obj?.searchText,
      isScrolled: obj?.isScrolled,
    };
    if (res?.status === 200) {
      await dispatch({
        type: CampaignType.GET_CAMPAIGN_DETAILS_DATA,
        payload: campaignsData,
      });
      return campaignsData;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const getExportCampaignAction = (id) => async () => {
  let res = await getExportCampaignService(id);
  if (res?.status === 200) {
    return res.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveFilterdCampaignAction = (obj) => async () => {
  let res = await saveFilteredCampaignService(obj);
  if (res?.status === 200) {
    swalSuccessMessage("Campaign Uploaded Successfully");
    return res.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const addManualAudienceListExcel = (data) => async (dispatch) => {
  let res = await addManualAudienceExcel(data);
  if (res?.status === 200) {
    swalSuccessMessage("Excel Uploaded Successfully");
  } else {
    swalErrorMessage("Something went wrong");
  }
};

export const checkPromptAction = (data) => async () => {
  let res = await checkPromptService(data);
  return res;
};

export const getExistingExcelAction = (data) => async () => {
  let res = await getExistingExcelService(data);
  if (res?.status === 200) {
    return res?.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignDetailsAllAction =
  (projectId, id, obj) => async (dispatch) => {
    let res = await getCampaignDetailsService(projectId, id, obj);
    if (res?.status === 200) {
      return res?.data?.doc;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const saveInsightsCampaignAction = (obj) => async () => {
  let res = await saveInsightsCampaignService(obj);
  if (res?.status === 200) {
    // swalSuccessMessage("Saved Successfully");
    return true
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getInsightsCampaignAction =
  (campaignId, empty) => async (dispatch) => {
    if (empty) {
      await dispatch({
        type: CampaignType.GET_CAMPAIGN_INSGHTS,
        payload: {
          data: {},
        },
      });
      return;
    }
    let res = await getInsightsCampaignService(campaignId);
    if (res?.status === 200) {
      await dispatch({
        type: CampaignType.GET_CAMPAIGN_INSGHTS,
        payload: {
          data: res?.data[0] || {},
        },
      });
      return res?.data;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const getAllCampaignDetailsAction =
  (campaignId, obj) => async (dispatch) => {
    let res = await getAllCampaignDetailsService(campaignId, obj);
    if (res?.status === 200) {
      if (obj.isDocsOnly) {
        await dispatch({
          type: CampaignType.GET_ALL_CAMPAIGN_DETAILS_DATA,
          payload: {
            data: res?.data?.doc || [],
            totalCount: res?.data?.totalCount,
            searchText: obj?.searchText,
            isScrolled: obj?.isScrolled,
          },
        });
      } else {
        await dispatch({
          type: CampaignType.GET_ALL_CAMPAIGN_COUNTS,
          payload: {
            countsData: res?.data?.countsData,
          },
        });
      }
      return res?.data?.doc;
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };

export const exportAllCampaignRunsAction = (campaignId, obj) => async () => {
  try {
    let res = await getAllCampaignDetailsService(campaignId, obj);
    if (res?.status === 200) {
      return res?.data?.doc;
    }
  } catch (error) {
    swalErrorMessage(error);
  }
};

export const checkQueueAction = (gateway) => async () => {
  let res = await checkQueueService(gateway);
  return res;
};

export const getCalllogsAction = (call_uuid) => async () => {
  let res = await getCallLogsService(call_uuid);
  return res;
};

export const favouriteCampaignAction = (data, id) => async () => {
  let res = await favouriteCampaignService(data, id);
  if (res?.status === 200) {
    // swalSuccessMessage("Favourites updated successfully");
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCampaignRunsLiveAction = (id, obj) => async (dispatch) => {
  let res = await getCampaignRunsLiveService(id, obj);
  if (res) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_RUNS_LIVE_DATA,
      payload: {
        liveCampaigns: res.data,
      },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const increaseCpsAction = (data, gateway) => async () => {
  let res = await increaseCpsService(data, gateway);
  if (res) {
    swalSuccessMessage(res?.status);
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCpsValueAction = (gateway) => async () => {
  let res = await getCpsValueService(gateway);
  if (res) {
    return res;
  }
};

export const getCampaignByIdAction = (projectId, obj) => async (dispatch) => {
  let res = await getCampaignDetailsByIdService(projectId, obj);
  if (res?.status === 200) {
    return res?.data?.doc[0];
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const increaseCapacityAction = (data, gateway) => async () => {
  let res = await increaseCapacityService(data, gateway);
  if (res) {
    swalSuccessMessage(res?.status);
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getLast50CallsAction = (obj) => async () => {
  let res = await getCallHistoryService(obj);
  if (res.status === 200) {
    return res?.data;
  }
};

export const attchReferenceAction = (obj) => async () => {
  let res = await attchReferenceService(obj);
  if (res.status === 200) {
    swalSuccessMessage("Call refrence attached");

    return res?.data;
  }
};

export const clearCacheReferenceAction = (obj) => async () => {
  let res = await clearCacheReferenceService(obj);
  if (res.status === 200) {
    swalSuccessMessage("Cache Cleared");
    return res?.data;
  }
};

export const clearQueueAction = (data) => async () => {
  let res = await clearQueueService(data);
  if (res) {
    return res;
  }
};

export const gatewaySwitchAction = (key, gateway) => async () => {
  let res = await gatewaySwitchService(key, gateway);
  if (res) {
    return res;
  }
};

export const lastCampaignActionList = (obj) => async (dispatch) => {
  const res = await lastCampaignRunListService(obj);
  
  if (res?.status === 200) {
    await dispatch({
      type: CampaignType.GET_CAMPAIGN_RUNS_LIST_DATA,
      payload: {
        campaigns: res?.data[0]?.data,
        totalCount: res?.data[0]?.totalCount,
      },
    });
    return res?.data;
  }
};

export const getCampaignEntityAction = async (params) => {
  const res = await getCampaignEntityService(params);

  if (res?.status === 200) {
    return res?.data;
  }
};

export const getCampaignEntityListAction = async (campaignId, params) => {
  const res = await getCampaignEntityListService(campaignId, params);

  if (res?.status === 200) {
    return res?.data;
  }
};
