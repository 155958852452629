import { Alert, Box, TextField } from "@mui/material";
import React from "react";
import BaseButton from "../../Common/Buttons/BaseButton";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const DurationFilter = ({ obj }) => {
  const {
    mainState,
    arraMapped,
    allCallStatus,
    outComefilter,
    onChange,
    onCustomDurationChange,
    customDurationError,
    handleClearDurationFilter,
  } = obj;
  return (
    <Box className="mt-2">
      <span className="font-12-grey">Duration</span>
      <Box className="mt-2 duration-filter-container">
        {arraMapped.map((arr, index) => (
          <Box key={index} sx={{ paddingTop: "4px" }}>
            <Box className="flex-evenly">
              {arr.innerMap?.map((innerArr, i) => {
                const dur = arr?.title === "Filter Calls with status";
                const isSelected = arr?.isObject
                  ? outComefilter.find((o) => o === innerArr?.tag)
                  : mainState[arr?.valueAssigned] === innerArr ||
                    mainState[arr?.valueAssigned].includes(innerArr);

                return (
                  <Box
                    key={i}
                    onClick={() => onChange(arr?.valueAssigned, innerArr)}
                    sx={{
                      height: "30px",
                      background: isSelected ? "#FFFFFF" : "#F1F4FA",
                      borderRadius: "100px",
                      px: 0.5,
                    }}
                    className="font-12-lightgrey flex-xy-center cursor-pointer"
                  >
                    {!arr?.isObject &&
                      (dur ? allCallStatus[innerArr] : innerArr)}
                    {arr?.isObject && innerArr?.name}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
      {mainState?.durationFilter === "Custom" && (
        <Box>
          <span className="font-12-lightgrey mt-2">Custom Duration</span>
          <Box className="flex-between" gap={1}>
            <TextField
              placeholder="from (in sec.)"
              name="min"
              inputProps={{ min: 0 }}
              type="number"
              value={mainState?.min}
              onChange={onCustomDurationChange}
              sx={commonStyles.inputField}
            />
            <TextField
              style={{ width: "160px" }}
              placeholder="to (in sec.)"
              name="max"
              inputProps={{ min: 0 }}
              type="number"
              value={mainState?.max}
              onChange={onCustomDurationChange}
              sx={commonStyles.inputField}
            />
          </Box>
          {customDurationError && (
            <Alert severity="error" variant="standard" sx={{ my: 1 }}>
              {customDurationError}
            </Alert>
          )}
        </Box>
      )}
      <BaseButton
        title="Clear Duration Filter"
        handleClick={handleClearDurationFilter}
        styles={{ padding: 0 }}
      />
    </Box>
  );
};

export default DurationFilter;
