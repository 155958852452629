const styles = {
  tableContainer: {
    "&.MuiPaper-rounded": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    boxShadow: "none",
    border: 1,
    borderBottom: 0,
    borderRadius: 2,
    borderColor: "#CCD2DE",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  header: {
    p: 1.5,
    textTransform: "uppercase",
    textAlign: "center",
    color: "#666666",
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: "#F8FAFE",
    textWrap: "nowrap",
  },
  cell: {
    p: 1,
    textAlign: "center",
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    textTransform: "capitalize",
  },
  loaderContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    zIndex: 10,
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#F1F4FA",
      cursor: "default",
      "& td": {
        backgroundColor: "inherit !important",
      },
    },
  },
  highlightedRow: {
    backgroundColor: "#f59a64",
    "&:hover": {
      backgroundColor: "#f59a64",
    },
  },
  bottomContainer: {
    p: 1.5,
    paddingLeft: 3,
    border: "1px solid #CCD2DE",
    gap: 2,
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    borderTop: 0,
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      borderRadius: "8px",
      color: "#666666",
      fontWeight: 500,
      padding: 0,
      "&.Mui-selected": {
        backgroundColor: "#F1F4FA",
        color: "#666666",
        border: 0,
        padding: 0,
      },
    },
  },
  paginationButtons: {
    textTransform: "capitalize",
    borderColor: "#CCD2DE",
    color: "#666666",
    fontWeight: 500,
    fontSize: 12,
    borderRadius: 2,
    padding: "4px 20px",
    "&:hover": { borderColor: "#5AA552" },
  },
};

export default styles;
