import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { styles } from "../../../assets/MUIStyles/onboarding";
import { useLocation } from "react-router-dom";
import { checkRolesAccess } from "../CommonComponents/Common";
import { useSelector } from "react-redux";
import OnboardingContainer from "./Container";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const ProjectNameSlide = ({ obj }) => {
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let from = locSearch.get("from");
  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { accountRoles },
  } = reducer;
  const signInButtonRef = useRef<HTMLButtonElement | null>(null);
  const {
    currentDataRef,
    mainState,
    onChange,
    next,
    projectId,
    activeIndex,
    continueToDashboard,
  } = obj;
  const { projectName } = mainState;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      next();
    }
  };

  return (
    <OnboardingContainer>
      <Box mx={2}>
        <Typography className="font-18-darkGrey" textAlign={"center"}>
          Welcome to the future of calling! Let's customize your experience!
        </Typography>

        <Box mt={3}>
          <Box className="input-container">
            {!projectName && !currentDataRef.current?.currentProjectName && (
              <span className="custom-placeholder">
                Enter your {from === "dashboard" ? "" : "first"} project name
                example <strong>“Sales”</strong>
              </span>
            )}
            <input
              name="projectName"
              className="common-ip"
              maxLength={25}
              onChange={onChange}
              onKeyDown={handleKeyPress}
              disabled={
                projectId || currentDataRef.current?.currentProjectId
                  ? true
                  : false
              }
              value={projectName || currentDataRef.current?.currentProjectName}
            />
          </Box>
          <Box mt={3} mx={{ xs: 0, sm: 4 }}>
            <Grid container spacing={2}>
              {from === "dashboard" && (
                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={continueToDashboard}
                    sx={{ ...commonStyles.clearButton, width: "100%" }}
                  >
                    {checkRolesAccess(accountRoles) === "dashboard"
                      ? "Continue to Dashboard"
                      : "Continue to Projects"}
                  </Button>
                </Grid>
              )}

              {activeIndex !== 3 && (
                <Grid item xs={12} sm={from === "dashboard" ? 6 : 12}>
                  <Button
                    sx={{ ...commonStyles.saveButton, width: "100%" }}
                    ref={signInButtonRef}
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                  >
                    Get Started
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </OnboardingContainer>
  );
};

export default ProjectNameSlide;
