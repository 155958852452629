import React, { useState } from "react";
import { Box } from "@mui/material";
import { ReactComponent as CalendarIcon } from "../../../../assets/Images/CalendarIcon.svg";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const addDays = (date, days) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const DateProjectFilter = ({ obj }) => {
  const {
    startDate,
    endDate,
    setDateRange,
    selectedProjectId,
    setMainState,
    projectList,
  } = obj;

  const [searchInput, setSearchInput] = useState("");
  const [warning, setWarning] = useState("");

  const handleInputChange = (inputValue, { action }) => {
    if (action === "input-change") {
      if (inputValue.length > 25) {
        setWarning("Project name cannot exceed 25 characters!");
      } else {
        setWarning("");
      }
      setSearchInput(inputValue.slice(0, 25));
    } else if (action === "select-option") {
      setSearchInput("");
      setWarning("");
    }
  };

  return (
    <>
      <Box className="mt-2 flex-column" sx={{ gap: "13px" }}>
        <Box>
          <span className="font-12-grey">Date</span>
          <Box
            className="new-date-picker mt-2"
            sx={{ position: "relative", padding: "6px" }}
          >
            <DatePicker
              dateFormat="dd/MM/yyyy"
              maxDate={addDays(new Date(), 0)}
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => setDateRange(update)}
              placeholderText="From date - To date"
              className="custom-date-input"
            />
            <span className="calendar-icon datepicker-icon">
              <CalendarIcon />
            </span>
          </Box>
        </Box>
      </Box>

      <Box className="mt-2">
        {warning && (
          <p style={{ color: "red", fontSize: "12px", marginBottom: "4px" }}>
            {warning}
          </p>
        )}

        <span className="font-12-grey">Project</span>
        <Select
          styles={commonStyles.customSelect}
          maxMenuHeight={180}
          className="basic-single mt-2"
          classNamePrefix="select"
          isDisabled={false}
          isSearchable={true}
          isClearable={true}
          options={projectList}
          value={
            selectedProjectId
              ? projectList?.find(
                  (project) => project?._id === selectedProjectId
                )
              : null
          }
          getOptionLabel={(e: any) => e.projectName}
          getOptionValue={(e: any) => e._id}
          onChange={(e) => {
            setMainState((prev) => ({
              ...prev,
              selectedProjectId: e?._id,
            }));
            setSearchInput("");
          }}
          onInputChange={handleInputChange}
          inputValue={searchInput}
          placeholder="Select Project"
        />
      </Box>
    </>
  );
};

export default DateProjectFilter;
