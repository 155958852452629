import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../../Constant/swalMessage";
import {
  attachInboundNumberService,
  getAllVoices,
  getCallSettingService,
  getCallerIdOtpService,
  getGatewayAssignedNumberService,
  getHealthCheckService,
  resendCallerIdOtpService,
  saveByocSettingService,
  saveCallSettingService,
  verifyCallerIdOtpService,
} from "../../../services/callSetting.services";

import { callSettingType } from "./callSetting.type";
export const getCallSettingAction = (id) => async (dispatch) => {
  let res = await getCallSettingService(id);
  if (res) {
    await dispatch({
      type: callSettingType.GET_CALL_SETTING_DATA,
      payload: res?.data,
    });
  }
};

export const saveCallSettingsAction = (obj) => async () => {
  let res = await saveCallSettingService(obj);
  if (res?.status === 200) {
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await getCallerIdOtpService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const resendCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await resendCallerIdOtpService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const verifyCallerIdOtpAction = (obj) => async (dispatch) => {
  let res = await verifyCallerIdOtpService(obj);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const clearCallSettingReducerAction = () => async (dispatch) => {
  await dispatch({
    type: callSettingType.CLEAR_CALL_SETTING,
    payload: {},
  });
};

export const saveByocSettingsAction = (obj) => async () => {
  let res = await saveByocSettingService(obj);
  if (res?.status === 200) {
    swalSuccessMessage(res?.msg);
    return res?.status;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getGatewayAssignedNumberAction = (gateway) => async () => {
  let res = await getGatewayAssignedNumberService(gateway);
  if (res) {
    return res;
  }
};

export const attachInboundNumberAction = (obj, gateway) => async () => {
  let res = await attachInboundNumberService(obj, gateway);
  if (res?.status) {
    swalSuccessMessage(res?.status);
  }
};

export const getHealthCheckServiceAction = () => async () => {
  try {
    let res = await getHealthCheckService();
    if (res.status === 200) {
      return res?.data;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllVoicesAction = (dispatch, params) => async () => {
  const res = await getAllVoices(params);
  if (res) {
    const voice = res
      .filter((voice) => voice?.provider === params?.provider)
      ?.map((voc) => ({
        name: voc?.name,
        hasMore: voc?.has_more,
        voiceData: voc?.voices?.map((voice) => ({
          ...voice,
          languages: voc?.languages,
          models: voc?.models,
          speed_control: voc?.speed_control,
          default_language_code: voc?.default_language_code,
          default_model: voc?.default_model,
          provider: voc?.provider,
        })),
      }));
    const payload = {
      [params?.provider]: voice,
    };

    await dispatch({
      type: callSettingType.GET_ALL_VOICES_DATA,
      payload: payload,
    });
    return res;
  }
};
