import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";
import {
  deleteScheduleCampaignAction,
  getScheduleCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import ConfirmationPopup from "../../CommonComponents/ConfirmationPopup";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import BaseButton from "../../Common/Buttons/BaseButton";
import StatusButton from "../../Common/Buttons/StatusButton";
import { Box, IconButton } from "@mui/material";
import { ReactComponent as Info } from "../../../../assets/icons/info.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Delete.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";
import moment from "moment";
import DeletePopup from "../../CommonComponents/PopUpCards/DeletePopUp";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";

function ScheduleList({ campaignId }) {
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);
  const {
    CampaignReducer: { sheduleCampaigns },
  } = reducer;

  const [allColumns, setAllColumns] = useState([]);
  const [state, setState] = useState({
    scheduleLocalList: [],
    spin: true,
    buttonDisable: false,
    popup: false,
    id: "",
  });
  const { scheduleLocalList, spin, buttonDisable, popup, id } = state;
  const [deleteSpin, setDeleteSpin] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    const init = async () => {
      await dispatch(getScheduleCampaignAction(campaignId));
      setState((prev) => ({
        ...prev,
        spin: false,
      }));
    };
    init();
  }, [dispatch]);

  useEffect(() => {
    if (sheduleCampaigns) {
      setState((prev) => ({
        ...prev,
        scheduleLocalList: sheduleCampaigns,
      }));
    }
  }, [sheduleCampaigns]);

  useEffect(() => {
    const columns = [
      {
        label: "Schedule Date",
        render: (campaign) =>
          moment(campaign?.time.split(" ")[0]).format("MMM DD, YYYY"),
        styles: { textWrap: "nowrap" },
      },
      {
        label: "Time",
        render: (campaign) =>
          moment(campaign?.time.split(" ")[1], "HH:mm:ss").format("hh:mm a"),
        styles: { textTransform: "none" },
      },
      {
        label: "Timezone",
        render: (campaign) => moment.tz(campaign?.timezone).format("z"),
      },
      {
        label: "STATUS",
        render: (campaign) => {
          const { status, statusColor, backgroundColor } = getStatus(campaign);

          return (
            <Box className="flex-xy-center">
              <StatusButton
                status={status}
                statusColor={statusColor}
                backgroundColor={backgroundColor}
              />
              {status === "failed" && (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="top"
                  overlay={
                    <Popover id="popover-basic" style={{ zIndex: 1200 }}>
                      <Popover.Content>
                        {campaign?.result?.errMsg}
                      </Popover.Content>
                    </Popover>
                  }
                  rootClose={true}
                  rootCloseEvent="click"
                  onExit={() => {}}
                >
                  <IconButton>
                    <Info />
                  </IconButton>
                </OverlayTrigger>
              )}
            </Box>
          );
        },
      },

      {
        label: "Action",
        render: (campaign) => (
          <BaseButton
            title="Delete"
            endIcon={<DeleteIcon />}
            handleClick={() =>
              setState((prev) => ({ ...prev, popup: true, id: campaign._id }))
            }
            styles={{ color: "#C4330F" }}
          />
        ),
      },
    ];
    setAllColumns(columns);
  }, []);

  const deleteSchedule = async () => {
    setDeleteSpin(true)
    let res = await dispatch(deleteScheduleCampaignAction(id));
    await dispatch(getScheduleCampaignAction(campaignId));
    setState((prev) => ({ ...prev, popup: false, id: "" }));
    setDeleteSpin(false)
    if (res) {
      setDeletePopup(true)
    }
  };

  const getStatus = (campaign) => {
    if (campaign.completed === false) {
      return {
        status: "pending",
        statusColor: "#67A8AE",
        backgroundColor: "#E4F2F5",
      };
    } else if (campaign?.result?.status === 200) {
      return {
        status: "completed",
        statusColor: "#5AA552",
        backgroundColor: "#E3F3EB",
      };
    } else
      return {
        status: "failed",
        statusColor: "#D84B28",
        backgroundColor: "#FCF0ED",
      };
  };

  const toggleDeletePopup = () => {
    setDeletePopup((prev) => !prev);
  };

  return (
    <>
      <DynamicTable
        columns={allColumns}
        data={scheduleLocalList}
        showTotalRows={true}
        loading={spin}
        rowCount={scheduleLocalList?.length}
      />

      {popup && (
        <DeletePopup
          handleDelete={deleteSchedule}
          heading="Delete Scheduled Campaign"
          message="Are you sure you want to delete selected scheduled campaign?"
          show={popup}
          toggle={() => setState((prev) => ({ ...prev, popup: false, id: "" }))}
          spin={deleteSpin}
        />
      )}
      {deletePopup && (
        <CommonPopup
          message={`Campaign deleted successfully`}
          show={deletePopup}
          toggle={toggleDeletePopup}
          Icon={SuccessIcon}
          heading="Campaign Deleted"
        />
      )}
    </>
  );
}

export default ScheduleList;
