import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../Constant/swalMessage";
import {
  AddLeadsService,
  deleteProjectsService,
  editProjectsService,
  getAdvanceSettingsService,
  getLeadDataService,
  getProjectByIdService,
  getProjectSheetsDataService,
  getProjectSheetsService,
  getProjectsService,
  saveAdvanceSettingsService,
  saveProjectsService,
  updateLeadsService,
} from "../../services/projects.services";
import { projectsType } from "./projects.type";
export const getProjectsAction = () => async (dispatch) => {
  let res = await getProjectsService();
  if (res) {
    await dispatch({
      type: projectsType.GET_PROJECTS_LIST,
      payload: res?.data,
    });
  }
};

export const getProjectByIdAction = (id) => async (dispatch) => {
  let res = await getProjectByIdService(id);
  if (res) {
    await dispatch({
      type: projectsType.GET_SELECTED_PROJECT,
      payload: res?.data,
    });
  }
};

export const saveProjectsAction =
  (obj, swal = true) =>
  async (dispatch) => {
    let res = await saveProjectsService(obj);
    if (res.status === 200) {
      return res;
    } else {
      swalErrorMessage(res.errMsg);
    }
  };

export const editProjectsAction =
  (obj, id, displayAlert = true) =>
  async (dispatch) => {
    let res = await editProjectsService(obj, id);
    if (res.status === 200) {
      if (displayAlert) {
        swalSuccessMessage(res.msg);
      }
      return res.status;
    } else {
      swalErrorMessage(res.errMsg);
    }
  };

export const deleteProjectsAction = (id) => async (dispatch) => {
  let res = await deleteProjectsService(id);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const AddLeadsAction = async (obj) => {
  const res = await AddLeadsService(obj);
  if (res.status === 200) {
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const updateLeadsAction = async (id, obj) => {
  const res = await updateLeadsService(id, obj);
  if (res.status === 200) {
    return res;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getLeadsDataAction = async (obj) => {
  const res = await getLeadDataService(obj);
  if (res.status === 200) {
    return res?.data;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getProjectSheetsAction = async (data) => {
  const res = await getProjectSheetsService(data);
  if (res?.status === 200) return res?.data;
};

export const getProjectSheetsDataAction = async (
  paramsdata,
  projectId,
  filters
) => {
  const res = await getProjectSheetsDataService(paramsdata, projectId, filters);
  if (res?.status === 200) return res?.data;
};
