import React, { useEffect, useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import * as FileSaver from "file-saver";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { ReactComponent as EditIcon } from "../../../assets/Images/EditIcon.svg";
import { ReactComponent as ExportFileIcon } from "../../../assets/Images/ExportPopupIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/Images/SuccessProjectIcon.svg";
import { shortenString } from "../../../Constant/helper";
import { swalErrorMessage } from "../../../Constant/swalMessage";
import BaseButton from "../Common/Buttons/BaseButton";
import { PAGE_SIZE_50 } from "../CommonComponents/Common";
import CommonModal from "../CommonComponents/Modal/CommonModal";
import CommonPopup from "../CommonComponents/PopUpCards/CommonPopup";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import {
  getLeadsDataAction,
  updateLeadsAction,
} from "../Projects/Store/projects.action";
import EditLeadModal from "./EditLeadModal";
import PatientTableFilters from "./PatientTableFilters";
import PatientTableHeader from "./PatientTableHeader";

const columnOrder = [
  { key: "_id", label: "Patient Id" },
  { key: "firstName", label: "First Name" },
  { key: "lastName", label: "Last Name" },
  { key: "email", label: "Email" },
  { key: "phoneNum", label: "Phone Number" },
  { key: "dateOfBirth", label: "Date of Birth" },
  { key: "insurance", label: "Insurance" },
  { key: "preExistingCondition", label: "Pre-existing Condition" },
  { key: "dateOfSurgery", label: "Date of Surgery" },
  { key: "pdfRec", label: "Record Type" },
  { key: "testsDone", label: "Test Results Received" },
  { key: "testsNotDone", label: "Tests Not Done" },
  { key: "actualTests", label: "All Tests Needed" },
  { key: "nameOfSurgeon", label: "Name Of Surgeon" },
  { key: "insights", label: "Insights" },
];

const PatientTable = () => {
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    ProjectReducer: { leadsList: data },
  } = reducer;

  const [exportPopup, setExportPopup] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [patientPopup, setPatientPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredData, setFilteredData] = useState(data || []);
  const [columns, setColumns] = useState([]);
  const [viewRecord, setViewRecord] = useState([]);
  const [editRecordModal, setEditRecordModal] = useState(false);
  const [editButtonLoading, setEditButtonLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    eamil: "",
    phoneNum: "",
    dateOfBirth: "",
    insurance: "",
    preExistingCondition: "",
    dateOfSurgery: "",
    testsDone: "",
    testsNotDone: "",
    actualTests: [],
  }) as any;

  const popoverOpen = Boolean(anchorEl);

  const tableCols: any = useMemo(() => {
    const testKeys = new Set(["testsDone", "testsNotDone", "actualTests"]);
    const dateKeys = new Set(["dateOfBirth", "dateOfSurgery"]);

    const cols: any = columnOrder
      .filter(({ key }) => key !== "insights")
      .map(({ key, label }) => ({
        label,
        render: (row) => {
          const value = row?.[key] || "--";

          if (key === "_id") return shortenString(value, 10);
          if (testKeys.has(key)) return renderTestResults(value, key, row);
          if (dateKeys.has(key) && value !== "--")
            return moment(value).format("DD-MM-YYYY");
          if (key === "pdfRec")
            return (
              <BaseButton
                disabled={!value}
                title="View"
                handleClick={() => setViewRecord(value)}
                styles={{ color: "#4280FF" }}
              />
            );

          return value;
        },
        styles: { textTransform: "none", textWrap: "nowrap", py: 2 },
      }));

    const insightsKeys = [
      ...new Set(
        data?.flatMap((item) => item?.insights?.map((i) => i.key) || [])
      ),
    ];

    insightsKeys.forEach((insightKey: any) => {
      cols.push({
        label: insightKey?.replace(/_/g, " "),
        render: (row) =>
          row?.insights?.find((item) => item?.key === insightKey)?.value ||
          "--",
        styles: { textTransform: "none", textWrap: "nowrap", py: 2 },
      });
    });

    cols.push({
      label: "Actions",
      render: (row) => (
        <IconButton onClick={() => handleEdit(row)}>
          <EditIcon title="Edit Patient" />
        </IconButton>
      ),
      styles: { textAlign: "center" },
    });

    return cols;
  }, [columnOrder]);

  useEffect(() => {
    setFilteredData(data || []);
  }, [data]);

  useEffect(() => {
    setColumns(tableCols);
  }, [tableCols]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    setEditButtonLoading(true);
    const data = {
      data: formData,
    };
    await updateLeadsAction(formData?._id, data);
    const params = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      searchtext: "",
    };
    await dispatch(getLeadsDataAction(params));
    setPatientPopup(true);
    setEditRecordModal(false);
    setEditButtonLoading(false);
  };

  const handleEdit = (lead) => {
    setEditRecordModal(true);
    setFormData(lead);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const exportToCSV = async () => {
    setExportLoading(true);
    const params = {
      pageIndex: 1,
      pageSize: PAGE_SIZE_50,
      searchtext: "",
    };
    const res: any = await dispatch(getLeadsDataAction(params));

    if (!res || res.length === 0) {
      swalErrorMessage("Nothing to export.");
      setExportLoading(false);
      return;
    }
    const testKeys = new Set(["testsDone", "testsNotDone", "actualTests"]);
    const dateKeys = new Set(["dateOfBirth", "dateOfSurgery"]);
    const insightsKeys = [
      ...new Set(
        res.flatMap((item) => item?.insights?.map((i) => i.key) || [])
      ),
    ];
    const arr: any = res?.map((lead) => {
      const obj: any = {};

      columnOrder.forEach(({ key, label }) => {
        if (dateKeys.has(key)) {
          obj[label] = lead[key]
            ? moment(lead[key]).format("DD-MM-YYYY")
            : "--";
        } else if (testKeys.has(key)) {
          const data = Array.isArray(lead[key])
            ? lead[key].join(", ")
            : lead[key]
            ? JSON.parse(lead[key])?.join(", ")
            : "";
          obj[label] = data;
        } else if (["pdfRec", "insights"].includes(key)) {
          return;
        } else {
          obj[label] = lead[key] || "--";
        }
      });

      insightsKeys?.forEach((key: any) => {
        obj[
          key.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
        ] = lead?.insights?.find((item) => item?.key === key)?.value || "--";
      });

      // obj["Documents"] = Array.isArray(lead?.pdf) ? lead.pdf.join(", \r\n") : "";

      return obj;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Patients` + fileExtension);

    setExportPopup(true);
    setExportLoading(false);
  };

  const filtersObj = {
    popoverOpen,
    handlePopoverClose,
    anchorEl,
    columnOrder,
    setFilteredData,
  };

  return (
    <>
      <Box
        sx={{
          border: "1px solid #E0E0E0",
          borderRadius: 2,
          padding: 2,
        }}
      >
        <PatientTableHeader
          handlePopoverOpen={handlePopoverOpen}
          exportToCSV={exportToCSV}
          exportLoading={exportLoading}
        />
        <DynamicTable
          columns={columns}
          data={filteredData}
          showTotalRows={true}
          loading={false}
          noDataAvailableMessage={"No Leads Available"}
          rowCount={filteredData?.length}
          pagination={true}
          stickyColumns={true}
        />
      </Box>
      <PatientTableFilters filtersObj={filtersObj} />

      {viewRecord?.length > 0 && (
        <CommonModal
          show={viewRecord?.length > 0}
          body={
            <Box px={3}>
              {viewRecord.map((record: any) => (
                <Box
                  key={record?.id}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="body1">{record?.response}</Typography>
                  {record?.url && (
                    <BaseButton
                      title="View PDF"
                      handleClick={() => window.open(record?.url, "_blank")}
                      styles={{ color: "#4280FF" }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          }
          handleSave={false}
          showSaveButton={false}
          heading={`View Record`}
          size={500}
          toggle={() => setViewRecord([])}
        />
      )}

      {editRecordModal && (
        <CommonModal
          show={editRecordModal}
          body={
            <EditLeadModal formData={formData} handleChange={handleChange} />
          }
          handleSave={handleSave}
          heading={`Edit Record`}
          size={700}
          toggle={() => setEditRecordModal(false)}
          spin={editButtonLoading}
        />
      )}

      {patientPopup && (
        <CommonPopup
          message={`Patient is updated successfully`}
          show={patientPopup}
          toggle={() => setPatientPopup(false)}
          Icon={SuccessIcon}
          heading="Patient updated successfully"
        />
      )}

      {exportPopup && (
        <CommonPopup
          message={`The selected data are exported successfully`}
          show={exportPopup}
          toggle={() => setExportPopup(false)}
          Icon={ExportFileIcon}
          heading="File exported successfully"
        />
      )}
    </>
  );
};

export default PatientTable;

const renderTestResults = (value, key, row: any = {}) => {
  const data = Array.isArray(value) ? value : JSON.parse(value || "[]");
  if (!Array.isArray(data) || data.length === 0) {
    return "--";
  }

  return (
    <Box>
      {[...new Set(data)]?.map((item, index) => {
        const matchedPdf = row?.pdfRec?.find((pdf) => pdf.response === item);

        return (
          <Typography
            key={index}
            sx={{
              textAlign: "center",
              color: "#333333",
              fontSize: 12,
              fontWeight: 500,
            }}
          >
            {key === "testsDone" ? (
              <BaseButton
                title={item}
                handleClick={() => window.open(matchedPdf?.url, "_blank")}
                styles={{ color: "#4280FF" }}
              />
            ) : (
              item
            )}
          </Typography>
        );
      })}
    </Box>
  );
};
