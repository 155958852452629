import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import timezones from "timezones-list";
import { useDispatch } from "react-redux";
import moment from "moment";
import ScheduleList from "./ScheduleList";
import "./ScheduleCampaign.css";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router-dom";
import useQueryParams from "../../../Hooks/useQueryParams";
import { PAGE_SIZE_50 } from "../../CommonComponents/Common";
import {
  getCampaignByIdAction,
  getScheduleCampaignAction,
  saveScheduleCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import { swalWarningMessage } from "../../../../Constant/swalMessage";
import { shortenString } from "../../../../Constant/helper";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { ReactComponent as CalendarIcon } from "../../../../assets/Images/CalendarIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/arrow-back.svg";
import BaseButton from "../../Common/Buttons/BaseButton";
import SearchableSelect from "../../Common/Select/SearchableSelect";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";

function ScheduleCampaign() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(null);
  const [timeZoneOptions, setTimeZoneoptions] = useState([]);
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [selectedTab, setSelectedtab] = useState("schedule");
  const { campaignid } = useQueryParams();
  const { projectId } = useParams();
  const [schedulePopup, setSchedulePopUp] = useState(false);

  const timeOptions = [
    { label: "00:00", value: "00:00:00" },
    { label: "01:00", value: "01:00:00" },
    { label: "02:00", value: "02:00:00" },
    { label: "03:00", value: "03:00:00" },
    { label: "04:00", value: "04:00:00" },
    { label: "05:00", value: "05:00:00" },
    { label: "06:00", value: "06:00:00" },
    { label: "07:00", value: "07:00:00" },
    { label: "08:00", value: "08:00:00" },
    { label: "09:00", value: "09:00:00" },
    { label: "10:00", value: "10:00:00" },
    { label: "11:00", value: "11:00:00" },
    { label: "12:00", value: "12:00:00" },
    { label: "13:00", value: "13:00:00" },
    { label: "14:00", value: "14:00:00" },
    { label: "15:00", value: "15:00:00" },
    { label: "16:00", value: "16:00:00" },
    { label: "17:00", value: "17:00:00" },
    { label: "18:00", value: "18:00:00" },
    { label: "19:00", value: "19:00:00" },
    { label: "20:00", value: "20:00:00" },
    { label: "21:00", value: "21:00:00" },
    { label: "22:00", value: "22:00:00" },
    { label: "23:00", value: "23:00:00" },
  ];

  useEffect(() => {
    const init = async () => {
      const obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        campaignId: campaignid,
      };
      let res = await dispatch(getCampaignByIdAction(projectId, obj));
      setCampaignData(res);
    };
    init();
  }, []);
  useEffect(() => {
    let kl = [];
    timezones.forEach((dat) => {
      let newDat = { ...dat };
      newDat["value"] = dat.tzCode;
      kl.push(newDat);
    });
    setTimeZoneoptions(kl);
  }, []);

  const saveScheduleCampaign = async () => {
    if (!startDate) {
      swalWarningMessage("Please Select Date");
      return;
    }
    if (!startTime) {
      swalWarningMessage("Please Select Time");
      return;
    }
    if (!selectedTimeZone) {
      swalWarningMessage("please select timezone");
      return true;
    }

    Swal.fire({
      title: "Warning!",
      text: "Please keep sufficient balance in your account to run the campaign.",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Cancel",
      denyButtonText: `Schedule`,
      denyButtonColor: "#7066e0",
      confirmButtonColor: "#dc3741",
      willClose: () => {
        setButtonDisable(false);
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setButtonDisable(false);
        return false;
      } else if (result.isDenied) {
        Swal.fire({
          title: "Warning!",
          text: "Initiating this campaign implies you have the consent to call the provided numbers, and acceptance of our terms and conditions. Proceed only if you agree.",
          showDenyButton: true,
          confirmButtonText: "Cancel",
          denyButtonText: `Schedule`,
          denyButtonColor: "#7066e0",
          confirmButtonColor: "#dc3741",
          icon: "warning",
        }).then(async (result) => {
          if (result.isDenied) {
            setButtonDisable(true);
            const momentObj1 = moment(startDate);
            const date = momentObj1.format("YYYY-MM-DD");
            let obj = {
              timeZone: selectedTimeZone,
              time: `${date} ${startTime}`,
              campaignId: campaignData?._id,
              projectId: projectId,
            };
            let res = await dispatch(saveScheduleCampaignAction(obj));
            setStartDate(new Date());
            setStartTime(null);
            setSelectedTimeZone("");
            await dispatch(getScheduleCampaignAction(campaignData?._id));
            if (res) {
              setSchedulePopUp(true);
            }
            setButtonDisable(false);
          }
        });
      }
    });
  };

  const toggleSchedule = () => {
    setSchedulePopUp((prev) => !prev);
  };
  return (
    <>
      <Box className="flex-align-center" gap={1}>
        <Typography className="font-20-grey">Schedule Campaign -</Typography>
        <Typography className="font-18-grey" textTransform={"capitalize"}>
          {shortenString(campaignData?.campaignName, 40)}
        </Typography>
      </Box>
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedtab(newValue)}
        sx={commonStyles.tabs}
      >
        <Tab label="Schedule Campaign" value="schedule" sx={commonStyles.tab} />
        <Tab label="View Scheduled List" value="list" sx={commonStyles.tab} />
      </Tabs>
      {selectedTab === "schedule" && (
        <Box className="flex-row" gap={2}>
          <Box width={"100%"}>
            <Typography className="font-14-grey mb-2">Date</Typography>
            <Box sx={styles.calendarContainer}>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText="DD - MM - YY"
                className="custom-date-input"
              />
              <CalendarIcon className="calendar-icon" />
            </Box>
          </Box>
          <Box width={"100%"}>
            <Typography className="font-14-grey mb-2">Time</Typography>
            <SearchableSelect
              value={startTime}
              setValue={setStartTime}
              options={timeOptions}
              placeholder="Select Time"
            />
          </Box>
          <Box width={"100%"}>
            <Typography className="font-14-grey mb-2">Timezone</Typography>
            <SearchableSelect
              value={selectedTimeZone}
              setValue={setSelectedTimeZone}
              options={timeZoneOptions}
              placeholder="Select Timezone"
            />
          </Box>
        </Box>
      )}

      {selectedTab === "list" && <ScheduleList campaignId={campaignid} />}
      <Box sx={styles.footer}>
        <BaseButton
          title="Back"
          variant="outlined"
          handleClick={() =>
            history.push(`/account/project/${projectId}/campaigns`)
          }
          startIcon={<ArrowBack />}
        />
        {selectedTab === "schedule" && (
          <BaseButton
            title="Save"
            variant="contained"
            disabled={buttonDisable}
            handleClick={saveScheduleCampaign}
          />
        )}
      </Box>
      {schedulePopup && (
        <CommonPopup
          message={"Campaign is scheduled and active"}
          show={schedulePopup}
          toggle={toggleSchedule}
          Icon={SuccessIcon}
          heading="Campaign scheduled"
        />
      )}
    </>
  );
}

export default ScheduleCampaign;
const styles = {
  calendarContainer: {
    position: "relative",
    width: "auto",
    border: "1px solid #D1D9EF",
    borderRadius: 2,
    padding: "3.5px",
    "&:hover": {
      borderColor: "#666",
    },
  },
  footer: {
    my: 3,
    display: "flex",
    gap: 1,
    position: { xs: "initial", md: "absolute" },
    right: 24,
    bottom: 0,
  },
};
