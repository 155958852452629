import React from "react";
import { Box, Typography } from "@mui/material";

const SummaryCard = ({ title, value, circleColor, errorMessage = false }) => {
  return (
    <Box
      sx={{
        minHeight: 100,
        padding: 1.5,
        borderRadius: 2,
        border: errorMessage ? "1px solid red" : "1px solid #D1D9EF",
        position: "relative",
      }}
    >
      {errorMessage && (
        <div title="Some message will come here." className="card-error-cont">
          <span className="card-error-text">Account Balance Expired</span>
        </div>
      )}
      <Box className="flex-align-center" marginBottom={1}>
        <Box
          sx={{
            width: 12,
            height: 12,
            background: circleColor,
            borderRadius: "50%",
            marginRight: 1,
          }}
        />
        <Typography className="font-14-lightgrey">{title}</Typography>
      </Box>

      <Typography
        className="font-16-700-grey"
        sx={{
          position: "absolute",
          bottom: 16,
          right: 16,
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default SummaryCard;
