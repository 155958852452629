import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as AddIcon } from "../../../assets/Images/AddIcon.svg";
import { getCustomWriteAccess } from "../CommonComponents/Common";
import SearchBar from "../Common/Search/SearchBar";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const AudienceHeader = ({ searchText, toggle, setState, viewListShow }) => {
  const reducer: any = useSelector((state) => state);

  const {
    AudienceReducer: { audienceCount },
  } = reducer;
  return (
    <Box pb={4} className="flex-between">
      <Box className="flex-xy-center" gap={1.5}>
        <Typography className="font-20-grey">Audiences</Typography>
        <Typography
          className="font-14-lightgrey"
          component="span"
          sx={commonStyles.textInfo}
        >
          Total{" "}
          <span className="font-20-700-lightgrey">{audienceCount || 0}</span>
        </Typography>
      </Box>
      {!viewListShow && (
        <Box className="flex-xy-center" gap={1}>
          <SearchBar
            searchText={searchText}
            handleChange={(e) => {
              e.persist();
              setState((prev) => ({
                ...prev,
                searchText: e.target.value,
              }));
            }}
          />

          {getCustomWriteAccess(reducer) === "write" && (
            <Button onClick={toggle} sx={commonStyles.saveButton}>
              <AddIcon style={{ marginRight: "5px" }} />
              Add New Audience
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AudienceHeader;
