import React from "react";
import { Box, Typography, Modal, Backdrop } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const BlockedNotice = () => {
  return (
    <Modal
      open={true}
      disableEscapeKeyDown
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            background:
              "linear-gradient(179.97deg, rgba(128, 47, 0, 0.8) 0.03%, rgba(29, 11, 0, 0.931745) 74.74%, #000000 93.61%)",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 450,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 3,
          py: 4,
          borderRadius: 3,
          textAlign: "left",
        }}
      >
        <Typography variant="h5" fontWeight={600} gutterBottom>
          Important Notice
        </Typography>
        <Box
          sx={{
            border: "none",
            height: "2.5px",
            background: "linear-gradient(to right, #FF5722, #FFA000)",
            width: "100%",
            mb: 2.5,
            mt: 1,
            borderRadius: "2px",
          }}
        />
        <Typography variant="body2" paragraph>
          Dear User, <strong>Vodex.ai</strong> has officially ended its
          partnership with Voizer. As a result, Voizer's services are no longer
          available on their platform. To continue using our AI-powered
          solutions, you can connect with us directly at{" "}
          <strong>Vodex.ai</strong>.
        </Typography>
        <Typography variant="body2" sx={{ mb: 3 }}>
          For any inquiries, feel free to reach out at{" "}
          <strong>
            <a
              href="mailto:contact@vodex.ai"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              contact@vodex.ai
            </a>
          </strong>
          .
        </Typography>

        <Box
          onClick={() =>
            window.open("https://vodex.ai", "_blank", "noopener,noreferrer")
          }
          sx={{
            mt: 2,
            p: 2,
            bgcolor: "#FFF8F3",
            border: "1px solid rgba(255, 87, 34, 0.3)",
            borderRadius: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              bgcolor: "#FFEDE3",
              transform: "translateY(-2px)",
              boxShadow: "0 4px 12px rgba(255, 87, 34, 0.15)",
            },
          }}
        >
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={600}
              color="#FF5722"
              textTransform="none"
            >
              Visit Vodex.ai
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Continue using our AI-powered solutions
            </Typography>
          </Box>
          <ArrowForwardIcon sx={{ color: "#FF5722" }} />
        </Box>
      </Box>
    </Modal>
  );
};

export default BlockedNotice;
