import React, { useState } from "react";
import { ScheduleConfig } from "../types/workflow";
import { Calendar, Clock, Zap } from "lucide-react";
import { Box, TextField, Typography } from "@mui/material";
import Select from "react-select";
import { customSelectStyles } from "../../../../assets/MUIStyles/multiAgent";
import timezones from "timezones-list";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const FREQUENCIES = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "monthly", label: "Monthly" },
];

const EVENT_TYPES = [
  { value: "due_date", label: "Due Date" },
  { value: "status_change", label: "Status Change" },
  { value: "assignment", label: "Assignment" },
  { value: "comment", label: "Comment" },
];

const DUE_DATE_OPTIONS = [
  { type: "before", label: "Days Before Due Date" },
  { type: "on", label: "On the Due Date" },
  { type: "after", label: "Days After Due Date" },
];

const END_TYPE_OPTIONS = [
  { value: "never", label: "Never" },
  { value: "after", label: "After number of occurrences" },
  { value: "on", label: "On date" },
  { value: "event", label: "At an event" },
];

interface ScheduleConfigProps {
  value: ScheduleConfig;
  onChange: (config: ScheduleConfig) => void;
}

const ScheduleConfigComponent: React.FC<ScheduleConfigProps> = ({
  value,
  onChange,
}) => {
  const [dueDateConditions, setDueDateConditions] = useState<any>(
    value.endEvent?.conditions || [
      {
        type: "on",
      },
    ]
  );

  const handleChange = (updates: Partial<ScheduleConfig>) => {
    let resetFields: Partial<ScheduleConfig> = {};

    if (updates.frequency === "daily") {
      resetFields = {
        days: undefined,
        frequencyDays: undefined,
        date: undefined,
      };
    } else if (updates.frequency === "weekly") {
      resetFields = { date: undefined };
    } else if (updates.frequency === "monthly") {
      resetFields = { days: undefined, frequencyDays: undefined };
    }

    onChange({ ...value, ...resetFields, ...updates });
  };

  const updateDueDateCondition = (condition: (typeof dueDateConditions)[0]) => {
    const newConditions = [condition];
    setDueDateConditions(newConditions);
    let obj: any = {
      type: "due_date",
      conditions: newConditions,
    };
    handleChange({
      endEvent: obj,
    });
  };

  return (
    <Box className="schedule-container">
      <Box>
        <Typography className="form-label">Frequency</Typography>

        <Select
          name="frequency"
          value={
            FREQUENCIES.find((option) => option.value === value.frequency) ||
            null
          }
          onChange={(selectedOption: any) =>
            handleChange({
              frequency: selectedOption?.value,
            })
          }
          options={FREQUENCIES}
          getOptionLabel={(e: any) => e?.label}
          getOptionValue={(e: any) => e?.value}
          placeholder="Select Frequency"
          styles={customSelectStyles}
        />
      </Box>

      {value.frequency === "weekly" && (
        <Box>
          <Typography className="form-label">Days of Week</Typography>
          <Box className="days-grid mx-3 mt-1 ">
            {DAYS.map((day) => (
              <label key={day} className="day-checkbox">
                <input
                  type="checkbox"
                  checked={value.days?.includes(day) || false}
                  onChange={(e) => {
                    const days = new Set(value.days || []);
                    if (e.target.checked) {
                      days.add(day);
                    } else {
                      days.delete(day);
                    }
                    const frequencyDays = DAYS.map((day) =>
                      days.has(day) ? 1 : 0
                    );

                    handleChange({
                      days: Array.from(days),
                      frequencyDays,
                    });
                  }}
                  className="checkbox"
                />
                <span className="day-label">{day.slice(0, 3)}</span>
              </label>
            ))}
          </Box>
        </Box>
      )}

      {value.frequency === "monthly" && (
        <Box>
          <Typography className="form-label">Days of Month</Typography>
          <TextField
            type="number"
            value={value?.date || 1}
            onChange={(e) => handleChange({ date: parseInt(e.target.value) })}
            variant="outlined"
            fullWidth
            sx={styles.textInput}
            inputProps={{ min: 1, max: 31 }}
          />
        </Box>
      )}

      <Box>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography className="form-label">Time </Typography>
          <Clock size={16} />
        </Box>
        <TextField
          type="time"
          value={value?.time}
          onChange={(e) => handleChange({ time: e.target.value })}
          fullWidth
          sx={{ ...styles.textInput, paddingY: 1 }}
        />
      </Box>

      <Box>
        <Typography className="form-label">Time Zone</Typography>

        <Select
          name="frequency"
          value={
            timezones.find((option) => option?.tzCode === value?.timezone) ||
            null
          }
          onChange={(selectedOption: any) =>
            handleChange({
              timezone: selectedOption?.tzCode,
            })
          }
          options={timezones}
          getOptionLabel={(e: any) => e?.label}
          getOptionValue={(e: any) => e?.tzCode}
          placeholder="Select Timezone"
          styles={customSelectStyles}
        />
      </Box>

      <Box>
        <Typography className="form-label">End</Typography>

        <Select
          value={END_TYPE_OPTIONS.find(
            (option) => option.value === value.endType
          )}
          onChange={(selected: any) =>
            handleChange({
              endType: selected?.value,
              endEvent: undefined,
            })
          }
          options={END_TYPE_OPTIONS}
          placeholder="Select end type"
          styles={customSelectStyles}
        />
      </Box>

      {value.endType === "after" && (
        <Box>
          <Typography className="form-label">Number of occurrences</Typography>
          <TextField
            type="number"
            value={value.endAfter || 1}
            onChange={(e) =>
              handleChange({ endAfter: parseInt(e.target.value) })
            }
            variant="outlined"
            fullWidth
            sx={styles.textInput}
            inputProps={{ min: 1 }}
          />
        </Box>
      )}

      {value.endType === "on" && (
        <Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography className="form-label">End date </Typography>
            <Calendar size={16} />
          </Box>
          <TextField
            type="date"
            value={value.endDate}
            onChange={(e) => handleChange({ endDate: e.target.value })}
            fullWidth
            sx={styles.textInput}
          />
        </Box>
      )}

      {value.endType === "event" && (
        <>
          <Box mb={2}>
            <Box display="flex" alignItems="center" gap={1}>
              <Typography className="form-label">Event Type</Typography>
              <Zap size={16} />
            </Box>

            <select
              value={value.endEvent?.type || ""}
              onChange={(e) => {
                const type: any = e.target.value;
                handleChange({
                  endEvent: {
                    type,
                    conditions:
                      type === "due_date" ? [{ type: "on" }] : undefined,
                  },
                });
              }}
              className="schedule-select"
            >
              <option value="">Select event type</option>
              {EVENT_TYPES.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </select>
          </Box>

          {value.endEvent?.type === "due_date" && (
            <Box>
              <Typography className="form-label">
                Due Date Condition{" "}
              </Typography>

              <div className="event-conditions">
                <select
                  value={dueDateConditions[0].type}
                  onChange={(e) =>
                    updateDueDateCondition({
                      type: e.target
                        .value as (typeof dueDateConditions)[0]["type"],
                      days: e.target.value === "on" ? undefined : 1,
                    })
                  }
                  className="schedule-select"
                >
                  {DUE_DATE_OPTIONS.map((option) => (
                    <option key={option.type} value={option.type}>
                      {option.label}
                    </option>
                  ))}
                </select>

                {(dueDateConditions[0].type === "before" ||
                  dueDateConditions[0].type === "after") && (
                  <input
                    type="number"
                    min="1"
                    value={dueDateConditions[0].days || 1}
                    onChange={(e) =>
                      updateDueDateCondition({
                        ...dueDateConditions[0],
                        days: parseInt(e.target.value),
                      })
                    }
                    className="schedule-input small-input"
                    placeholder="Days"
                  />
                )}
              </div>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ScheduleConfigComponent;

const styles = {
  textInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      borderColor: "#CCD2DE",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-input": {
      paddingX: 1.5,
      paddingY: 1,
      "&::placeholder": {
        color: "#8792A7",
        fontSize: 12,
        fontWeight: 400,
      },
    },
  },
};
