export const commonStyles = {
  clearButton: {
    textDecoration: "none",
    textTransform: "none",
    color: "rgba(85, 85, 85, 1)",
    fontWeight: "500",
    fontSize: "13px",
    background: "#FFFFFF",
    borderRadius: 2,
    px: 2,
    py: "7px",
    width: "auto",
    height: "38px",
    border: "1px solid rgba(204, 210, 222, 1)",
    "&:hover": {
      background: "#E0E0E0",
    },
  },
  saveButton: {
    textDecoration: "none",
    textTransform: "none",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "13px",
    background: "rgba(255, 94, 0, 0.75)",
    borderRadius: 2,
    px: 2,
    py: "7px",
    width: "auto",
    height: "38px",
    "&:hover": {
      background: "#E95500 !important",
    },
    "&.Mui-disabled": {
      backgroundColor: "#E95500",
    },
  },
  inputField: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
      padding: 0,
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
  hideScroll: {
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  customSelect: {
    control: (provided) => ({
      ...provided,
      border: "1px solid #CCD2DE",
      padding: "2px",
      borderRadius: 8,
      boxShadow: "none",
      fontSize: 13,
      "&:hover": {
        borderColor: "#CCD2DE",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555555",
      fontSize: 13,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333333",
      fontSize: 13,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      border: "1px solid #CCD2DE",
      padding: "5px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 2,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#333333" : "#555555",
      backgroundColor: state.isSelected ? "#DDE8FF" : "#FFFFFF",
      fontSize: 13,
      borderRadius: 2,
      padding: "10px 12px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#DDE8FF" : "#F2F2F2",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  },
  textInfo: {
    border: 1,
    borderColor: "#CCD2DE",
    px: 1.5,
    py: 0.25,
    borderRadius: "9px",
  },
  phoneInput: {
    buttonStyle: {
      background: "#f4f4f4",
      border: "1px solid #E6EBF7",
      backgroundColor: "#F5F7FB",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    inputStyle: {
      padding: "20px 10px 20px 50px",
      border: "1px solid #CCD2DE",
      width: "100%",
    },
    containerStyle: {
      borderRadius: "8px",
    },
  },
  tabs: {
    marginTop: 2,
    backgroundColor: "#F1F4FA",
    width: "fit-content",
    borderRadius: "6px",
    minHeight: 36,
    ".MuiTabs-indicator": {
      display: "none",
    },
    ".MuiTabs-scroller": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginX: 0.5,
    },
  },
  tab: {
    fontSize: 14,
    fontWeight: 400,
    textTransform: "none",
    padding: "2px 8px",
    color: "#757B93",
    borderRadius: "8px",
    minHeight: 30,
    "&.Mui-selected": {
      backgroundColor: "#fff",
      color: "#333333",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
  },
  loginButton: {
    mt: 1,
    borderRadius: 2,
    bgcolor: "#FF5E00",
    fontSize: 13,
    fontWeight: 600,
    padding: 1.5,
    textTransform: "none",
    ":hover": { bgcolor: "#E95500" },
    "&.Mui-disabled": {
      backgroundColor: "#E95500",
    },
  },
  skeletonStyle: {
    backgroundColor: "#F3F6FD",
    borderRadius: "6px",
  },
  addBtn: {
    textTransform: "none",
    color: "#656E7F",
    border: 0,
    backgroundColor: "#F1F4FA",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 2,
    padding: "4px 10px",
    "&:hover": {
      transition: "width 0.5s ease-in-out",
      border: 0,
    },
  },
  favBtn: {
    minWidth: "auto",
    p: "10px",
    borderColor: "#D1D9EF",
    "&:hover": {
      borderColor: "#666",
    },
  },
};
