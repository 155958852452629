import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../Constant/swalMessage";
import {
  acceptInviteService,
  checkUserService,
  cloneVoiceService,
  deleteInvitedUsersService,
  deleteVoiceService,
  editInvitedUserService,
  getAccountHistoryService,
  getInviteListService,
  getVoiceListService,
  inviteUserService,
  saveVoiceService,
  sendSmsToSupportService,
} from "../../services/access.services";

import { accessType } from "./access.type";
export const inviteUserAction = (obj) => async (dispatch) => {
  let res = await inviteUserService(obj);

  if (res?.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const checkUserAction = (obj) => async (dispatch) => {
  let res = await checkUserService(obj);
  if (res.status === 200) {
    return res.data;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const acceptInviteAction = (obj) => async () => {
  let res = await acceptInviteService(obj);
  if (res.status === 200) {
    swalSuccessMessage(res.msg);
    return res.status;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getInviteListAction = (obj) => async (dispatch) => {
  let res = await getInviteListService(obj);
  if (res.status === 200) {
    await dispatch({
      type: accessType.GET_INVITE_USERS_LIST,
      payload: res?.data || [],
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const deleteInvitedUserAction = (obj) => async (dispatch) => {
  let res = await deleteInvitedUsersService(obj);
  if (res.status === 200) {
    // swalSuccessMessage("Successfully Deleted.");
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const editInvitedUserAction = (obj, id) => async () => {
  let res = await editInvitedUserService(obj, id);
  if (res?.status === 200) {
    // swalSuccessMessage(res?.msg);
    return true
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getAccountHistoryAction = (obj) => async (dispatch) => {
  let res = await getAccountHistoryService(obj);
  if (res.status === 200) {
    await dispatch({
      type: accessType.ACCOUNT_HISTORY_LIST,
      payload: {
        data: res?.data?.data || [],
        totalCount: res?.data?.count[0]?.count || 0,
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
      },
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const saveVoiceUserAction = (obj) => async () => {
  let res = await saveVoiceService(obj);
  if (res?.status === 200) {
    return res.data;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const cloneVoiceAction = (obj) => async () => {
  let res = await cloneVoiceService(obj);
  if (res) {
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const deleteVoiceAction = (obj) => async () => {
  let res = await deleteVoiceService(obj);
  if (res) {
    // swalSuccessMessage("Voice deleted successfully");
    return true;
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const getVoicesListAction = (obj) => async (dispatch) => {
  let res = await getVoiceListService(obj);
  if (res.status === 200) {
    await dispatch({
      type: accessType.ACCOUNT_VOICES_LIST,
      payload: res?.data || [],
    });
  } else {
    swalErrorMessage(res?.errMsg);
  }
};

export const sendSmsToSupportAction =
  (obj, localMessage = "") =>
  async () => {
    let res = await sendSmsToSupportService(obj);
    if (res.status === 200) {
      if (localMessage) {
        swalSuccessMessage(localMessage);
      }
    } else {
      swalErrorMessage(res?.errMsg);
    }
  };
