import React, { useEffect, useState } from "react";
import { Box, List, ListItem, ListItemButton, Typography } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../../../assets/Images/SearchIcon.svg";

import VodexLogoCollapsed from "../../../../assets/Images/SingleLogo.png";
import VodexLogoExpanded from "../../../../assets/Images/VodexLogo.png";
import EmailMenu from "./EmailMenu";
import {
  getUerAccountsDetailsAction,
  saveUserDetailsAction,
} from "../../Actions/CommonActions";
import Select from "react-select";
import SearchBar from "./SearchBar";
import {
  checkRolesAccess,
  isEmpty,
  isVodexLoggedin,
} from "../../CommonComponents/Common";
import { Scrollbars } from "react-custom-scrollbars";
import packageJson from "./../../../../../package.json";

interface MenuItem {
  value: string;
  label: string;
}

const drawerWidth = 265;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(10)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  "& .MuiDrawer-paper": {
    borderRight: "none",
  },
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Sidebar = ({ styles, open, handleDrawer, menuItems, obj }) => {
  const {
    setShowSettings,
    activeItemId,
    setActiveItemId,
    secondaryMenu,
    setShowAdmin,
    adminRoutes,
    settingsRoutes,
    isProjectDetailPage,
    editModalOpen,
    setEditModalOpen,
    setAnchorEl,
    anchorEl,
    setProfilePopUp,
  } = obj;
  const history = useHistory();
  const dispatch = useDispatch();
  const reducer = useSelector((reducer: any) => reducer);
  const currentVersion = packageJson.version;
  const location = useLocation();
  const projectId = location.pathname.split("/")[3];
  const {
    AccountReducer: { userDetails },
  } = reducer;
  const {
    AccountReducer: {
      userDetails: { role },
      selectedAccount,
      whitelabelDetails,
      accountRoles,
    },
  } = reducer;
  const [inputValue, setInputValue] = useState("");
  const [formData, setFormData] = useState(
    userDetails || {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      pincode: "",
    }
  );
  const [searchModal, setSearchModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [visitedItems, setVisitedItems] = useState([]);
  const [access, setAccess] = useState("");

  useEffect(() => {
    const acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  useEffect(() => {
    if (userDetails) {
      setFormData(userDetails);
    }
  }, [userDetails]);

  useEffect(() => {
    const pathname = location?.pathname;
    const showSetting = settingsRoutes.find((path) => path?.to === pathname);
    const showAdminSettings = adminRoutes.find((path) => path?.to === pathname);
    if (showSetting) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
    if (showAdminSettings) {
      setShowAdmin(true);
    }
  }, [location.pathname]);

  const handleRoutes = (item) => {
    if (item.to === "/account/access") {
      history.push("/account/access");
      setShowSettings(true);
      setShowAdmin(false);
    } else if (item.to === "access") {
      setShowAdmin(true);
      setShowSettings(false);
      history.push("/account/admin");
    } else {
      history.push(item.to);
      setShowSettings(false);
      setShowAdmin(false);
    }
  };

  const updateUserDetails = async (updatedDetails) => {
    let res: any = await dispatch(saveUserDetailsAction(updatedDetails));
    await dispatch(getUerAccountsDetailsAction());
    if (res) {
      setProfilePopUp(true);
    }
  };
  const menu = [
    {
      id: "agents",
      text: "Agents",
      to: `/account/project/${projectId}/agents`,
    },
    {
      id: "audience",
      text: "Audience",
      to: `/account/project/${projectId}/audiences`,
    },
    {
      id: "campaigns",
      text: "Campaigns",
      to: `/account/project/${projectId}/campaigns`,
    },
    {
      id: "call_settings",
      text: "Call settings",
      to: `/account/project/${projectId}/call-settings`,
    },
    {
      id: "messaging",
      text: "Messaging",
      to: `/account/project/${projectId}/common`,
    },
  ];

  const projectItems = selectedAccount?.params?.dialerConfig
    ? [
        ...menu,
        {
          id: "dialer_config",
          text: "Dialer config",
          to: `/account/project/${projectId}/dialer-config`,
        },
      ]
    : menu;

  const combinedMenuItems = isProjectDetailPage
    ? [...menuItems, ...secondaryMenu, ...projectItems]
    : [...menuItems, ...secondaryMenu];

  const options: MenuItem[] = combinedMenuItems?.map((item) => ({
    value: item.to,
    label: item.text,
  }));

  const handleSearchBar = () => {
    setSearchModal((prev) => !prev);
    setIsVisible(true);
  };

  const handleSelectItems = (e) => {
    if (e) {
      if (e.value === "/account/access") {
        setShowSettings(true);
        setShowAdmin(false);
      } else if (e.value === "/account/admin") {
        setShowAdmin(true);
        setShowSettings(false);
      } else {
        setShowSettings(false);
        setShowAdmin(false);
      }

      let visitedLoc: any = localStorage.getItem("visitedItems");
      let visitedItems = JSON.parse(visitedLoc) || [];

      const newItem = { name: e.value, label: e.label };

      if (!visitedItems.some((item) => item.name === newItem.name)) {
        visitedItems.push(newItem);

        localStorage.setItem("visitedItems", JSON.stringify(visitedItems));
        setVisitedItems(visitedItems);
      }

      localStorage.setItem("visitedItems", JSON.stringify(visitedItems));
    }
    history.push(e.value);
  };

  const renderItems = (listItems) => {
    return (
      <List>
        {listItems.map((item, index) => (
          <ListItem
            title={item?.text}
            sx={{ marginBottom: "4px" }}
            onClick={() => {
              handleRoutes(item);
              setEditModalOpen(false);
            }}
            key={item.text}
            disablePadding
          >
            <ListItemButton
              onClick={() => setActiveItemId(item.id)}
              sx={{
                ...styles.listItemConatiner,
                backgroundColor:
                  activeItemId === item.id ? "#FF6A00" : "transparent",
                px: `${open ? "10px" : "0"}`,
                justifyContent: `${open ? "space-between" : "center"}`,
                "&:hover": {
                  backgroundColor:
                    activeItemId === item.id ? "#FF6A00" : "#333",
                },
                transition: "background-color 0.3s",
              }}
            >
              <Box
                className="flex-align-center"
                sx={{
                  justifyContent: open ? "flex-start" : "center",
                }}
              >
                <span
                  style={{
                    color: activeItemId === item.id ? "#fff" : "#A0A0A0",
                  }}
                >
                  {item.icon}
                </span>
                {open && (
                  <Typography
                    ml={1}
                    sx={{
                      fontSize: 14,
                      fontWeight: 400,
                      color: activeItemId === item.id ? "#fff" : "#A0A0A0",
                    }}
                  >
                    {item.text}
                  </Typography>
                )}
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  };

  let emailMenuObj = {
    formData,
    setFormData,
    editModalOpen,
    setEditModalOpen,
    userDetails,
    updateUserDetails,
    styles,
    open,
    setAnchorEl,
    anchorEl,
  };

  let searchbarObj = {
    handleSearchBar,
    isVisible,
    options,
    setInputValue,
    inputValue,
    setShowSettings,
    setShowAdmin,
    visitedItems,
    setVisitedItems,
    setSearchModal,
  };

  return (
    <Drawer
      sx={{
        ...styles.bg,
        "& .MuiDrawer-paper": {
          backgroundColor: styles.bg.backgroundColor,
        },
      }}
      variant="permanent"
      open={open}
    >
      <DrawerHeader
        sx={{
          ...styles.bg,
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: "24px",
        }}
      >
        <Box>
          {isVodexLoggedin() && (
            <Link
              className=""
              to={
                access === "dashboard"
                  ? `/account/dashboard`
                  : `/account/projects`
              }
            >
              <img
                src={open ? VodexLogoExpanded : VodexLogoCollapsed}
                alt="vodex"
              />
            </Link>
          )}

          {!isEmpty(whitelabelDetails) && (
            <a
              className="display-mobile sidebar-brand d-flex align-items-center justify-content-center"
              style={{
                pointerEvents: "none",
                marginLeft:
                  whitelabelDetails?.marginLeft === "0"
                    ? "-60px"
                    : whitelabelDetails?.marginLeft,
              }}
              href={"void"}
            >
              {whitelabelDetails?.imgLink && (
                <img
                  style={{
                    transform: `rotate(${whitelabelDetails?.rotate})`,
                  }}
                  src={whitelabelDetails?.imgLink}
                  className={`${
                    open ? "white-label-image-lg" : "white-label-image"
                  } `}
                />
              )}
            </a>
          )}
        </Box>
      </DrawerHeader>

      <Box sx={{ ...styles.bg }}>
        <Box mt={3} mx={2} mb={4}>
          <Box>
            {open ? (
              <Box
                className="flex-align-center"
                borderRadius={2}
                border={"1px solid rgba(47, 53, 62, 1)"}
                bgcolor={"rgba(17, 17, 17, 1)"}
              >
                <SearchIcon className="ml-2" />
                <Select
                  options={options}
                  isSearchable={true}
                  inputValue={inputValue}
                  onInputChange={(value) => {
                    if (value.length > 20) {
                      return;
                    }
                    setInputValue(value);
                  }}
                  value={options?.find(
                    (option) => option?.value === inputValue
                  )}
                  placeholder="Search..."
                  className="basic-single project-select"
                  classNamePrefix="select"
                  getOptionLabel={(e) => e?.label}
                  getOptionValue={(e) => e?.value}
                  onChange={(e) => handleSelectItems(e)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  styles={styles.searchBar}
                />
              </Box>
            ) : (
              <Box
                component={"span"}
                onClick={handleSearchBar}
                display={"flex"}
                justifyContent={"center"}
                sx={{ cursor: "pointer" }}
              >
                <SearchIcon />
              </Box>
            )}
          </Box>
        </Box>
        {open && <hr style={{ borderTop: "1px solid #242931" }} />}
        <Box mt={2}>
          <Scrollbars
            style={{
              height: "45vh",
              color: "white",
              borderRadius: "10px",
            }}
            renderThumbVertical={({ style, ...props }) => {
              const thumbStyle = {
                backgroundColor: "#6c757d",
                borderRadius: "10px",
                width: "3px",
              };
              return <div style={{ ...style, ...thumbStyle }} {...props} />;
            }}
            autoHide={true}
          >
            <Box mx={2}>{renderItems(menuItems)}</Box>
          </Scrollbars>
          <Box
            sx={{
              position: "absolute",
              bottom: "8px",
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              width: "100%",
              px: 2,
            }}
          >
            {renderItems(secondaryMenu)}
            <EmailMenu obj={emailMenuObj} />
            {currentVersion && (
              <Box
                sx={{
                  color: "#A0A0A0",
                  fontSize: " 8px",
                  textAlign: open ? "right" : "center",
                }}
              >
                V.{currentVersion}
              </Box>
            )}
          </Box>
        </Box>
        {searchModal && <SearchBar obj={searchbarObj} />}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
