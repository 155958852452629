import React from "react";
import { ReactComponent as EmptyStateIcon } from "../../../assets/icons/empty-state.svg";
import { Box, Typography } from "@mui/material";

const EmptyState = ({ message = "No Data Found" }) => {
  return (
    <Box
      my={1}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyItems="center"
      justifyContent="center"
      alignItems="center"
      gap={2}
      p={3}
      position="relative"
    >
      <Typography
        fontSize={20}
        fontWeight={500}
        color="#555555"
        textTransform="capitalize"
        textAlign="center"
        position="absolute"
        top={90}
        left="50%"
        sx={{
          transform: "translateX(-50%)",
        }}
      >
        {message} ...
      </Typography>
      <Box>
        <EmptyStateIcon />
      </Box>
    </Box>
  );
};

export default EmptyState;
