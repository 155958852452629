import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as VodexLogo } from "../../../assets/Images/VodexLogo1.svg";
import { isVodexLoggedin } from "../CommonComponents/Common";

const OnboardingHeader = () => {
  return (
    <Box className="onboarding-header flex-align-center ">
      {isVodexLoggedin() && <VodexLogo />}
    </Box>
  );
};

export default OnboardingHeader;
