import {
  swalErrorMessage,
  swalSuccessMessage,
} from "../../../../../Constant/swalMessage";
import {
  deleteAudienceService,
  editAudienceExcelService,
  editAudienceService,
  getAudienceService,
  getAudienceServiceForSelect,
  saveAudienceExcelService,
  saveAudienceService,
} from "../../../services/audience.services";

import { AudienceType } from "./Audience.type";
export const getAudienceAction = (id, obj) => async (dispatch) => {
  let res = await getAudienceService(id, obj);
  if (res) {
    await dispatch({
      type: AudienceType.GET_AUDIENCE_DATA,
      payload: {
        data: res?.data?.doc || [],
        totalCount: res?.data?.totalCount || 0,
        searchText: obj?.searchText,
        isScrolled: obj?.isScrolled,
      },
    });
  }
};

export const saveAudienceAction = (obj) => async (dispatch) => {
  let res = await saveAudienceService(obj);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const deleteAudienceAction = (id) => async () => {
  let res = await deleteAudienceService(id);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const setExpandViewAction = (type) => async (dispatch) => {
  await dispatch({
    type: AudienceType.SET_EXPAND_VIEW,
    payload: type,
  });
};

export const editAudienceAction = (id, obj) => async (dispatch) => {
  let res = await editAudienceService(id, obj);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return res.status;
  } else {
    swalErrorMessage(res.errMsg);
  }
};

export const getAudienceForSelectAction = (id, obj) => async (dispatch) => {
  let res = await getAudienceServiceForSelect(id, obj);
  if (res) {
    return res?.data?.doc;
  }
};

export const saveAudienceExcelAction = (obj) => async () => {
  let res = await saveAudienceExcelService(obj);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};
export const editAudienceExcelAction = (id, obj) => async () => {
  let res = await editAudienceExcelService(id, obj);
  if (res.status === 200) {
    // swalSuccessMessage(res.msg);
    return true;
  } else {
    swalErrorMessage(res.errMsg);
  }
};
export const clearAudienceReducer = () => async (dispatch) => {
  await dispatch({
    type: AudienceType.CLEAR_AUDIENCE_DATA,
    payload: {},
  });
};
