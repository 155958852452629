import React from "react";
import { Box, Radio, TextField, Typography } from "@mui/material";
import { ReactComponent as AlertIcon } from "../../../../assets/Images/Alert.svg";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const RedialBody = ({ redialObj }) => {
  const {
    setCampaignName,
    campaignName,
    audienceName,
    setAudienceName,
    range,
    setRange,
  } = redialObj;

  return (
    <Box minHeight={300}>
      <Box>
        <Box>
          <Typography className="font-14-grey mb-2">Campaign Name</Typography>
          <TextField
            name="name"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
            variant="outlined"
            fullWidth
            placeholder="Example it can be “Real Estate Campaign”"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 35 }}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Typography className="font-14-grey mb-2">Audience</Typography>
        <TextField
          name="audname"
          value={audienceName}
          onChange={(e) => setAudienceName(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="Enter Audience Name"
          sx={commonStyles.inputField}
          inputProps={{ maxLength: 35 }}
        />
      </Box>
      <Box mt={2}>
        <Typography className="font-14-grey mb-2">Campaign Type :</Typography>
        <Box className="flex-row" gap={1}>
          <Box className="flex-xy-center" gap={0.5}>
            <Radio
              checked={!range}
              onChange={() => {
                setRange(false);
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography className="font-14-grey" mb={0}>
              Default
            </Typography>
          </Box>
          <Box className="flex-xy-center" gap={1}>
            <Radio
              checked={range}
              onChange={() => {
                setRange(true);
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography className="font-14-grey" mb={0}>
              Range
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        mt={2}
        className="flex-xy-center font-alert-text"
        sx={{
          height: "50px",
          maxWidth: "410px",
          bgcolor: "#F0FAFF",
          borderRadius: 2,
        }}
        component={"span"}
      >
        <AlertIcon className="mr-2" />
        Note: Please use unique names for both campaign and audience
      </Box>
    </Box>
  );
};

export default RedialBody;
