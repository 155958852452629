import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { leftMenuStyle } from "../../../../assets/MUIStyles/sidebarMenu";
import "../../../../assets/css/page/MainLayout.scss";

import { ReactComponent as CallHistoryIcon } from "../../../../assets/Images/CallHistoryIcon.svg";
import { ReactComponent as DasboardIcon } from "../../../../assets/Images/Dashboard.svg";
import { ReactComponent as LeftPointer } from "../../../../assets/Images/LeftPointer.svg";
import { ReactComponent as ProjectIcon } from "../../../../assets/Images/ProjectIcon.svg";
import { ReactComponent as QuickStartIcon } from "../../../../assets/Images/Quick start.svg";
import { ReactComponent as RightPointer } from "../../../../assets/Images/RightPointer.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/Images/SettingsIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/Images/SupportIcon.svg";
import { ReactComponent as AdminIcon } from "../../../../assets/Images/Admin.svg";
import { ReactComponent as Call } from "../../../../assets/Images/Call.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";

import NewRoutes from "./NewRoutes";
import ProjectSidebar from "./ProjectSidebar";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useSelector } from "react-redux";
import {
  checkRolesAccess,
  isVodexLoggedin,
} from "../../CommonComponents/Common";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";

const menu = [
  {
    id: "quick_start",
    text: "Quick start",
    icon: <QuickStartIcon />,
    to: "/onboarding?from=dashboard",
  },
  {
    id: "dashboard",
    text: "Dashboard",
    icon: <DasboardIcon />,
    to: "/account/dashboard",
  },
  {
    id: "projects",
    text: "Projects",
    icon: <ProjectIcon />,
    to: "/account/projects",
  },
  {
    id: "call_history",
    text: "Call History",
    icon: <CallHistoryIcon />,
    to: "/account/call-history",
  },
  {
    id: "campaigns-running",
    text: "Campaigns Running",
    icon: <Call />,
    to: "/account/running-campaign",
  },
  {
    id: "cps-settings",
    text: "CPS Settings",
    icon: <i className={`fas fa-toolbox`}></i>,
    to: "/account/cps-settings",
  },
  {
    id: "capacity-settings",
    text: "Capacity Settings",
    icon: <i className={`fas fa-toolbox`}></i>,
    to: "/account/capacity-settings",
  },
];

const secondaryMenuItems = [
  {
    id: "admin",
    text: "Admin Control",
    icon: <AdminIcon />,
    to: "/account/admin",
  },
  {
    id: "settings",
    text: "Settings",
    icon: <SettingsIcon />,
    to: "/account/access",
  },
  ...(isVodexLoggedin()
    ? [
        {
          id: "support",
          text: "Support",
          icon: <SupportIcon />,
          to: "/account/support-email",
        },
      ]
    : []),
];

const adminRoutes = [
  { id: "admin", to: "/account/admin" },
  {
    to: "/account/account-topup",
  },
  {
    to: "/account/recharge",
  },
];

const settingsRoutes = [
  { to: "/account/access" },
  {
    to: "/account/voice-cloning",
  },
  { to: "/account/history" },
  { to: "/account/integrations" },
];

export default function MainLayout() {
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { accountRoles },
  } = reducer;
  const {
    AccountReducer: {
      userDetails: { role },
    },
  } = reducer;
  const {
    AccountReducer: { selectedAccount },
  } = reducer;
  const [open, setOpen] = useState(false);
  const [activeItemId, setActiveItemId] = useState("dashboard");
  const [showSettings, setShowSettings] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [access, setAccess] = useState("");
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [profilePop, setProfilePopUp] = useState(false);

  const location = useLocation();
  const currentPath = location?.pathname;
  const theme = useTheme();
  const styles = leftMenuStyle(theme, open);
  const isProjectDetailPage =
    location.pathname.startsWith("/account/project/") &&
    location.pathname !== "/account/projects";

  useEffect(() => {
    let acc: any = checkRolesAccess(accountRoles);
    setAccess(acc);
  }, [accountRoles]);

  useEffect(() => {
    setOpen(false);
  }, [isProjectDetailPage]);

  useEffect(() => {
    const updateActiveItem = () => {
      if (currentPath.includes("/account/project/")) {
        return "projects";
      }
      const activeMenu = [...menu, ...secondaryMenuItems].find(
        (item) => item.to === currentPath
      );
      if (activeMenu) {
        return activeMenu.id;
      }

      if (settingsRoutes.some((route) => route.to === currentPath)) {
        return "settings";
      }
      if (adminRoutes.some((route) => route.to === currentPath)) {
        return "admin";
      }
      return null;
    };

    const activeId = updateActiveItem();
    if (activeId) {
      setActiveItemId(activeId);
    }
  }, [currentPath]);

  const handleDrawer = () => {
    setOpen((prev) => !prev);
    setAnchorEl(null);
  };

  const toggleProfilePopup = () => {
    setProfilePopUp((prev) => !prev);
  };

  const menuItems = menu.filter((item) => {
    if (item.id === "quick_start") {
      return checkRolesAccess(accountRoles, "projectPage");
    }

    if (item.id === "dashboard") {
      return access === "dashboard";
    }
    if (item.id === "campaigns-running") {
      return selectedAccount?.params?.liveCampaigns;
    }
    if (item.id === "cps-settings") {
      return selectedAccount?.params?.cpsAccess;
    }
    if (item.id === "capacity-settings") {
      return selectedAccount?.params?.capacityAccess;
    }

    return true;
  });

  const secondaryMenu = secondaryMenuItems?.filter((item) => {
    if (item.id === "settings") {
      return access === "dashboard";
    }
    if (item.id === "admin") {
      return role === "super_admin" || role === "admin" || role === "sales";
    }
    return true;
  });

  let sidebarObj = {
    setShowSettings,
    activeItemId,
    setActiveItemId,
    secondaryMenu,
    setShowAdmin,
    adminRoutes,
    settingsRoutes,
    isProjectDetailPage,
    editModalOpen,
    setEditModalOpen,
    setAnchorEl,
    anchorEl,
    setProfilePopUp,
  };

  return (
    <Box className="main-layout-container">
      <CssBaseline />
      <Box className="top-bar-container">
        <Topbar
          showAdminControls={showAdmin}
          styles={styles}
          open={open}
          showSettings={showSettings}
        />
      </Box>
      {!isProjectDetailPage && (
        <Box
          sx={{
            display: { xs: "none", lg: "block" },
            ...(open ? styles.leftPointer : styles.rightPointer),
          }}
        >
          {open ? (
            <LeftPointer onClick={handleDrawer} />
          ) : (
            <RightPointer onClick={handleDrawer} />
          )}
        </Box>
      )}

      <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
        <Sidebar
          styles={styles}
          menuItems={menuItems}
          open={open}
          handleDrawer={handleDrawer}
          obj={sidebarObj}
        />
        {isProjectDetailPage && (
          <ProjectSidebar
            setEditModalOpen={setEditModalOpen}
            drawerOpen={open.toString()}
          />
        )}
        <Box
          sx={{
            flexGrow: 1,
            height: "calc(100vh - 64px)",
            overflow: "hidden",
            marginLeft: isProjectDetailPage ? "200px" : "0",
            transition: "margin-left 0.3s ease",
          }}
        >
          <Box className="flex-column inner-main-container">
            <Box
              sx={{
                px: 2,
                pt: 2,
                flex: 1,
                overflowY: "auto",
                "&::-webkit-scrollbar": { display: "none" },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              <NewRoutes />
            </Box>
          </Box>
        </Box>
      </Box>
      {profilePop && (
        <CommonPopup
          message={`Profile updated Successfully`}
          show={profilePop}
          toggle={toggleProfilePopup}
          Icon={SuccessIcon}
          heading="Profile Updated"
        />
      )}
    </Box>
  );
}
