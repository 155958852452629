import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "./SoftBox";
import SoftTypography from "./SoftTypography";
import { shortenString } from "../../../Constant/helper";

function MiniStatisticsCard({
  bgColor,
  title,
  count,
  percentage,
  errorMessage,
  icon,
  direction,
}) {
  return (
    <Card className={`card-transform ${errorMessage && "card-error"} `}>
      {errorMessage && (
        <div title="Some message will come here." className="card-error-cont">
          <span className="card-error-text">Account Balance Expired</span>
        </div>
      )}
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            <Grid item xs={8}>
                <div title={`${title.text}`}>
                <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                  <SoftTypography
                    sx={{
                      display: "block",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "100%",
                    }}
                    variant="button"
                    color={bgColor === "white" ? "text" : "white"}
                    opacity={bgColor === "white" ? 1 : 0.7}
                    textTransform="capitalize"
                    fontWeight={title.fontWeight}
                  >
                    {/* {shortenString(title.text, 15)} */}
                    {title.text}
                  </SoftTypography>
                  <SoftTypography
                    variant="h5"
                    fontWeight="bold"
                    color={bgColor === "white" ? "dark" : "white"}
                  >
                    {count}{" "}
                    {/* <SoftTypography
                    variant="button"
                    color={percentage.color}
                    fontWeight="bold"
                  >
                    {percentage.text}
                  </SoftTypography> */}
                  </SoftTypography>
                </SoftBox>
                </div>
                
            </Grid>
            <Grid item xs={4}>
              {!errorMessage && (
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  {/* <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon> */}

                  <i className={`fas fa-${icon.component}`}></i>
                </SoftBox>
              )}
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
MiniStatisticsCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
MiniStatisticsCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default MiniStatisticsCard;
