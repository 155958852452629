import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../CommonComponents/Common";
import {
  swalErrorMessage,
  swalWarningMessage,
} from "../../../../Constant/swalMessage";
import {
  getInsightsCampaignAction,
  saveInsightsCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Delete.svg";
import AddIcon from "@mui/icons-material/Add";
import CustomScrollbars from "../../CommonComponents/CustomScrollbars";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

function InsightsModal({
  insightsPopup,
  toggle,
  selectedForInsights,
  setShowPopUp,
}) {
  const reducer: any = useSelector((state) => state);
  const {
    CampaignReducer: { campaignInsights },
  } = reducer;
  const [state, setState] = useState<any>({
    questions: [{ question: "", key: "", type: "" }],
    about: "",
    buttonDisable: false,
    disconnectedArray: [],
    errMsg: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isEmpty(campaignInsights)) {
      setState((prev) => ({
        ...prev,
        questions: [...campaignInsights?.questions],
        about: campaignInsights?.about,
      }));
    }
  }, [campaignInsights]);

  useEffect(() => {
    const init = async () => {
      await dispatch(getInsightsCampaignAction(selectedForInsights._id));
    };
    init();

    return () => {
      const init = async () => {
        await dispatch(
          getInsightsCampaignAction(selectedForInsights._id, true)
        );
      };
      init();
    };
  }, []);

  const { questions, about, buttonDisable, errMsg } = state;

  const addMoreColumn = (idx) => {
    if (
      questions[idx]?.question &&
      questions[idx]?.key &&
      questions[idx]?.type
    ) {
      let add = { question: "", key: "", type: "" };

      setState((prev) => ({
        ...prev,
        questions: [add, ...prev.questions],
      }));
    } else {
      swalWarningMessage("Please fill existing one first");
    }
  };

  const handleChange = (e, idx, val) => {
    let cloneData = state.questions;
    if (val === "key") {
      cloneData[idx][val] = e.target.value.split(" ").join("");
    } else {
      cloneData[idx][val] = e.target.value;
    }
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let err = 0;
    let required = ["question", "type", "key"];
    for (const item of questions) {
      required.forEach((o) => {
        if (!item[o]) {
          err++;
        }
      });
    }
    if (err) {
      swalErrorMessage("Please fill all the field first");
      return;
    } else {
      setState((prev) => ({ ...prev, buttonDisable: true }));
      let obj = {
        questions: [...questions],
        about: about,
        campaignId: selectedForInsights?._id,
      };
      let res: any = await dispatch(saveInsightsCampaignAction(obj));
      if (res) {
        setShowPopUp(true);
      }
    }

    setState((prev) => ({ ...prev, buttonDisable: false }));
    toggle();
  };
  const removeQuestion = (idx) => {
    let cloneData = state.questions;
    cloneData.splice(idx, 1);
    setState((prev) => ({
      ...prev,
      questions: cloneData,
    }));
  };

  return (
    <div>
      <CommonModal
        show={insightsPopup}
        toggle={toggle}
        heading={`Create Insights - ${selectedForInsights?.campaignName}`}
        body={
          <Box>
            <Box className="flex-between">
              <Typography className="font-14-grey" sx={{ flex: 1 }}>
                Question
              </Typography>
              <Typography className="font-14-grey" sx={{ flex: 1 }}>
                Name
              </Typography>
              <Typography className="font-14-grey" sx={{ flex: 1 }}>
                Type
              </Typography>
              <Typography
                className="font-14-grey"
                sx={{ flex: 0.15 }}
              ></Typography>
            </Box>
            <CustomScrollbars height="35vh">
              <Box className="flex-column" gap={2} my={1}>
                {state.questions.map((data, idx) => (
                  <Box key={idx}>
                    <Box className="flex-between-only" gap={2}>
                      <TextField
                        placeholder="Enter what you are looking from call"
                        value={data.question}
                        onChange={(e) => handleChange(e, idx, "question")}
                        sx={{ ...commonStyles.inputField, width: "100%" }}
                      />
                      <TextField
                        placeholder="Enter a name exact name"
                        value={data.key}
                        onChange={(e) => handleChange(e, idx, "key")}
                        sx={{ ...commonStyles.inputField, width: "100%" }}
                      />
                      <Select
                        displayEmpty
                        placeholder="Select Type"
                        value={data.type}
                        onChange={(e) => handleChange(e, idx, "type")}
                        sx={styles.typeSelect}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              overflowY: "auto",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">Please Choose</MenuItem>
                        <MenuItem value="string">Text</MenuItem>
                        <MenuItem value="integer">Number</MenuItem>
                        <MenuItem value="boolean">True/false</MenuItem>
                      </Select>

                      <IconButton
                        disabled={state.questions.length === 1}
                        onClick={() => removeQuestion(idx)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                    <span className="font-600-red">{errMsg}</span>

                    {idx === 0 && (
                      <Box className="flex-row" pt={1}>
                        <Button
                          onClick={() => addMoreColumn(idx)}
                          variant="outlined"
                          startIcon={
                            <AddIcon
                              style={{
                                color: "#666666",
                                padding: 1,
                                backgroundColor: "white",
                              }}
                            />
                          }
                          sx={styles.addBtn}
                        >
                          Add
                        </Button>
                      </Box>
                    )}
                  </Box>
                ))}
              </Box>
            </CustomScrollbars>
          </Box>
        }
        size={750}
        handleSave={handleSubmit}
        spin={buttonDisable || errMsg}
        disableSaveButton={errMsg}
      />
    </div>
  );
}

export default InsightsModal;

const styles = {
  addBtn: {
    textTransform: "none",
    color: "#656E7F",
    border: 0,
    backgroundColor: "#F1F4FA",
    fontSize: 12,
    fontWeight: 600,
    borderRadius: 2,
    padding: "4px 10px",
    "&:hover": {
      transition: "width 0.5s ease-in-out",
      border: 0,
    },
  },
  typeSelect: {
    width: "100%",
    fontSize: 14,
    fontWeight: 400,
    borderColor: "#D1D9EF",
    borderRadius: 1,
    color: "#9E9FA1",
    "& .MuiSelect-select": {
      padding: "10px 14px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
    },
  },
};
