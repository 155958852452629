import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { ReactComponent as GmailIcon } from "../../../assets/Images/GmailIcon.svg";
import { ReactComponent as SheetsIcon } from "../../../assets/Images/SheetsIcon.svg";
import { LoadingSlides, PAGE_SIZE_50 } from "../CommonComponents/Common";
import CommonModal from "../CommonComponents/Modal/CommonModal";
import {
  getProjectSheetsAction,
  getProjectSheetsDataAction,
} from "../NewProjects/Store/projects.action";
import Sheets from "./Sheets";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import BaseButton from "../Common/Buttons/BaseButton";

type SheetType = { id: string; name: string; mimetype?: string };
type SubSheetType = { id?: string; name: string };

type StateType = {
  sheets: SheetType[];
  subSheets: SubSheetType[];
  selectedSheet: SheetType | null;
  selectedSubSheet: SubSheetType | null;
  loading: boolean;
  sheetsModalLoading: boolean;
  selected: {
    sheet: string;
    subSheet: string;
  };
};

const Integrations = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const history = useHistory();
  const reducer: any = useSelector((state) => state);
  const {
    ProjectReducer: { selectedProject },
  } = reducer;

  const gmailAuth = selectedProject?.gmailAuth;
  const sheetAuth = selectedProject?.sheetAuth;

  const selectedSpreadSheet = selectedProject?.triggerPoint;

  const [state, setState] = useState<StateType>({
    sheets: [],
    subSheets: [],
    selectedSheet: null,
    selectedSubSheet: null,
    loading: false,
    sheetsModalLoading: false,
    selected: {
      sheet: "",
      subSheet: "",
    },
  });
  const [show, setShow] = useState(false);
  const [showSheetsModal, setShowSheetsModal] = useState(false);
  const [sheetsData, setSheetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [downloadexcelSpin, setdownloadexcelSpin] = useState(false);
  const [filters, setFilters] = useState<
    { columnName: string; operator: string; value: string }[]
  >([{ columnName: "", operator: "contains", value: "" }]);

  const {
    sheets,
    subSheets,
    selectedSheet,
    selectedSubSheet,
    loading,
    sheetsModalLoading,
    selected,
  } = state;

  useEffect(() => {
    if (selectedSpreadSheet?.spreadSheetId) {
      setState((prev) => ({
        ...prev,
        selectedSheet: {
          id: selectedSpreadSheet?.spreadSheetId,
          name: selectedSpreadSheet?.spreadSheetName,
        },
        selectedSubSheet: {
          name: selectedSpreadSheet?.sheetName,
        },
        selected: {
          sheet: selectedSpreadSheet?.spreadSheetName,
          subSheet: selectedSpreadSheet?.sheetName,
        },
      }));
    }
  }, [selectedSpreadSheet]);

  useEffect(() => {
    (async () => {
      const sheets = await fetchData("");
      setState((prev) => ({
        ...prev,
        sheets,
        subSheets: [],
        selectedSheet: sheets.length > 0 ? prev.selectedSheet : null,
        selectedSubSheet: sheets.length > 0 ? prev.selectedSubSheet : null,
      }));
    })();
  }, []);

  useEffect(() => {
    if (!selectedSheet) return;
    (async () => {
      const subSheets = await fetchData("subSpreadsheet", {
        spreadSheetId: selectedSheet?.id,
      });
      setState((prev) => ({ ...prev, subSheets }));
    })();
  }, [selectedSheet]);

  const fetchData = async (type: string, params: any = {}) => {
    setState((prev) => ({ ...prev, loading: true }));
    try {
      const data = { projectId, type, ...params };
      const response = (await getProjectSheetsAction(data)) || [];
      return response;
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const fetchSheetsData = async (appliedFilters = filters) => {
    setState((prev) => ({ ...prev, sheetsModalLoading: true }));

    const paramsdata = {
      pageSize: PAGE_SIZE_50,
      pageIndex: currentPage,
    };
    const res: any = await getProjectSheetsDataAction(
      paramsdata,
      projectId,
      appliedFilters
    );
    const allSheets = res?.map((row) => row.map((cell) => ({ value: cell })));
    setSheetsData(allSheets);
    setState((prev) => ({ ...prev, sheetsModalLoading: false }));
  };
  const fetchSheetsData1 = async (filters) => {
    const paramsdata = {
      pageSize: PAGE_SIZE_50,
      pageIndex: 1,
    };
    const res: any = await getProjectSheetsDataAction(
      paramsdata,
      projectId,
      filters
    );
    const allSheets = res?.map((row) => row.map((cell) => ({ value: cell })));
    setSheetsData(allSheets);
  };

  const handleSaveSheet = async () => {
    if (!selectedSheet || !selectedSubSheet) {
      const message = !selectedSheet
        ? "Please select the Sheets"
        : "Please select the SubSheet";

      swalWarningMessage(message);
      return;
    }

    setState((prev) => ({ ...prev, loading: true }));

    await fetchData("saveTriggerPoint", {
      spreadSheetId: selectedSheet?.id,
      spreadSheetName: selectedSheet?.name,
      sheetName: selectedSubSheet?.name,
      active: true,
    });
    fetchSheetsData();
    setState((prev) => ({
      ...prev,
      loading: false,
      selected: {
        sheet: selectedSheet?.name,
        subSheet: selectedSubSheet?.name,
      },
    }));
    setShow(false);
    setCurrentPage(1);
  };

  const downloadSampleExcel = async () => {
    setdownloadexcelSpin(true);

    const keys: any = [];

    const obj1 = {
      FirstName: "",
      LastName: "",
      Address: "",
      "Zip code": "",
      Age: "",
      SSN: "",
      "Email id": "",
      PhoneNum: "",
      "Type of loan": "",
      "Credit Rating": "",
      "Loan disbursal date (dueDate)": "",
      "Amount due": "",
      "Payment due date": "",
      "Interest/Penal charges": "",
      "Risk category of the customer (Extremely low risk, medium risk, high risk, extremely high risk)":
        "",
      "Settlement option": "",
      "Minimum amount due": "",
      "Minimum amount due with penalty": "",
      status: "",
      dueDate: "",
    };
    keys.push(obj1);

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(keys);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `sample sheet` + fileExtension);
    setdownloadexcelSpin(false);
  };

  return (
    <Box p={1}>
      <Typography className="font-20-grey">Integration</Typography>
      <Grid mt={0.5} container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Box
            className="flex-xy-center"
            bgcolor={gmailAuth ? "rgba(36, 148, 34, 0.65)" : "white"}
            py={1}
            sx={{
              height: 30,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {gmailAuth && gmailAuth?.length > 0 && (
              <Typography fontSize={"12px"} color={"#FFFFFF"} fontWeight={500}>
                Connected: {gmailAuth}
              </Typography>
            )}
          </Box>
          <Box sx={styles.container}>
            <Box
              component={"a"}
              href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly%20https%3A%2F%2Fmail.google.com%2F%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&prompt=consent&response_type=code&client_id=808909189797-sm3gandff008bpn4tc5rb74lqo05u3a3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.vodex.ai%2Fredirect-to-vodex%3FauthType%3Dgmail"
              className="flex-xy-center"
              p={3}
            >
              <GmailIcon style={{ width: "40px", height: "40px" }} />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box
            className="flex-xy-center"
            bgcolor={sheetAuth ? "rgba(36, 148, 34, 0.65)" : "white"}
            py={1}
            sx={{
              height: 30,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            {sheetAuth && sheetAuth?.length > 0 && (
              <Typography fontSize={"12px"} color={"#FFFFFF"} fontWeight={500}>
                Connected: {sheetAuth}
              </Typography>
            )}
          </Box>
          <Box sx={{ ...styles.container, position: "relative" }}>
            <Box
              component={"a"}
              href="https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.readonly%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fspreadsheets%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive.file&prompt=consent&response_type=code&client_id=808909189797-sm3gandff008bpn4tc5rb74lqo05u3a3.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fapp.vodex.ai%2Fredirect-to-vodex%3FauthType%3Dsheets"
              className="flex-xy-center"
              p={3}
            >
              <SheetsIcon style={{ width: "40px", height: "40px" }} />
            </Box>
            {sheetAuth && (
              <Box
                p={1}
                position={"absolute"}
                bottom={2}
                left={2}
                sx={{
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
                width={"100%"}
              >
                <Box display="flex" justifyContent="space-between" gap={1}>
                  <Box display="flex" gap={1}>
                    {selectedSpreadSheet?.sheetName &&
                      selectedSpreadSheet?.spreadSheetName && (
                        <Button
                          variant="outlined"
                          onClick={() => setShowSheetsModal(true)}
                          sx={styles.button}
                        >
                          View
                        </Button>
                      )}

                    <Button
                      variant="outlined"
                      onClick={() => setShow(true)}
                      sx={styles.button}
                    >
                      Connect
                    </Button>
                  </Box>
                  <Button
                    variant="outlined"
                    onClick={downloadSampleExcel}
                    sx={styles.button}
                  >
                    {downloadexcelSpin ? (
                      <LoadingSlides bgColor="#181818" />
                    ) : (
                      "Download sample file"
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Box
            className="flex-xy-center"
            sx={{ ...styles.container, height: "140px" }}
          >
            <BaseButton
              title="View DB"
              handleClick={() => history.push(`leads`)}
            />
          </Box>
        </Grid>
      </Grid>

      {showSheetsModal && (
        <CommonModal
          show={showSheetsModal}
          heading={`${selected?.sheet || selectedSpreadSheet?.sheetName} - ${
            selected?.subSheet || selectedSpreadSheet?.spreadSheetName
          }`}
          toggle={() => setShowSheetsModal(false)}
          body={
            <Sheets
              sheetsData={sheetsData}
              currentPage={currentPage}
              handlePrevious={() => setCurrentPage(currentPage - 1)}
              handleNext={() => setCurrentPage(currentPage + 1)}
              filters={filters}
              setFilters={setFilters}
              fetchSheetsData={fetchSheetsData}
              fetchSheetsData1={fetchSheetsData1}
              sheetsModalLoading={sheetsModalLoading}
            />
          }
          footer={false}
          handleSave={() => {}}
          size={1200}
        />
      )}

      {show && (
        <CommonModal
          show={show}
          heading={"Connect Sheet"}
          handleSave={handleSaveSheet}
          saveButtonText="Save Sheet"
          toggle={() => setShow(false)}
          spin={loading}
          body={
            <SheetBody
              setState={setState}
              selectedSheet={selectedSheet}
              selectedSubSheet={selectedSubSheet}
              sheets={sheets}
              subSheets={subSheets}
            />
          }
        />
      )}
    </Box>
  );
};

export default Integrations;

const SheetBody = ({
  setState,
  selectedSheet,
  sheets,
  selectedSubSheet,
  subSheets,
}) => {
  return (
    <Box>
      <Box>
        <Typography className="mb-2 font-14-grey">Select Sheet</Typography>
        <Select
          maxMenuHeight={160}
          className="basic-single"
          value={selectedSheet}
          isSearchable
          options={sheets}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          onChange={(selected) =>
            setState((prev) => ({
              ...prev,
              selectedSheet: selected,
              selectedSubSheet: null,
            }))
          }
          placeholder="Select Sheet"
          styles={styles.select}
        />
      </Box>

      <Box mt={2}>
        <Typography className="mb-2 font-14-grey">Select Subsheet</Typography>
        <Select
          maxMenuHeight={160}
          className="basic-single "
          value={selectedSubSheet}
          isSearchable
          options={subSheets}
          getOptionLabel={(e: any) => e?.name}
          getOptionValue={(e: any) => e?.id}
          onChange={(selected) =>
            setState((prev) => ({
              ...prev,
              selectedSubSheet: selected,
            }))
          }
          placeholder="Select Sub Sheet"
          styles={styles.select}
        />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    height: "110px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    border: "1px solid rgba(241, 244, 250, 1)",
    borderRadius: "8px",
    transition: "border 0.3s ease, box-shadow 0.3s ease",
    "&:hover": {
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
    },
  },
  button: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    textWrap: "nowrap",
    boxShadow: "none",
    paddingX: 1.5,
    paddingY: "3px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B0B0B0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
      boxShadow: "none",
      transition: "width 0.5s ease-in-out",
      border: "1px solid #B0B0B0",
    },
    "&.Mui-disabled": {
      color: "white",
      backgroundColor: "transparent",
    },
  },
  select: {
    control: (provided) => ({
      ...provided,
      border: "1px solid #CCD2DE",
      padding: "2px",
      borderRadius: 8,
      boxShadow: "none",
      fontSize: 13,
      "&:hover": {
        borderColor: "#CCD2DE",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#555555",
      fontSize: 13,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#333333",
      fontSize: 13,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      border: "1px solid #CCD2DE",
      padding: "5px",
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 2,
      zIndex: 99999,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#333333" : "#555555",
      backgroundColor: state.isSelected ? "#DDE8FF" : "#FFFFFF",
      fontSize: 13,
      borderRadius: 2,
      padding: "10px 12px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#DDE8FF" : "#F2F2F2",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  },
};
