import { Box, TextField } from "@mui/material";
import React from "react";
import { commonStyles } from "../../../../../assets/MUIStyles/commonStyles";

const DurationFilter = ({
  durationFilter,
  mainState,
  arraMapped,
  allCallStatus,
  outComefilter,
  onChange,
  setMainState,
}) => {
  const onCustomDurationChange = (e) => {
    const { name, value } = e.target;
    if (value && Number(value) <= 0) return;

    setMainState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Box className="mt-4">
      <span className="font-12-grey">Duration</span>
      <Box className="mt-2 duration-filter-container">
        {arraMapped.map((arr, index) => (
          <Box key={index} sx={{ paddingTop: "4px" }}>
            <Box className="flex-evenly">
              {arr.innerMap?.map((innerArr, i) => {
                const dur = arr?.title === "Filter Calls with status";
                const isSelected = arr?.isObject
                  ? outComefilter.find((o) => o === innerArr?.tag)
                  : mainState[arr?.valueAssigned] === innerArr ||
                    mainState[arr?.valueAssigned].includes(innerArr);

                return (
                  <Box key={i}>
                    <Box
                      onClick={() => onChange(arr?.valueAssigned, innerArr)}
                      className="flex-xy-center cusrsor-pointer"
                      sx={{
                        width: "70px",
                        height: "32px",
                        background: isSelected ? "#FFFFFF" : "#F1F4FA",
                        borderRadius: "100px",
                      }}
                    >
                      <span className="font-12-lightgrey">
                        {!arr?.isObject &&
                          (dur ? allCallStatus[innerArr] : innerArr)}
                        {arr?.isObject && innerArr?.name}
                      </span>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
      </Box>
      <Box>
        {durationFilter === "Custom" && (
          <Box>
            <span className="font-12-lightgrey mt-2">Custom Duration</span>
            <Box className="flex-between" gap={1}>
              <TextField
                placeholder="from (in sec.)"
                name="min"
                inputProps={{ min: 1 }}
                type="number"
                sx={commonStyles.inputField}
                value={mainState?.min}
                onChange={onCustomDurationChange}
              />
              <TextField
                placeholder="to (in sec.)"
                name="max"
                inputProps={{ min: 1 }}
                type="number"
                sx={commonStyles.inputField}
                value={mainState?.max}
                onChange={onCustomDurationChange}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box>
        <Box
          onClick={() => {
            setMainState((prev) => ({
              ...prev,
              durationFilter: "",
            }));
          }}
          component={"span"}
          className="flex-row cursor-pointer font-12-lightgrey ml-1 mt-1"
          sx={{
            "&:hover": { color: "#181818" },
          }}
        >
          Clear Duration Filter
        </Box>
      </Box>
    </Box>
  );
};

export default DurationFilter;
