import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const shortenString = (text, max, url = false) => {
  if (text && text.length > max) {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 800 }}
        placement="bottom"
        overlay={
          <Tooltip
            style={{ zIndex: 9999 }}
            className="text-tooltip"
            id={`tooltip_${Date.now()}`}
          >
            {text}
          </Tooltip>
        }
      >
        {url ? (
          <a href={`${text}`} target="_blank">{`${text.slice(0, max)}...`}</a>
        ) : (
          <span>{`${text.slice(0, max)}...`}</span>
        )}
      </OverlayTrigger>
    );
  }
  return text;
};
