import React, { useState, useEffect } from "react";
import { Node, ScheduleConfig, DueDateCondition } from "../types/workflow";
import { Zap, Trash2, Calendar } from "lucide-react";
import * as Icons from "lucide-react";
import { availableAgents } from "../data/agents";
import ScheduleConfigComponent from "./ScheduleConfig";
import { useSelector } from "react-redux";
import { Box, TextField, Typography } from "@mui/material";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import Select from "react-select";
import CustomScrollbars from "../../CommonComponents/CustomScrollbars";
import { customSelectStyles } from "../../../../assets/MUIStyles/multiAgent";
import { timezoneAliases } from "../../CommonComponents/Common";
import moment from "moment";

const DUE_DATE_OPTIONS = [
  { type: "before", label: "Days Before Due Date" },
  { type: "on", label: "On the Due Date" },
  { type: "after", label: "Days After Due Date" },
];

const EVENT_TYPES = [
  { value: "due_date", label: "Due Date" },
  { value: "status_change", label: "Status Change" },
  { value: "assignment", label: "Assignment" },
  { value: "comment", label: "Comment" },
];

const TRIGGER_TYPE_OPTIONS: TriggerOption[] = [
  { value: "webhook", label: "Webhook" },
  { value: "event", label: "Event" },
];

interface TriggerOption {
  value: "webhook" | "event";
  label: string;
}

interface WorkflowNodeProps {
  node: Node;
  onDragStart: (e: React.DragEvent) => void;
  onDragEnd: () => void;
  onStartConnection: () => void;
  onCompleteConnection: () => void;
  isConnecting: boolean;
  onUpdateNode: (nodeId: string, data: Partial<Node["data"]>) => void;
  onDeleteNode: (nodeId: string) => void;
}
const timeZone = moment.tz.guess();
const normalizedTimeZone =
  timezoneAliases[timeZone] || moment.tz.zone(timeZone)?.name || timeZone;

const defaultSchedule: ScheduleConfig = {
  frequency: "daily",
  time: "09:00",
  timezone: normalizedTimeZone,
};

const WorkflowNode: React.FC<WorkflowNodeProps> = ({
  node,
  onDragStart,
  onDragEnd,
  onStartConnection,
  onCompleteConnection,
  isConnecting,
  onUpdateNode,
  onDeleteNode,
}) => {
  const reducer: any = useSelector((state) => state);
  const {
    AgentReducer: { agents },
  } = reducer;

  const [showConfig, setShowConfig] = useState(false);
  const [currentTriggerType, setCurrentTriggerType] = useState(
    node.data.triggerType || "webhook"
  );
  const [currentEventType, setCurrentEventType] = useState<
    Node["data"]["eventType"]
  >(node.data.eventType);
  const [allAgents, setAllAgents] = useState([]) as any;
  const [dueDateCondition, setDueDateCondition] = useState<DueDateCondition>({
    type: "on",
  });

  useEffect(() => {
    setAllAgents(node?.data?.agents);

    if (!node.data.schedule) {
      handleScheduleChange(defaultSchedule);
    }
  }, []);

  // Initialize due date condition when event type changes to 'due_date'
  useEffect(() => {
    if (currentEventType === "due_date") {
      if (node.data.dueDateConditions?.[0]) {
        setDueDateCondition(node.data.dueDateConditions[0]);
      } else {
        setDueDateCondition({ type: "on" });
      }
    }
  }, [currentEventType, node.data.dueDateConditions]);

  const filterAgentsByType = (nodeData) => {
    const selectedType = nodeData.data.agentType;

    if (selectedType === "call") {
      return agents?.filter(
        (agent: any) => !agent.agentType || agent.agentType === "call"
      );
    }
    return agents?.filter((agent: any) => agent.agentType === selectedType);
  };
  const getIcon = () => {
    if (node.type === "trigger") {
      switch (node.data.triggerType) {
        case "webhook":
          return <Zap size={14} color="#555555" />;
        case "event":
          return <Calendar size={14} color="#555555" />;
        default:
          return <Zap size={14} color="#555555" />;
      }
    } else if (node.data.agentType) {
      const agent = availableAgents.find((a) => a.id === node.data.agentType);
      if (agent) {
        const IconComponent = Icons[
          agent.icon as keyof typeof Icons
        ] as React.FC<{ size?: number; className?: string }>;
        return IconComponent ? (
          <IconComponent size={14} className="icon-component" />
        ) : (
          <Zap size={14} color="#555555" />
        );
      }
    }
    return <Zap size={14} color="#555555" />;
  };

  const nodeStyle: React.CSSProperties = {
    position: "absolute",
    left: node.position.x,
    top: node.position.y,
    cursor: "move",
    userSelect: "none",
  };

  const handleConfigSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    const updates: Partial<Node["data"]> = {
      name: formData.get("name") as string,
      description: formData.get("description") as string,
    };
    if (node.type === "agent") {
      updates.agents = allAgents;
    }

    if (node.type === "trigger") {
      updates.triggerType = currentTriggerType;
      updates.eventType = currentEventType;

      updates.webhook = undefined;
      updates.event = undefined;
      updates.dueDateConditions = undefined;

      switch (currentTriggerType) {
        case "webhook":
          updates.webhook = formData.get("webhook") as string;
          break;
        case "event":
          if (currentEventType === "due_date") {
            updates.dueDateConditions = [dueDateCondition];
          }
          break;
      }
    }

    onUpdateNode(node.id, updates);
    setShowConfig(false);
  };

  const handleScheduleChange = (schedule: ScheduleConfig) => {
    onUpdateNode(node.id, { schedule });
  };

  const handleTriggerTypeChange = (type: Node["data"]["triggerType"]) => {
    let type1: any = type;
    setCurrentTriggerType(type1);
    setCurrentEventType(undefined);
    setDueDateCondition({ type: "on" });
  };

  const handleEventTypeChange = (type: Node["data"]["eventType"]) => {
    setCurrentEventType(type);
    setDueDateCondition({ type: "on" });
  };

  const handleAgentChange = (agent) => {
    setAllAgents([agent?._id]);
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDeleteNode(node.id);
  };

  return (
    <>
      <div
        style={nodeStyle}
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        className={`node-container ${isConnecting ? "connecting" : ""}`}
      >
        <div
          className="node-header cursor-pointer relative group"
          onClick={() => setShowConfig(true)}
        >
          <div className="header-container mb-4">
            <div className="icon-container">
              {getIcon()}
              <span className="node-name">{node.data.name}</span>
            </div>
            <button onClick={handleDelete} className="" title="Delete node">
              <Trash2 size={14} color="#C4330F" />
            </button>
          </div>
        </div>

        <div
          className="node-footer"
          style={{
            justifyContent: node.type === "agent" ? "space-between" : "center",
          }}
        >
          {node.type === "agent" && (
            <button onClick={onCompleteConnection} className="input-button">
              Input
            </button>
          )}
          <button onClick={onStartConnection} className="connect-button">
            Connect
          </button>
        </div>

        {showConfig && (
          <CommonModal
            show={showConfig}
            toggle={() => setShowConfig(false)}
            heading={<>Configure {node.type}</>}
            body={
              <form onSubmit={handleConfigSubmit} className="p-1">
                <CustomScrollbars
                  height={node.type === "trigger" ? "60vh" : "300px"}
                >
                  <Box mb={2}>
                    <Typography {...styles.label}>Name</Typography>
                    <TextField
                      type="text"
                      name="name"
                      defaultValue={node.data.name}
                      variant="outlined"
                      fullWidth
                      sx={styles.textInput}
                    />
                  </Box>

                  <Box mb={2}>
                    <Typography {...styles.label}>Description</Typography>
                    <TextField
                      type="text"
                      name="description"
                      defaultValue={node.data.description}
                      variant="outlined"
                      fullWidth
                      sx={styles.textInput}
                    />
                  </Box>

                  {node.type === "agent" && (
                    <Box mb={2}>
                      <Typography {...styles.label}>Select Agent</Typography>

                      <Select
                        name="agent"
                        value={
                          filterAgentsByType(node)?.find(
                            (option) => option?._id === allAgents?.[0]
                          ) || null
                        }
                        onChange={handleAgentChange}
                        options={filterAgentsByType(node)}
                        getOptionLabel={(e: any) => e?.agentName}
                        getOptionValue={(e: any) => e?._id}
                        placeholder="Select Agent"
                        styles={customSelectStyles}
                      />
                    </Box>
                  )}

                  {node.type === "trigger" && (
                    <>
                      <Box mb={2}>
                        <Typography {...styles.label}>Trigger Type</Typography>

                        <Select
                          name="triggerType"
                          value={
                            TRIGGER_TYPE_OPTIONS.find(
                              (option) => option.value === currentTriggerType
                            ) || null
                          }
                          onChange={(selectedOption) =>
                            handleTriggerTypeChange(selectedOption?.value)
                          }
                          options={TRIGGER_TYPE_OPTIONS}
                          getOptionLabel={(e: any) => e?.label}
                          getOptionValue={(e: any) => e?.value}
                          placeholder="Select Trigger Type"
                          styles={customSelectStyles}
                        />
                      </Box>

                      {currentTriggerType === "webhook" && (
                        <Box mb={2}>
                          <Typography {...styles.label}>Webhook URL</Typography>
                          <TextField
                            type="text"
                            name="webhook"
                            defaultValue={node.data.webhook || ""}
                            variant="outlined"
                            fullWidth
                            sx={styles.textInput}
                            placeholder="Enter webhook url"
                          />
                        </Box>
                      )}

                      {currentTriggerType === "event" && (
                        <>
                          <Box mb={2}>
                            <Typography {...styles.label}>
                              Event Type
                            </Typography>

                            <Select
                              name="triggerType"
                              value={
                                EVENT_TYPES.find(
                                  (option) => option.value === currentEventType
                                ) || null
                              }
                              onChange={(selectedOption: any) =>
                                handleEventTypeChange(selectedOption?.value)
                              }
                              options={EVENT_TYPES}
                              getOptionLabel={(e: any) => e?.label}
                              getOptionValue={(e: any) => e?.value}
                              placeholder="Select Event Type"
                              styles={customSelectStyles}
                            />
                          </Box>

                          {currentEventType === "due_date" && (
                            <Box className="flex items-center space-x-2 bg-gray-700/50 rounded-lg">
                              <Box mb={2}>
                                <Typography {...styles.label}>
                                  Due Date Condition
                                </Typography>

                                <Select
                                  name="triggerType"
                                  value={
                                    DUE_DATE_OPTIONS.find(
                                      (option) =>
                                        option.type === dueDateCondition?.type
                                    ) || null
                                  }
                                  onChange={(selectedOption: any) =>
                                    setDueDateCondition(selectedOption)
                                  }
                                  options={DUE_DATE_OPTIONS}
                                  getOptionLabel={(e: any) => e?.label}
                                  getOptionValue={(e: any) => e?.type}
                                  placeholder="Select Due Date"
                                  styles={customSelectStyles}
                                />
                              </Box>

                              {(dueDateCondition.type === "before" ||
                                dueDateCondition.type === "after") && (
                                <TextField
                                  type="number"
                                  placeholder="Days"
                                  value={dueDateCondition.days || 1}
                                  onChange={(e) =>
                                    setDueDateCondition({
                                      ...dueDateCondition,
                                      days: parseInt(e.target.value),
                                    })
                                  }
                                  variant="outlined"
                                  fullWidth
                                  sx={styles.textInput}
                                  inputProps={{ min: 1 }}
                                />
                              )}
                            </Box>
                          )}
                        </>
                      )}

                      <ScheduleConfigComponent
                        value={node.data.schedule || defaultSchedule}
                        onChange={handleScheduleChange}
                      />
                    </>
                  )}
                </CustomScrollbars>
                <Box my={2} display="flex" justifyContent="flex-end">
                  <button type="submit" className="submit-button">
                    Save Changes
                  </button>
                </Box>
              </form>
            }
            size={550}
            footer={false}
            handleSave={() => {}}
          />
        )}
      </div>
    </>
  );
};

export default WorkflowNode;

const styles = {
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 0.5,
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
      borderColor: "#CCD2DE",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-input": {
      paddingX: 1.5,
      paddingY: 1,
      "&::placeholder": {
        color: "#8792A7",
        fontSize: 12,
        fontWeight: 400,
      },
    },
  },
};
