import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { verifyEmailAction } from "../Actions/CommonActions";
import BaseButton from "../Common/Buttons/BaseButton";
import { isVodexLoggedin } from "../CommonComponents/Common";
import AccountVerification from "../../../assets/Images/Accountverification.png";

const AccountVerfication = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const token = locSearch.get("token");
  const reducer = useSelector((reducer: any) => reducer);
  const {
    AccountReducer: { publicKey },
  } = reducer;

  const [spin, setSpin] = useState(true);
  useEffect(() => {
    const init = async () => {
      if (!token) {
        history.push("/");
      }
      const currency = "usd";
      await dispatch(verifyEmailAction({ token, currency }));
      setSpin(false);
    };
    if (publicKey) init();
  }, [publicKey]);

  return (
    <Box
      className={`flex-xy-center ${
        !isVodexLoggedin() && "whitelabel-background"
      }`}
      minHeight="100vh"
    >
      <Box className="flex-column-align-center">
        <Typography
          color={` ${isVodexLoggedin() ? "#1A1D23" : "#fff"}`}
          fontSize={28}
          fontWeight={700}
          position="absolute"
        >
          Verified Successfully
        </Typography>
        <img src={AccountVerification} alt="Account Verification" />
        <BaseButton
          variant="contained"
          title={`Continue ${isVodexLoggedin() ? "to Vodex" : ""}`}
          handleClick={() => history.push("/")}
          disabled={spin}
          styles={{
            backgroundColor: "#FF5E00",
            py: 1.5,
            width: "70%",
          }}
        />
      </Box>
    </Box>
  );
};

export default AccountVerfication;
