import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import { LoadingSlides } from "../CommonComponents/Common";
import OnboardingContainer from "./Container";
import "react-phone-input-2/lib/style.css";
import { styles } from "../../../assets/MUIStyles/onboarding";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

const CallSlide = ({ obj }) => {
  const { setMainState, mainState, callTrigger, onChange, spin, previous } =
    obj;
  const { firstName, phone } = mainState;

  const handleKeyPress1 = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      callTrigger();
    }
  };

  return (
    <OnboardingContainer>
      <Box mx={2}>
        <Typography className="font-18-darkGrey" textAlign={"left"}>
          Launch a trial call
        </Typography>
        <Typography className="font-14-darkGrey" mt={1} textAlign={"left"}>
          {" "}
          First test call with your new AI tool- who’s the lucky one ?
        </Typography>

        <form className="mt-4" onChange={onChange}>
          <Box bgcolor={"#FFFFFF"} className="input-container">
            {!firstName && (
              <span className="custom-placeholder">
                <strong>Who should the bot call example*</strong> {`"John Doe"`}
              </span>
            )}
            <input
              name="firstName"
              className="common-ip"
              value={firstName}
              onKeyDown={handleKeyPress1}
              maxLength={25}
              id="firstName"
            />
          </Box>

          <Box mt={2}>
            <PhoneInput
              specialLabel={""}
              country={"us"}
              onChange={(e) => {
                setMainState((prev) => ({
                  ...prev,
                  phone: e,
                }));
              }}
              value={phone}
              enableLongNumbers={true}
              onKeyDown={handleKeyPress1}
              inputStyle={styles.phoneInput}
              containerStyle={styles.phoneContainer}
              placeholder="What number should the bot call*"
            />
          </Box>
        </form>
        <Box mt={2} mx={{ xs: 0, sm: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{ ...commonStyles.clearButton, width: "100%" }}
                onClick={previous}
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                sx={{ ...commonStyles.saveButton, width: "100%" }}
                onClick={callTrigger}
              >
                {spin ? (
                  <LoadingSlides bgColor="white" />
                ) : (
                  " Make a Sample Call"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </OnboardingContainer>
  );
};

export default CallSlide;
