import { Box, Button } from "@mui/material";
import React from "react";
import { LoadingSlides } from "../../../CommonComponents/Common";
import { commonStyles } from "../../../../../assets/MUIStyles/commonStyles";
const FilterFooter = ({
  loading,
  clearFilter,
  applyFilter,
  setMainState,
  mainState,
}) => {
  const { durationFilter, statusFilter, outComefilter } = mainState;
  return (
    <Box maxHeight="70px">
      <hr style={{ marginBottom: "3px", marginTop: "18px" }} />

      <Box>
        <Box py={0.5} className="flex-end">
          <Button
            sx={commonStyles.clearButton}
            className="mr-2"
            onClick={() => {
              clearFilter("all", true);
              setMainState((prev) => ({ ...prev, showCreateButton: false }));
              localStorage.removeItem("showCreateButton");
            }}
          >
            Cancel
          </Button>

          <Button
            sx={commonStyles.saveButton}
            disabled={
              loading ||
              (!durationFilter &&
                outComefilter?.length === 0 &&
                statusFilter?.length === 0)
            }
            onClick={() => applyFilter("apply")}
          >
            {loading ? <LoadingSlides bgColor="white" /> : "Apply filter"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FilterFooter;
