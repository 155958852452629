import React, { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import OnboardingHeader from "./OnboardingHeader";
import { useHistory, useLocation } from "react-router-dom";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import { Carousel, CarouselItem } from "reactstrap";
import { checkRolesAccess } from "../CommonComponents/Common";
import {
  callAgentAction,
  handleOnbiardingChatAgentAction,
  saveAgentAction,
} from "../ProjectSection/Agents/Store/Agent.action";
import { useDispatch, useSelector } from "react-redux";
import { saveProjectsAction } from "../Projects/Store/projects.action";
import {
  getUerAccountsDetailsAction,
  lastLoginTrackAction,
  saveFeedbackAction,
} from "../Actions/CommonActions";
import ChatSlide from "./ChatSlide";
import AgentSlide from "./AgentSlide";
import ProjectNameSlide from "./ProjectNameSlide";
import CallSlide from "./CallSlide";
import { sendSmsToSupportAction } from "../AccessManger/Store/access.action";
import CallPage from "../../../assets/Images/RecievedCallPage.svg";
import NoResponse from "../../../assets/Images/NoResponse.svg";
import SupportImg from "../../../assets/Images/SupportImg.svg";
import "../LoginRegister/Login.css";
import { axiosClient } from "../../../Constant/apiConstant";
import { Modal } from "react-bootstrap";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";

interface CurrentProjectData {
  currentProjectId: String;
  currentProjectName: String;
}

const items = [
  {
    id: 0,
    altText: "Slide 1",
    caption: "Slide 1",
  },
  {
    id: 1,
    altText: "Slide 2",
    caption: "Slide 2",
  },
  {
    id: 2,
    altText: "Slide 3",
    caption: "Slide 3",
  },
  {
    id: 3,
    altText: "Slide 3",
    caption: "Slide 3",
  },
];

const stars = [1, 2, 3, 4, 5];

const starObj = {
  "star-5": "star-1",
  "star-4": "star-2",
  "star-3": "star-3",
  "star-2": "star-4",
  "star-1": "star-5",
};

const Onboarding = () => {
  const currentDataRef = useRef<CurrentProjectData | null>(null);
  const [projectId, setProjectId] = useState("");
  const messagesContainerRef: any = useRef(null);
  const messagesContainerRef1: any = useRef(null);
  const [messages, setMessages] = useState<any>([]);
  const [history, setHistory] = useState<any>([]);
  const [inputText, setInputText] = useState("");
  const historyPush = useHistory();
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  let from = locSearch.get("from");
  const dispatch = useDispatch();

  const [state, setState] = useState<any>({
    seletedType: "",
    conversationData: [{ input: "Hi", output: "" }],
    agentName: "",
    organisationName: "",
    greetingMessage: "",
    context: "",
    rules: "",
    objections: [],
    voicemailMessage: "",
    projectName: "",
    firstName: "",
    phone: "",
    agentId: "",
    call_uuid: "",
    feedbackText: "",
    starsFeedback: 0,
    feedBackSaved: "",
    chatId: "",
  });

  const {
    agentName,
    organisationName,
    greetingMessage,
    context,
    rules,
    projectName,
    phone,
    firstName,
    agentId,
    call_uuid,
    feedbackText,
    starsFeedback,
    feedBackSaved,
    chatId,
  } = state;

  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [spin, setSpin] = useState(false);
  const [firstShow, setFirstShow] = useState(false);
  const [feedbackShow, setFeedbackShow] = useState(false);
  const [noResponseShow, setNoResponseShow] = useState(false);
  const [supportShow, setSupportShow] = useState(false);
  const [textSpin, setTextSpin] = useState(false);
  const [spinButton, setSpinButton] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (messagesContainerRef.current) {
      const { scrollHeight, clientHeight } =
        messagesContainerRef.current.getValues();
      const maxScrollTop = scrollHeight - clientHeight;
      messagesContainerRef.current.scrollTop(maxScrollTop);
    }
    if (messagesContainerRef1.current) {
      const { scrollHeight, clientHeight } =
        messagesContainerRef1.current.getValues();
      const maxScrollTop = scrollHeight - clientHeight;
      messagesContainerRef1.current.scrollTop(maxScrollTop);
    }
  }, [messages]);

  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { userDetails, selectedAccount, publicKey },
  } = reducer;

  const {
    AccountReducer: { accountRoles },
  } = reducer;

  useEffect(() => {
    if (publicKey) dispatch(getUerAccountsDetailsAction());
  }, [publicKey]);

  useEffect(() => {
    const init = async () => {
      axiosClient.defaults.headers.common[
        "dbUrl"
      ] = `${selectedAccount?.accountUrl}`;
      if (selectedAccount && userDetails) {
        let lastLoginObj = {
          accountUrl: selectedAccount?.accountUrl,
          userId: userDetails?._id,
          accountId: selectedAccount?._id,
        };
        await dispatch(lastLoginTrackAction(lastLoginObj));
      }
    };
    if (publicKey) init();
  }, [publicKey, selectedAccount]);

  useEffect(() => {
    let kl: any = currentDataRef?.current?.currentProjectId || "";
    setProjectId(kl);
  }, [currentDataRef.current?.currentProjectId]);

  useEffect(() => {
    const acc = checkRolesAccess(accountRoles, "projectPage");
    if (acc === false) {
      historyPush.push("/account/projects");
    }
  }, [accountRoles]);

  useEffect(() => {
    const currentProjectData = localStorage.getItem("currentSlideData");

    if (currentProjectData) {
      currentDataRef.current = JSON.parse(currentProjectData);
    } else {
      currentDataRef.current = null;
    }

    if (currentDataRef.current && currentDataRef.current.currentProjectId) {
      setActiveIndex(1);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (activeIndex === 0) {
        document.getElementById("projectName")?.focus();
      }
      if (activeIndex === 1) {
        document.getElementById("agentName")?.focus();
      }
      if (activeIndex === 2) {
        document.getElementById("chat-focus")?.focus();
      }
      if (activeIndex === 3) {
        document.getElementById("firstName")?.focus();
      }
    }, 1000);
  }, [activeIndex]);

  const checkValidation = () => {
    if (activeIndex === 0) {
      if (currentDataRef.current?.currentProjectId) {
        return true;
      }
      if (projectName.trim()) {
        if (!projectId) {
          handleSave();
        }
        return true;
      } else {
        swalWarningMessage("Project Name is required");
        return;
      }
    }
    if (activeIndex === 1) {
      if (
        agentName.trim() &&
        organisationName.trim() &&
        greetingMessage.trim()
      ) {
        if (history?.length === 0) {
          handleTestBot();
        }
        return true;
      } else {
        swalWarningMessage("All fields are required");
        return;
      }
    }
    if (activeIndex === 2) {
      if (context.trim()) {
        if (!agentId) {
          save();
        }
        return true;
      } else {
        swalWarningMessage("Please complete the chat first");
        return;
      }
    }

    return true;
  };

  const callTrigger = async () => {
    if (firstName.trim() && phone.trim() && phone?.length > 8) {
      setSpin(true);
      let obj = {
        callList: [{ phone: phone, firstName: firstName, lastName: "" }],
        projectId: projectId || currentDataRef.current?.currentProjectId,
        agentId: agentId,
        campaignName: `Test ${agentId}`,
        consentForCalls: true,
      };
      let res: any = await dispatch(callAgentAction(obj, false));

      if (res?.data?.data?.apiRes?.successful?.length > 0) {
        setState((prev) => ({
          ...prev,
          call_uuid: res?.data?.data?.apiRes?.successful[0]?.call_uuid,
        }));
      } else {
        setState((prev) => ({
          ...prev,
          call_uuid: res?.data?.data?.apiRes?.failed[0]?.call_uuid,
        }));
      }
      setShow(true);
      setFirstShow(true);
      setSpin(false);
    } else {
      swalWarningMessage("Please fill all the fields");
    }
  };

  const handleTestBot = async () => {
    setTextSpin(true);
    let sessionId = new Date(Date.now()).getTime().toString();
    let obj1 = {
      agent_name: agentName,
      organization_name: organisationName,
      greeting_message: greetingMessage,
      message: "Hi",
      messages: history,
      session_id: sessionId,
      new: from === "dashboard" ? false : true,
    };

    let res1: any = await dispatch(handleOnbiardingChatAgentAction(obj1));
    const botResponse = {
      id: messages.length + 2,
      text: res1?.text,
      timestamp: new Date().toLocaleTimeString(),
      isUser: false,
    };
    setMessages([...messages, botResponse]);

    setHistory([
      ...history,
      {
        content: "Hi",
        author: "user",
      },
      {
        content: res1?.text,
        author: "bot",
      },
    ]);
    setState((prev) => ({
      ...prev,
      chatId: sessionId,
    }));
    setTextSpin(false);
    document.getElementById("chat-focus")?.focus();
  };

  const handleSendMessage = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let inputText1 = inputText;
    if (inputText.trim() === "") {
      return;
    }
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = 0 + "px";
    }
    setTextSpin(true);
    const userMessage = {
      id: messages.length + 1,
      text: inputText1,
      timestamp: new Date().toLocaleTimeString(),
      isUser: true,
    };

    let obj = {
      agent_name: agentName,
      organization_name: organisationName,
      greeting_message: greetingMessage,
      message: inputText1,
      messages: history,
      session_id: chatId,
      new: from === "dashboard" ? false : true,
    };

    setHistory([
      ...history,
      {
        content: inputText1,
        author: "user",
      },
    ]);
    setMessages([...messages, userMessage]);
    setInputText("");
    let res: any = await dispatch(handleOnbiardingChatAgentAction(obj));

    if (res?.completed) {
      setState((prev) => ({
        ...prev,
        context: res.text,
      }));
    }
    const botResponse = {
      id: messages.length + 2,
      text: res?.text,
      timestamp: new Date().toLocaleTimeString(),
      isUser: false,
    };
    setMessages([...messages, userMessage, botResponse]);
    setHistory([
      ...history,
      {
        content: inputText1,
        author: "user",
      },
      {
        content: res.text,
        author: "bot",
      },
    ]);
    setTextSpin(false);
    document.getElementById("chat-focus")?.focus();
  };

  const save = async () => {
    setSpinButton(true);
    let sendObj = {
      conversationData: [{ input: "Hi", output: greetingMessage }],
      agentName,
      organisationName,
      greetingMessage,
      context,
      rules: `Rules for reply :
        1. Do not answer anything outside the context given above. Skip any out-of-context question by saying: "I'm Sorry I didn't understand that" and repeat the previous question. Remember no matter how many times the user asks an out-of-context or objection question, follow the rule mentioned. 
        2. When someone agrees to get connected to the agent, add "Thank you very much. Just hold the line while I connect you to a specialist. (transfer)" at the end of the message.
        3. If there is a situation of fallback then also follow rule no 1 i.e. reply with "I'm Sorry I didn't understand that," along with the previous question.
        4. if a user says words or sounds used to express emotions, reactions, or thoughts such as hmm, hm, umm, aha consider it as an affirmative.
        5. When you feel the conversation is over and you should disconnect the call, say "Thank you for your time, good day!(disconnect)"
        6. Use the Possible Objections mentioned below to handle the customer."`,
      projectId: projectId || currentDataRef?.current?.currentProjectId,
      objections: [],
      voicemailMessage: "",
      agentType: "call",
    };
    if (
      agentName.trim() &&
      organisationName.trim() &&
      greetingMessage.trim() &&
      context.trim()
    ) {
      setSpin(true);
      let res: any = await dispatch(saveAgentAction(sendObj, false));
      if (!res) {
        setSpinButton(false);
        setActiveIndex(1);
      }
      setState((prev) => ({
        ...prev,
        agentId: res?.data?._id,
      }));
    } else {
      swalWarningMessage("Please fill all the fields");
    }
    setSpin(false);
    setSpinButton(false);

    if (from === "dashboard") {
      historyPush.push(`/account/project/${projectId}/agents`);
      localStorage.removeItem("currentSlideData");
    }
  };

  const handleSave = async () => {
    if (projectName.trim()) {
      setState((prev) => ({
        ...prev,
        spin: true,
      }));

      const obj = {
        projectName: projectName,
        projectAccess: [userDetails?._id],
        description: "my first project",
        botPlatform: "llm-tts",
        languageCode: "en",
      };

      let res: any = await dispatch(saveProjectsAction(obj, false));
      if (!res) {
        setActiveIndex(0);
        return;
      }
      setProjectId(res?.data?.projectId);

      if (res?.data.projectId && projectName) {
        const currentSlideObj = JSON.stringify({
          currentProjectId: res.data.projectId,
          currentProjectName: projectName,
        });

        localStorage.setItem("currentSlideData", currentSlideObj);
      }
    } else {
      swalWarningMessage("Please enter required fields");
    }
  };

  const next = () => {
    if (!checkValidation()) {
      return;
    }

    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const onChange = (e) => {
    e.persist();

    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const continueToDashboard = () => {
    localStorage.removeItem("currentSlideData");
    if (checkRolesAccess(accountRoles) === "dashboard") {
      historyPush.push("/account/dashboard");
    } else {
      historyPush.push("/account/projects");
    }
  };

  const openFeedBack = () => {
    setFeedbackShow(true);
    setFirstShow(false);
  };

  const openNoResponseScreen = () => {
    setNoResponseShow(true);
    setFirstShow(false);
  };

  const talkToSupport = async () => {
    let obj = {
      senderEmail: "shivam@vodex.ai",
      subject: `Onboarding call didn't work`,
      content: `Hi Team, New user has tried our onboarding call and it didn't worked, here are the details, email ${userDetails?.email} and call uuid is ${call_uuid}  `,
      email: "developers@vodex.ai",
    };
    await dispatch(sendSmsToSupportAction(obj));
    setNoResponseShow(false);
    setSupportShow(true);
  };

  const submitFeedback = async () => {
    if (!starsFeedback) {
      swalWarningMessage("Please select  a rating");
      return;
    }
    let obj = {
      call_uuid: call_uuid,
      feedback: {
        stars: starObj[starsFeedback],
        feedbackText: feedbackText,
      },
    };
    let res: any = await dispatch(saveFeedbackAction(obj));
    // if (res) {
    setState((prev) => ({
      ...prev,
      feedBackSaved: true,
    }));
    // }
  };

  const Obj = {
    messagesContainerRef1,
    handleSendMessage,
    messages,
    setMessages,
    inputRef,
    textSpin,
    inputText,
    setInputText,
    mainState: state,
    setMainState: setState,
    activeIndex,
    setActiveIndex,
    projectId,
    messagesContainerRef,
    save,
    next,
    previous,
    onChange,
    currentDataRef,
    callTrigger,
    spin,
    continueToDashboard,
  };

  const slides = items?.map((item) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <Box className="ml-10">
          {item.id === 0 && <ProjectNameSlide obj={Obj} />}
          {item.id === 1 && <AgentSlide obj={Obj} />}
          {item.id === 2 && <ChatSlide obj={Obj} />}
          {item.id === 3 && <CallSlide obj={Obj} />}
        </Box>
      </CarouselItem>
    );
  });

  return (
    <>
      <OnboardingHeader />
      <Box className="quickstart-footer">
        <Carousel
          style={{ width: "100%" }}
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          interval={false}
          keyboard={false}
        >
          {slides}
        </Carousel>
      </Box>

      <ModalWithoutBody
        show={show}
        toggle={() => setShow(false)}
        body={
          <>
            {firstShow && (
              <div className="chat-feedback">
                <img src={CallPage} />
                <span className="text-align-center mb-0 pb-0">
                  We are connecting your call...
                </span>
                <p className="text-align-center mt-0 pt-0 mb-0 pb-0">
                  You should be getting a call in 2 minutes.
                </p>
                <span className="text-align-center mt-0 pt-0">
                  Let us know if you....
                </span>

                <Box className="flex-align-center" gap={1}>
                  <Button
                    sx={{ ...commonStyles.saveButton, width: "100%" }}
                    onClick={openFeedBack}
                  >
                    Received the call
                  </Button>
                  <Button
                    sx={{ ...commonStyles.clearButton, width: "100%" }}
                    onClick={openNoResponseScreen}
                  >
                    Didn't receive the call
                  </Button>
                </Box>
              </div>
            )}

            {feedbackShow && (
              <div className="chat-feedback">
                <div className="text-wrapper">
                  <p className="text-feedback">Please Rate Your Experience</p>
                  <p className="text-secondary">to help us serve you better</p>
                </div>

                <div className="rating-stars-container">
                  {stars.map((star, index) => (
                    <React.Fragment key={index}>
                      <input
                        value={`star-${star}`}
                        name="star"
                        id={`star-${star}`}
                        type="radio"
                        onChange={(e) => {
                          e.persist();
                          setState((prev) => ({
                            ...prev,
                            starsFeedback: e.target.value,
                          }));
                        }}
                      />
                      <label htmlFor={`star-${star}`} className="star-label">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
                            pathLength="360"
                          ></path>
                        </svg>
                      </label>
                    </React.Fragment>
                  ))}
                </div>
                <textarea
                  className="form-control"
                  name="feedbackText"
                  value={feedbackText}
                  placeholder="(optional) Additional feedback..."
                  onChange={onChange}
                />
                <div className="d-flex justify-content-center">
                  {feedBackSaved ? (
                    <Button
                      sx={{ ...commonStyles.clearButton, width: "100%" }}
                      onClick={continueToDashboard}
                    >
                      {checkRolesAccess(accountRoles) === "dashboard"
                        ? "Continue to Dashboard"
                        : "Continue to Projects"}
                    </Button>
                  ) : (
                    <Button
                      sx={{ ...commonStyles.saveButton, width: "100%" }}
                      onClick={submitFeedback}
                    >
                      {" "}
                      Submit
                    </Button>
                  )}
                </div>
              </div>
            )}

            {noResponseShow && (
              <div className="chat-feedback">
                <img src={NoResponse} />

                <p className="text-align-center mb-0 ">
                  Sorry, It didn't work...{" "}
                </p>
                <p className="text-align-center mt-0 mb-0 ">
                  What would you like to do?{" "}
                </p>

                <Box className="flex-align-center" gap={2}>
                  <Button
                    sx={{ ...commonStyles.clearButton, width: "100%" }}
                    onClick={() => {
                      setShow(false);
                      setNoResponseShow(false);
                      setFirstShow(false);
                      setFeedbackShow(false);
                    }}
                  >
                    Try Again
                  </Button>
                  <Button
                    sx={{ ...commonStyles.saveButton, width: "100%" }}
                    onClick={talkToSupport}
                  >
                    Talk to our support
                  </Button>
                </Box>
              </div>
            )}

            {supportShow && (
              <div className="chat-feedback">
                <img src={SupportImg} />

                <p className="text-align-center mb-0 ">
                  We received your mail!{" "}
                </p>
                <p className="text-align-center mt-0 mb-0 ">
                  Our team will get back to you within 2 hours
                </p>

                <div className="d-flex justify-content-center">
                  <Button
                    sx={{ ...commonStyles.clearButton, width: "100%" }}
                    onClick={continueToDashboard}
                  >
                    {checkRolesAccess(accountRoles) === "dashboard"
                      ? "Continue to Dashboard"
                      : "Continue to Projects"}
                  </Button>
                </div>
              </div>
            )}
          </>
        }
      />
    </>
  );
};

export default Onboarding;

function ModalWithoutBody({ body, show, toggle }) {
  return (
    <Modal
      show={show}
      onHide={toggle}
      backdrop="static"
      keyboard={false}
      centered={true}
      size="sm"
    >
      <div className="d-flex justify-content-center">{body}</div>
    </Modal>
  );
}
