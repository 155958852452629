import React, { useEffect, useState } from "react";
import CommonTable from "../CommonComponents/CommonTable";
import { useDispatch } from "react-redux";
import { gatewayQueueAction, getGatewayListAction } from "./Store/admin.action";
import "../../../assets/css/page/OnBoardingForm.scss";
import { Scrollbars } from "react-custom-scrollbars";
import { renderThumb } from "../CommonComponents/Common";
import ConfirmationPopup from "../CommonComponents/ConfirmationPopup";

function GatewayStatus() {
  const dispatch = useDispatch();
  const [data, setData] = useState([{ sid: "" }]);
  const [modal, setModal] = useState(false);
  const [spin, setSpin] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState("");
  const [selectedAction, setSelectedAction] = useState("");

  const init = async () => {
    let res: any = await dispatch(getGatewayListAction());
    setData(res?.table_data?.campaigns);
  };

  useEffect(() => {
    init();
  }, []);
  const refresh = async () => {
    await init();
  };

  const save = async () => {
    setSpin(true);
    await dispatch(gatewayQueueAction(selectedAction, selectedGateway));
    setSpin(false);
    setModal(false);
    init();
  };
  const access = [
    "shivam@vodex.ai",
    "sameer@vodex.ai",
    "sandeep@vodex.ai",
    "praveen@vodex.ai",
  ];
  const actionFunc = async (action, gateway) => {
    setSelectedAction(action);
    setSelectedGateway(gateway);
    setModal(true);
  };
  return (
    <div>
      <button className="custom-button-icon" onClick={refresh}>
        Refresh
      </button>
      <Scrollbars
        style={{
          height: "420px",
          color: "white",
          borderRadius: "10px",
        }}
        renderThumbVertical={renderThumb}
        autoHide={true}
      >
        <CommonTable
          body={data.map((que: any, idx) => {
            let gateway = que?.gateway_name && que?.gateway_name[0];
            return (
              <tr key={`${idx}comm`}>
                <td>{que?.logical_name}</td>
                <td>{gateway}</td>
                <td>{que?.active} </td>
                <td>{que?.usage}</td>
                {/* <td>{que?.avg_bot_delay} </td>
                  <td>{que?.bot_error}</td>
                  <td>{que?.botwrite_time}</td>
                  <td>{que?.connect_error}</td> */}
                <td>{que?.max_limit}</td>
                <td>{que?.on_call}</td>
                <td>{que?.on_stream}</td>
                <td>{que?.queue_size}</td>
                {/* <td>{que?.resolve_error}</td> */}
                <td>{que?.scheduled}</td>
                {/* <td>{que?.ssl_error}</td> */}
                {/* <td>{que?.transit_time}</td> */}
                {/* <td>{que?.wss_error}</td> */}
                <td className="d-flex w-30 ">
                  {/* <button className="btn btn-danger m-1" onClick={() => actionFunc("disable", gateway)}>Disable</button> */}
                  <button
                    className="btn btn-success m-1"
                    onClick={() => actionFunc("enable", gateway)}
                  >
                    Enable
                  </button>
                  <button
                    className="btn btn-success m-1"
                    onClick={() => actionFunc("clear", gateway)}
                  >
                    Clear Calls
                  </button>
                </td>
              </tr>
            );
          })}
          headers={[
            "Gateway Name",
            "Gateway value",
            "Active",
            "Current Calls",
            // "avg_bot_delay",
            // "bot_error",
            // "botwrite_time",
            // "connect_error",
            "max_limit",
            "Answered",
            "Streaming",
            "Queue",
            // "resolve_error",
            "Scheduled",
            // "ssl_error",
            // "transit_time",

            // "wss_error",
            "Action",
          ]}
        />
      </Scrollbars>

      <ConfirmationPopup
        handleSave={save}
        heading={"Action"}
        message={`Are you sure you want to ${selectedAction} the gateway - ${selectedGateway}`}
        show={modal}
        toggle={() => {
          setSelectedAction("");
          setSelectedGateway("");
          setModal(false);
        }}
        spin={spin}
      />
    </div>
  );
}

export default GatewayStatus;
