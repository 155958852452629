import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Button,
  Stack,
  Typography,
  Pagination,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { shortenString } from "../../../../Constant/helper";
import chroma from "chroma-js";
import ReactAudioPlayer from "react-audio-player";
import { styles } from "../../../../assets/MUIStyles/campaignDetailsList";
import { ReactComponent as NextIcon } from "../../../../assets/icons/next.svg";
import { ReactComponent as PreviousIcon } from "../../../../assets/icons/previous.svg";
import { ReactComponent as EmptyStateIcon } from "../../../../assets/icons/empty-state.svg";
import BaseButton from "../../Common/Buttons/BaseButton";
import { LoadingSlides, PAGE_SIZE_50 } from "../../CommonComponents/Common";

const CustomRow = ({
  data,
  trackEnabledRecording,
  getName,
  getCallStatus,
  selectedAccount,
  getOutcome,
  openCallTranscriptModal,
  live,
  handleCopy,
}) => {
  return (
    data?.length > 0 &&
    data?.map((campData, id) => (
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "#F1F4FA",
            cursor: "default",
          },
        }}
      >
        <TableCell sx={styles.tableCell}>
          {
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="tooltip-disabled">{getName(campData)}</Tooltip>
              }
              rootClose={true}
              rootCloseEvent={"click"}
            >
              <span className="d-inline-block">
                <span style={{ pointerEvents: "none" }}>{campData?.phone}</span>
              </span>
            </OverlayTrigger>
          }
        </TableCell>
        <TableCell sx={styles.tableCell}>{getCallStatus(campData)}</TableCell>
        <TableCell sx={styles.tableCell}>
          {selectedAccount?.params?.pricesWaivers
            ? "--"
            : campData?.newCost || "--"}
        </TableCell>
        <TableCell
          onClick={() => handleCopy(campData?.call_uuid)}
          sx={styles.tableCell}
        >
          {trackEnabledRecording?.length > 0 && getOutcome(campData)}
          {shortenString(campData?.call_uuid, 15)}
        </TableCell>

        <TableCell sx={styles.tableCell}>
          <ReactAudioPlayer
            src={
              campData?.callRecordingUrl ||
              `https://storage.googleapis.com/botstream-recordings/${campData?.call_uuid}.mp3`
            }
            controls
            style={{
              minWidth: "100px",
              height: "30px",
            }}
          />
        </TableCell>
        {live === "false" && (
          <TableCell sx={styles.tableCell}>
            <Box
              disabled={!["703", "712"].includes(campData?.callStatus)}
              className="cursor-pointer"
              onClick={() => openCallTranscriptModal(campData)}
              component={"button"}
              sx={{ color: "#4280FF", fontSize: 12, fontWeight: 500 }}
            >
              View Transcript
            </Box>
          </TableCell>
        )}

        <TableCell sx={styles.tableCell}></TableCell>
      </TableRow>
    ))
  );
};

const CampaignRunTable = ({ obj }) => {
  const {
    data,
    live,
    trackEnabledRecording,
    getOutcome,
    selectedAccount,
    constantData,
    inBound,
    handleNext,
    handlePrevious,
    handlePageChange,
    currentPage,
    openCallTranscriptModal,
    handleCopy,
    totalCallsMade,
    loading,
  } = obj;
  const [expandedRow, setExpandedRow] = useState(null);
  const [isInboundExpand, setInBoundExpand] = useState(false);
  const [isRedialExpand, setRedailExpand] = useState(false);

  const getName = (phone) => {
    const firstName = phone?.customParameters?.firstName || "";
    const lastName = phone?.customParameters?.lastName || "";
    return `${firstName} ${lastName}`.trim() || "No Name";
  };

  const toggleInBound = () => {
    setInBoundExpand((prev) => !prev);
  };
  const toggleRedial = () => {
    setRedailExpand((prev) => !prev);
  };
  const toggleExpand = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
    setInBoundExpand(false);
    setRedailExpand(false);
  };

  let totalPages = Math.ceil(Number(totalCallsMade) / PAGE_SIZE_50);

  const getCallStatus = (campaign) => {
    let statusColor =
      constantData["callStatusColour"][campaign?.callStatus] || "#333333";
    try {
      statusColor = chroma(statusColor).hex();
    } catch (error) {
      statusColor = "#333333";
    }
    const lighterColor = chroma(statusColor).alpha(0.1).hex();
    if (constantData["callStatus"][campaign?.callStatus]) {
      return (
        <Box className="flex-xy-center">
          <Button
            variant="outlined"
            size="small"
            sx={styles.callStatusButton(lighterColor, statusColor)}
          >
            <Box
              sx={{
                backgroundColor: statusColor || "#333333",
                ...styles.callStatusBox,
              }}
            />
            {shortenString(
              constantData["callStatus"][campaign?.callStatus],
              15
            )}
          </Button>
        </Box>
      );
    }
    return "--";
  };

  // Inbound and Redial Calls Data
  const fetchData = (label, data, toggleRow, expand) => {
    return (
      <>
        <TableRow
          sx={{
            "&:hover": {
              backgroundColor: "#F1F4FA",
              cursor: "default",
            },
          }}
        >
          <TableCell colSpan={live === "false" ? 6 : 5} sx={styles.tableCell}>
            <strong>{label}</strong>
          </TableCell>
          <TableCell sx={styles.tableCell}>
            <IconButton onClick={toggleRow}>
              <ExpandMoreIcon
                style={{
                  transform: expand ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.2s",
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
        {expand && (
          <CustomRow
            live={live}
            data={data}
            trackEnabledRecording={trackEnabledRecording}
            getName={getName}
            getCallStatus={getCallStatus}
            selectedAccount={selectedAccount}
            getOutcome={getOutcome}
            openCallTranscriptModal={openCallTranscriptModal}
            handleCopy={handleCopy}
          />
        )}
      </>
    );
  };

  return loading ? (
    <Box sx={styles.loaderContainer}>
      <LoadingSlides bgColor="#FF5E00" />
    </Box>
  ) : (
    <Box>
      {data?.length > 0 ? (
        <Box>
          <TableContainer
            sx={{
              ...styles.tableContainer,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottom: 0,
            }}
            component={Paper}
            elevation={0}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={styles.tableHeader}>Phone Number</TableCell>
                  <TableCell sx={styles.tableHeader}>Call Status</TableCell>
                  <TableCell sx={styles.tableHeader}>Cost</TableCell>
                  <TableCell sx={styles.tableHeader}>UUID</TableCell>
                  <TableCell sx={styles.tableHeader}>Call Recording</TableCell>
                  {live === "false" && (
                    <TableCell sx={styles.tableHeader}>View Summary</TableCell>
                  )}
                  <TableCell sx={styles.tableHeader}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((campaignData, id) => {
                  const campaign = campaignData;
                  const redialedCalls = campaign?.redialedCalls;
                  const inboundCalls = campaign?.inboundCalls;
                  const outBoundCallsData = campaign?.outboundCalls;
                  const isExpanded = expandedRow === id;
                  const combinedCalls = inBound &&
                    outBoundCallsData?.length > 0 && [
                      ...inboundCalls,
                      ...outBoundCallsData,
                    ];

                  return (
                    <React.Fragment key={id}>
                      {!inBound ? (
                        <TableRow
                          sx={{
                            "&:hover": {
                              backgroundColor: "#F1F4FA",
                              cursor: "default",
                            },
                          }}
                        >
                          <TableCell sx={styles.tableCell}>
                            {
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {getName(campaign)}
                                  </Tooltip>
                                }
                                rootClose={true}
                                rootCloseEvent={"click"}
                              >
                                <span className="d-inline-block">
                                  <span style={{ pointerEvents: "none" }}>
                                    {campaign?.phone}
                                  </span>
                                </span>
                              </OverlayTrigger>
                            }
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {getCallStatus(campaign)}
                          </TableCell>
                          <TableCell sx={styles.tableCell}>
                            {selectedAccount?.params?.pricesWaivers
                              ? "--"
                              : campaign?.newCost || "--"}
                          </TableCell>
                          <TableCell
                            onClick={() => handleCopy(campaign?.call_uuid)}
                            sx={styles.tableCell}
                          >
                            {trackEnabledRecording?.length > 0 &&
                              getOutcome(campaign)}
                            {shortenString(campaign?.call_uuid, 15)}
                          </TableCell>

                          <TableCell sx={styles.tableCell}>
                            <ReactAudioPlayer
                              src={
                                campaign?.callRecordingUrl ||
                                `https://storage.googleapis.com/botstream-recordings/${campaign?.call_uuid}.mp3`
                              }
                              controls
                              style={{
                                minWidth: "100px",
                                height: "30px",
                              }}
                            />
                          </TableCell>
                          {live === "false" && (
                            <TableCell sx={styles.tableCell}>
                              <BaseButton
                                disabled={
                                  !["703", "712"].includes(
                                    campaignData?.callStatus
                                  )
                                }
                                title="View Transcript"
                                handleClick={() =>
                                  openCallTranscriptModal(campaignData)
                                }
                                styles={{ color: "#4280FF" }}
                              />
                            </TableCell>
                          )}
                          <TableCell sx={styles.tableCell}>
                            {(inBound
                              ? outBoundCallsData.length > 0
                              : inboundCalls.length > 0 ||
                                redialedCalls.length > 0) && (
                              <IconButton onClick={() => toggleExpand(id)}>
                                <ExpandMoreIcon
                                  style={{
                                    transform:
                                      expandedRow === id
                                        ? "rotate(180deg)"
                                        : "rotate(0deg)",
                                    transition: "transform 0.2s",
                                  }}
                                />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell sx={styles.tableCell}>
                            {
                              <OverlayTrigger
                                placement="right"
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    {getName(campaign)}
                                  </Tooltip>
                                }
                                rootClose={true}
                                rootCloseEvent={"click"}
                              >
                                <span className="d-inline-block">
                                  <span style={{ pointerEvents: "none" }}>
                                    {campaign?.phone}
                                  </span>
                                </span>
                              </OverlayTrigger>
                            }
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell sx={styles.tableCell}>
                            <IconButton onClick={() => toggleExpand(id)}>
                              <ExpandMoreIcon
                                style={{
                                  transform:
                                    expandedRow === id
                                      ? "rotate(180deg)"
                                      : "rotate(0deg)",
                                  transition: "transform 0.2s",
                                }}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}

                      {/* Redialed and Inbound Calls */}
                      {selectedAccount?.params?.inboundEnable &&
                        isExpanded &&
                        !inBound &&
                        inboundCalls?.length > 0 &&
                        fetchData(
                          "Inbound Calls",
                          inboundCalls,
                          toggleInBound,
                          isInboundExpand
                        )}
                      {isExpanded &&
                        !inBound &&
                        redialedCalls?.length > 0 &&
                        fetchData(
                          "Redialed Calls",
                          redialedCalls,
                          toggleRedial,
                          isRedialExpand
                        )}

                      {/* Inbound Calls */}
                      {inBound && isExpanded && (
                        <CustomRow
                          live={live}
                          data={combinedCalls}
                          trackEnabledRecording={trackEnabledRecording}
                          getName={getName}
                          getCallStatus={getCallStatus}
                          selectedAccount={selectedAccount}
                          getOutcome={getOutcome}
                          openCallTranscriptModal={openCallTranscriptModal}
                          handleCopy={handleCopy}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" sx={styles.bottomContainer}>
            <Box color="#555555" display="flex" gap={1.5} alignItems="center">
              <Typography fontSize={12} fontWeight={500}>
                Total{" "}
              </Typography>
              <Typography
                component="span"
                fontSize={12}
                fontWeight={500}
                border={1}
                borderColor="#CCD2DE"
                px={1.5}
                py={0.25}
                borderRadius={2}
              >
                {`${data?.length} row(s)`}
              </Typography>
            </Box>

            {totalCallsMade > 50 && (
              <>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                  size="medium"
                  variant="outlined"
                  siblingCount={0}
                  boundaryCount={2}
                  hidePrevButton
                  hideNextButton
                  sx={styles.pagination}
                />

                <Box display="flex" gap={3}>
                  <Button
                    variant="outlined"
                    startIcon={
                      <PreviousIcon
                        style={{
                          opacity: currentPage === 1 ? 0.5 : 1,
                          color: currentPage === 1 ? "#B0B0B0" : "#666666",
                        }}
                      />
                    }
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                    sx={styles.paginationButtons}
                  >
                    Previous
                  </Button>

                  <Button
                    variant="outlined"
                    endIcon={
                      <NextIcon
                        style={{
                          opacity: currentPage === totalPages ? 0.5 : 1,
                          color:
                            currentPage === totalPages ? "#B0B0B0" : "#666666",
                        }}
                      />
                    }
                    onClick={handleNext}
                    disabled={currentPage === totalPages}
                    sx={styles.paginationButtons}
                  >
                    Next
                  </Button>
                </Box>
              </>
            )}
          </Stack>
        </Box>
      ) : (
        <Box sx={styles.noDataContainer}>
          <Typography sx={styles.noDataText}>No Calls Available ...</Typography>
          <Box>
            <EmptyStateIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CampaignRunTable;
