export const leftMenuStyle = (theme, open) => ({
    bg: {
      backgroundColor: "#181818",
    },
    leftPointer: {
      position: "fixed",
      top: "52px",
      left: "256px",
      zIndex: "9999",
      cursor: "pointer",
      transition: theme.transitions.create(["left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    rightPointer: {
      position: "fixed",
      top: "53px",
      left: "60px",
      zIndex: "9999",
      cursor: "pointer",
      transition: theme.transitions.create(["left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    searchBar: {
      container: (base) => ({
        ...base,
        flexGrow: 1,
      }),
      control: (base) => ({
        ...base,
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        color: "#FFFFFF",
        fontSize: "14px",
        fontWeight: 400,
        minHeight: "40px",
      }),
      placeholder: (base) => ({
        ...base,
        color: "rgba(158, 159, 161, 1)", 
      }),
      input: (base) => ({
        ...base,
        color: "#FFFFFF",
      }),
      singleValue: (base) => ({
        ...base,
        color: "#FFFFFF",
      }),
      menu: (base) => ({
        ...base,
        backgroundColor: "rgba(17, 17, 17, 1)", 
        borderRadius: "8px", 
        marginTop: 0, 
       
      }),
      menuList: (base) => ({
        ...base,
        padding: "5px", 
        maxHeight: "200px",
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }),
      option: (base, { isFocused, isSelected }) => ({
        ...base,
        fontSize:"14px",
        backgroundColor: isFocused
          ? "rgba(47, 53, 62, 1)"
          : "transparent",
        color: isSelected
          ? "#FFFFFF"
          : "rgba(158, 159, 161, 1)",
        cursor: "pointer",
      }),
    },
    listItemConatiner: {
      display: "flex",
      alignItems: "center",
      gap: 1,
      minHeight: "40px",
      width: "100%",
      borderRadius: "8px",
      cursor: "pointer",
    },
    mailStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "orange",
      height: "23px",
      width: "23px",
      borderRadius: "100%",
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "12px",
    },
    settingsBox: {
      paddingLeft: "16px",
      display: "flex",
      gap: 1,
      position: "fixed",
      top: "24px",
      width: "500px",
      left: `${open ? "264px" : "72px"}`,
      transition: theme.transitions.create(["left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    settingsNav: {
      paddingTop: "6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      minWidth: "170px",
      height: "40px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      cursor: "pointer",
    },
    settingsNav: {
      paddingTop: "6px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      minWidth: "170px",
      height: "40px",
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
      cursor: "pointer",
    },
  });
  
  const sharedStyles = { color: "#9E9FA1", fontSize: "14px", fontWeight: "400" };
  
  export const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: "232px",
      backgroundColor: "#111111",
      color: "#9E9FA1",
      fontSize: "14px",
      fontWeight: "400",
      border: "1px solid #2F353E",
      borderRadius: "8px",
      boxShadow: state.isFocused ? "0 0 0 1px #2F353E" : "none",
      "&:hover": {
        border: "1px solid #2F353E",
      },
    }),
    placeholder: (base) => ({
      ...base,
      ...sharedStyles,
    }),
    singleValue: (base) => ({
      ...base,
      ...sharedStyles,
    }),
    input: (base) => ({
      ...base,
      ...sharedStyles,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "#111111",
      borderRadius: "8px",
      border: "1px solid #2F353E",
      color: "#9E9FA1",
      boxShadow: "none",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      borderRadius: "8px",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#2F353E" : "#111111",
      color: state.isSelected ? "#FFFFFF" : "#9E9FA1",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#2F353E",
        color: "#FFFFFF",
      },
      fontSize: "14px",
      fontWeight: "400",
    }),
  };
  