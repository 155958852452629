import React, { useState } from "react";
import {
    Box,
    Button,
    MenuItem,
    Popover,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import BaseButton from "../Common/Buttons/BaseButton";

const PatientTableFilters = ({ filtersObj }) => {
  const reducer = useSelector((reducer: any) => reducer);
  const {
    ProjectReducer: { leadsList },
  } = reducer;
  const {
    popoverOpen,
    handlePopoverClose,
    anchorEl,
    columnOrder,
    setFilteredData,
  } = filtersObj;
  const [filters, setFilters] = useState<
    { columnName: string; operator: string; value: string }[]
  >([{ columnName: "", operator: "contains", value: "" }]);

  const fetchSheetsData = (filters) => {
    if (!filters || filters.length === 0) {
      setFilteredData(leadsList);
      return;
    }

    const newFiltered = leadsList.filter((item) =>
      filters.every((filter) => {
        const { columnName, operator, value } = filter;
        const itemValue = (item?.[columnName] || "").toString().toLowerCase();
        const filterValue = value.toLowerCase();

        switch (operator) {
          case "=":
            return itemValue === filterValue;
          case "!=":
            return itemValue !== filterValue;
          case "<":
            return (
              !isNaN(itemValue) &&
              !isNaN(filterValue) &&
              +itemValue < +filterValue
            );
          case ">":
            return (
              !isNaN(itemValue) &&
              !isNaN(filterValue) &&
              +itemValue > +filterValue
            );
          case "contains":
          default:
            return itemValue.includes(filterValue);
        }
      })
    );

    setFilteredData(newFiltered);
  };

  const updateFilter = (index: number, e) => {
    const { name, value } = e.target;
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [name]: value };
    setFilters(newFilters);
  };

  const addFilter = () => {
    const lastFilter = filters[0];

    if (!lastFilter.columnName || !lastFilter.operator || !lastFilter.value) {
      return;
    }

    setFilters([
      { columnName: "", operator: "contains", value: "" },
      ...filters,
    ]);
  };

  const removeFilter = (index: number) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const applyFilters = async () => {
    await fetchSheetsData(filters);
  };

  const clearFilters = () => {
    setFilters([{ columnName: "", operator: "contains", value: "" }]);
    fetchSheetsData([]);
  };

  return (
    <Popover
      open={popoverOpen}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      PaperProps={{
        style: { padding: 16, width: 610, borderRadius: 8 },
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        {filters?.map((filter, index) => (
          <Stack direction="row" spacing={1} alignItems="center" key={index}>
            <Select
              name="columnName"
              value={filter.columnName}
              onChange={(e) => updateFilter(index, e)}
              displayEmpty
              size="small"
              sx={styles.select}
            >
              <MenuItem value="">
                <em>Select Column</em>
              </MenuItem>
              {columnOrder.map(({ key }, i) => (
                <MenuItem key={i} value={key as any}>
                  {key}
                </MenuItem>
              ))}
            </Select>

            <Select
              name="operator"
              value={filter.operator}
              onChange={(e) => updateFilter(index, e)}
              size="small"
              sx={styles.select}
            >
              <MenuItem value="=">=</MenuItem>
              <MenuItem value="!=">!=</MenuItem>
              <MenuItem value="<">{"<"}</MenuItem>
              <MenuItem value=">">{">"}</MenuItem>
              <MenuItem value="contains">Contains</MenuItem>
            </Select>

            <TextField
              size="small"
              name="value"
              placeholder="Enter value"
              value={filter.value || ""}
              onChange={(e) => updateFilter(index, e)}
              sx={styles.textField}
            />

            {index !== 0 && (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => removeFilter(index)}
                sx={styles.removeButton}
              >
                X
              </Button>
            )}

            {index === 0 && (
              <Button
                variant="outlined"
                onClick={addFilter}
                sx={styles.filterButton}
                disabled={
                  !filter?.columnName || !filter?.operator || !filter?.value
                }
              >
                + Add
              </Button>
            )}
          </Stack>
        ))}

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              onClick={() => {
                clearFilters();
                handlePopoverClose();
              }}
              sx={styles.actionButton}
            >
              Clear Filters
            </Button>

            <BaseButton
              title="Apply Filters"
              variant="contained"
              handleClick={() => {
                applyFilters();
                handlePopoverClose();
              }}
            />
          </Stack>
        </Box>
      </Box>
    </Popover>
  );
};

export default PatientTableFilters;

const styles = {
  filterButton: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    border: "1px solid #B0B0B0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
      border: "1px solid #B0B0B0",
    },
  },
  select: {
    minWidth: 150,
    "& .MuiSelect-select": {
      fontSize: 13,
      color: "#333333",
    },
  },
  textField: {
    minWidth: 150,
    "& .MuiInputBase-input": {
      fontSize: 13,
      color: "#333333",
    },
  },
  removeButton: {
    minWidth: 40,
    fontSize: 12,
  },
  actionButton: {
    color: "#333333",
    fontSize: 12,
    fontWeight: 500,
    borderRadius: 2,
    textTransform: "capitalize",
    border: "1px solid #B0B0B0",
    "&:hover": {
      backgroundColor: "#F0F0F0",
      border: "1px solid #B0B0B0",
    },
  },
};
