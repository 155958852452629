import React from "react";
import { Box } from "@mui/material";
import { Row } from "reactstrap";
import { commonStyles } from "../../../../../assets/MUIStyles/commonStyles";

const CallStatusFilter = ({
  onChange,
  allCallStatus,
  statusFilter,
  showAll,
  setShowAll,
}) => {
  const FILTERS = [
    "703",
    "704",
    "712",
    "710",
    "705",
    "716",
    "718",
    "706",
    "707",
    "708",
    "709",
    "711",
    "713",
    "700",
    "701",
    "702",
    "714",
    "715",
    "717",
    "719",
    "720",
  ];
  const INITIAL_COUNT = 12;
  const filtersToShow = showAll ? FILTERS : FILTERS.slice(0, INITIAL_COUNT);

  return (
    <Box className="mt-4">
      <span className="font-12-grey">Call Status</span>
      <Box maxHeight={"400px"} sx={commonStyles.hideScroll}>
        <ul>
          {filtersToShow.map((innerArr, id) => {
            const idx = `cbx-${id + 1}`;
            return (
              <li key={`staat${innerArr}`}>
                <Row className="mt-3">
                  <Box>
                    <Box className="container">
                      <Box className="flex-align-center">
                        <input
                          type="checkbox"
                          id={idx}
                          checked={statusFilter?.includes(innerArr)}
                          onChange={() => onChange("statusFilter", innerArr)}
                        />
                        <label
                          htmlFor={idx}
                          className="font-12-grey ml-3 check-label"
                        >
                          {allCallStatus[innerArr]}
                        </label>
                      </Box>
                    </Box>
                  </Box>
                </Row>
              </li>
            );
          })}
        </ul>
      </Box>

      {statusFilter.length < 12 && (
        <span
          className="font-12-lightgrey cursor-pointer"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? "see less" : "see more"}
        </span>
      )}
    </Box>
  );
};

export default CallStatusFilter;
