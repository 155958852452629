import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CampaignDetailsList from "./CampaignDetailsList";

import { Toast } from "react-bootstrap";
import { shortenString } from "../../../../Constant/helper";
import useQueryParams from "../../../Hooks/useQueryParams";
import {
  converTime,
  HintIconWithTooltip,
  isEmpty,
  PAGE_SIZE_50,
} from "../../CommonComponents/Common";
import {
  checkQueueAction,
  getCampaignByIdAction,
  getCampaignRunsByIdAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import { Box } from "@mui/material";
import Draggable from "react-draggable";

const CampaignDetails: React.FC = () => {
  const { campaignid, campaignRuns } = useQueryParams();
  const { projectId } = useParams<{ projectId: string }>();
  const [data, setData] = useState<any>({});
  const [campaignRunDetailsData, setcampaignRunDetailsData] = useState<any>({});

  let modifiedName;
  function hasAndAfterSpace(string) {
    return /\s(?=and)/.test(string);
  }
  if (data?.campaignName && hasAndAfterSpace(data?.campaignName)) {
    modifiedName = data?.campaignName.toString().replace("and", "&");
  }
  const dispatch = useDispatch();
  const reducer: any = useSelector((state) => state);
  const {
    CallSettingReducer: { callSettings },
  } = reducer;
  const [queueCount, setQueueCount] = useState({ ultra: 1, normal: 0 });
  const init = async () => {
    if (!isEmpty(callSettings)) {
      let res: any = await dispatch(checkQueueAction(callSettings?.gateway));
      setQueueCount(res);
    }
  };

  useEffect(() => {
    const init = async () => {
      const obj = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        campaignId: campaignid,
      };
      let res = await dispatch(getCampaignByIdAction(projectId, obj));
      setData(res);
      const obj1 = {
        pageIndex: 1,
        pageSize: PAGE_SIZE_50,
        campaignRunId: campaignRuns,
      };
      let res1 = await dispatch(
        getCampaignRunsByIdAction(projectId, campaignid, obj1)
      );
      setcampaignRunDetailsData(res1[0]);
    };
    init();
  }, []);

  useEffect(() => {
    init();
  }, [callSettings]);
  useEffect(() => {
    let kl = 0;
    if (!isEmpty(callSettings)) {
      if (kl === 0) {
        const interval = setInterval(() => {
          kl = 1;
          init();
        }, 40000);
        return () => clearInterval(interval);
      }
    }
  }, [callSettings]);

  const calculateQueue = (type) => {
    let val = Number(queueCount[type]) / 2 / 60 + 2;
    return queueCount[type] > 0 ? `${Math.ceil(val)} minutes` : `No Queue`;
  };
  return (
    <Box>
      <Box p={1}>
        {(Number(queueCount?.normal) > 0 || Number(queueCount?.ultra) > 0) && (
          <Draggable>
            <Toast
              style={{
                position: "absolute",
                top: 150,
                right: 30,
                zIndex: 100,
              }}
            >
              <Toast.Header closeButton={false}>
                <strong className="mr-auto">
                  Calls in queue{" "}
                  <HintIconWithTooltip
                    content={
                      "Currently, there is a queue for calls, and we appreciate your patience. You will receive a call once the queue is completed."
                    }
                    position="left"
                  />
                </strong>{" "}
                {/* <small>just now</small> */}
              </Toast.Header>
              <Toast.Body>
                Ultra Realistic Voices : {queueCount?.ultra}
                <br />
                <span>Estimated time to clear : {calculateQueue("ultra")}</span>
                <hr />
                Normal Voices : {queueCount?.normal} <br />
                <span>
                  Estimated time to clear : {calculateQueue("normal")}
                </span>
              </Toast.Body>
            </Toast>
          </Draggable>
        )}
        
        <CampaignDetailsList
          name={
            modifiedName
              ? shortenString(modifiedName, 25)
              : shortenString(data?.campaignName, 25)
          }
          campaignDate={converTime(campaignRunDetailsData?.createdAt)}
          campaignData={data}
        />{" "}
      </Box>
    </Box>
  );
};

export default CampaignDetails;
