import { Box, Typography } from "@mui/material";
import React from "react";

const SummaryCard = ({ title, value, circleColor }) => {
  return (
    <Box className="flex-column-between summary-container">
      <Box className="flex-between-only">
        <Typography className="font-12-lightgrey">{title}</Typography>
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: "50%",
            background: circleColor,
          }}
        />
      </Box>

      <Typography className="font-20-grey">{value}</Typography>
    </Box>
  );
};

export default SummaryCard;
