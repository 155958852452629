import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";

import { ReactComponent as BackIcon } from "../../../../assets/Images/BackIcon.svg";
import { ReactComponent as AddIcon } from "../../../../assets/Images/AddIcon.svg";
import { styles } from "../../../../assets/MUIStyles/campaignDetailsList";
import {
  CALL_STATUS,
  HintIconWithTooltip,
  HintIconWithTooltipWhite,
  isEmpty,
} from "../../CommonComponents/Common";
import { shortenString } from "../../../../Constant/helper";

import chroma from "chroma-js";
import ReactAudioPlayer from "react-audio-player";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const FilteredCampaignBody = ({
  campaignRunListData,
  trackEnabledRecording,
  newCampaignData,
  constantData,
  selectedAccount,
  setOpen,
  setState,
  toggleState,
  setSelectAll,
}) => {
  const { setPopup } = toggleState;

  const getName = (phone) => {
    const firstName = phone?.customParameters?.firstName || "";
    const lastName = phone?.customParameters?.lastName || "";
    return `${firstName} ${lastName}`.trim() || "No Name";
  };

  const getCallStatus = (campaign) => {
    let statusColor =
      constantData["callStatusColour"][campaign?.callStatus] || "#333333";

    try {
      statusColor = chroma(statusColor).hex();
    } catch (error) {
      statusColor = "#333333";
    }
    const lighterColor = chroma(statusColor).alpha(0.1).hex();

    return (
      <Box className="flex-xy-center">
        <Button
          variant="outlined"
          size="small"
          sx={styles.callStatusButton(lighterColor, statusColor)}
        >
          <Box
            sx={{
              backgroundColor: statusColor || "#333333",
              ...styles.callStatusBox,
            }}
          />
          {!isEmpty(CALL_STATUS) &&
            shortenString(CALL_STATUS[campaign?.callStatus], 15)}
        </Button>
      </Box>
    );
  };

  return (
    <Box
      sx={{ minWidth: "580px", height: "100%", paddingBottom: "60px" }}
      px={2.5}
      pt={3}
    >
      <Box
        maxHeight={"90%"}
        sx={{
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
          scrollbarWidth: "none",
        }}
      >
        <Box className="flex-between" pt={2} pb={3}>
          <Typography className="font-20-grey">Filtered Numbers</Typography>
          <Box className="flex-xy-center">
            <Button
              className="mr-2"
              onClick={() => setOpen(false)}
              sx={commonStyles.clearButton}
            >
              <BackIcon className="mr-1" />
              Back
            </Button>
            <Button
              disabled={newCampaignData?.length > 0}
              className="btn btn-info mr-3"
              onClick={() => {
                setPopup(true);
                setSelectAll(true);
                setOpen(false);
              }}
              sx={commonStyles.saveButton}
            >
              {newCampaignData?.length > 0 && <AddIcon className="mr-1" />}
              Create New Campaign
              {newCampaignData?.length === 0 && (
                <HintIconWithTooltipWhite
                  content={
                    "Will Create a new campaign with all the filtered numbers"
                  }
                  position={"bottom"}
                />
              )}
            </Button>
          </Box>
        </Box>
        <Box>
          <DynamicTable
            columns={[
              {
                label: "",
                render: (campaign) => (
                  <input
                    className="mr-1"
                    checked={
                      !!newCampaignData.find((o) => o._id === campaign._id)
                    }
                    type="checkbox"
                    onChange={(e) => {
                      let data = newCampaignData;
                      if (!e.target.checked) {
                        let c = data?.findIndex((o) => o._id === campaign._id);

                        data.splice(c, 1);
                      } else {
                        data.push(campaign);
                      }

                      setState((prev) => ({
                        ...prev,
                        newCampaignData: data,
                      }));
                    }}
                  />
                ),
              },
              {
                label: "Phone Number",
                render: (campaign) => (
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-disabled">
                        {getName(campaign)}
                      </Tooltip>
                    }
                    rootClose={true}
                    rootCloseEvent={"click"}
                  >
                    <span className="d-inline-block">
                      <span style={{ pointerEvents: "none" }}>
                        {campaign?.phone}
                      </span>
                    </span>
                  </OverlayTrigger>
                ),
              },
              {
                label: "Call Status",
                render: (campaign) => getCallStatus(campaign),
              },
              {
                label: "Cost",
                render: (campaign) =>
                  selectedAccount?.params?.pricesWaivers
                    ? "--"
                    : campaign?.newCost || "--",
              },
              {
                label: "UUID",
                render: (campaign) => {
                  return (
                    <HintIconWithTooltip
                      position="left"
                      content={campaign?.call_uuid}
                    />
                  );
                },
              },
              {
                label: "Recording",
                render: (campaign) => (
                  <ReactAudioPlayer
                    src={
                      campaign?.callRecordingUrl ||
                      `https://storage.googleapis.com/botstream-recordings/${campaign?.call_uuid}.mp3`
                    }
                    controls
                    style={{
                      maxWidth: "100px",
                      height: "30px",
                    }}
                  />
                ),
              },
            ]}
            data={campaignRunListData}
            loading={false}
            totalPages={1}
            currentPage={1}
            handlePageChange={() => {}}
            handlePrevious={() => {}}
            handleNext={() => {}}
            noDataAvailableMessage="No Filtered Campaigns Available"
            pagination={true}
            showTotalRows={true}
            rowCount={campaignRunListData?.length}
          />
        </Box>
      </Box>

      <Box
        sx={{
          position: "fixed",
          bottom: "20px",
          pt: 2,
        }}
      >
        <Button
          disabled={newCampaignData?.length === 0}
          onClick={() => {
            if (newCampaignData?.length > 0) {
              setPopup(true);
              setOpen(false);
            }
          }}
          sx={commonStyles.saveButton}
        >
          {newCampaignData?.length === 0 && <AddIcon className="mr-1" />}
          Create Custom Campaign
          {newCampaignData?.length > 0 && (
            <HintIconWithTooltipWhite
              content={"Will Create a new campaign with only selected numbers"}
              position={"top"}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default FilteredCampaignBody;
