import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as FavIcon } from "../../../../assets/icons/favourite.svg";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/arrow-back.svg";
import SearchBar from "../../Common/Search/SearchBar";
import { getCustomWriteAccess } from "../../CommonComponents/Common";
import BaseButton from "../../Common/Buttons/BaseButton";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const CampaignHeader = ({
  toggle,
  searchText,
  setState,
  star,
  campaignCall,
}) => {
  const reducer: any = useSelector((state) => state);

  const handleChange = (e) => {
    e.persist();

    setState((prev) => ({
      ...prev,
      searchText: e.target.value,
    }));
  };

  return (
    <Box className="flex-between">
      <Typography className="font-20-grey">Campaigns</Typography>
      <Box className="flex-xy-center" gap={1.5}>
        <SearchBar searchText={searchText} handleChange={handleChange} />
        {star ? (
          <BaseButton
            title="Back"
            variant="contained"
            handleClick={() => campaignCall(false)}
            startIcon={<ArrowBack className="back-icon" />}
          />
        ) : (
          <>
            <BaseButton
              title={<FavIcon />}
              variant="outlined"
              handleClick={() => campaignCall(true)}
              styles={commonStyles.favBtn}
            />
            {getCustomWriteAccess(reducer) === "write" && (
              <BaseButton
                title="Add New Campaigns"
                variant="contained"
                startIcon={<AddIcon />}
                handleClick={toggle}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CampaignHeader;

