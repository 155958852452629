export const styles = {
  textArea: {
    ".MuiOutlinedInput-root": {
      padding: "5px 13px",
    },
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    fontSize: "13px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  phoneInput: {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid rgba(204, 210, 222, 1)",
    padding: "22px 14px",
    paddingLeft: "50px",
  },
  phoneContainer: {
    width: "100%",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  },
};
