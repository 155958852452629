import React from "react";
import { Box } from "@mui/material";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const CallStatusFilter = ({ obj }) => {
  const {
    saveFilter,
    statusFilter,
    onChange,
    allCallStatus,
    showAll,
    setShowAll,
  } = obj;

  const FILTERS = [
    "703",
    "704",
    "712",
    "710",
    "705",
    "716",
    "718",
    "706",
    "707",
    "708",
    "709",
    "711",
    "713",
    "700",
    "701",
    "702",
    "714",
    "715",
    "717",
    "719",
    "720",
  ];
  const INITIAL_COUNT = 7;
  const filtersToShow = showAll ? FILTERS : FILTERS.slice(0, INITIAL_COUNT);
  const savedStatusFilter =
    showAll && statusFilter.length > 7
      ? statusFilter
      : statusFilter.slice(0, INITIAL_COUNT);
  const displayedFilters = saveFilter ? savedStatusFilter : filtersToShow;

  return (
    <Box className="mt-3">
      {saveFilter ? (
        displayedFilters?.length > 0 && (
          <span className="font-12-600-grey ">Selected Filters</span>
        )
      ) : (
        <span className="font-12-grey">Call Status</span>
      )}
      <Box maxHeight="260px" sx={commonStyles.hideScroll}>
        {displayedFilters.map((innerArr, index) => {
          const uniqueId = `cbx2-${index}`;

          return (
            <Box className="flex-align-center" my={2} key={`staat${innerArr}`}>
              <input
                type="checkbox"
                id={uniqueId}
                checked={statusFilter?.includes(innerArr)}
                onChange={() => onChange("statusFilter", innerArr)}
              />
              <label
                htmlFor={uniqueId}
                className="font-12-grey ml-3 cursor-pointer check-label"
              >
                {allCallStatus[innerArr]}
              </label>
            </Box>
          );
        })}
      </Box>

      {(!saveFilter || (statusFilter.length > 5 && saveFilter)) && (
        <span
          className="font-12-lightgrey cursor-pointer"
          onClick={() => setShowAll((prev) => !prev)}
        >
          {showAll ? "see less" : "see more"}
        </span>
      )}
    </Box>
  );
};

export default CallStatusFilter;
