import React, { Dispatch, useEffect, useState } from "react";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as PlayIcon } from "../../../../assets/Images/PlayIcon.svg";
import { ReactComponent as RedirectIcon } from "../../../../assets/icons/external-link.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/Images/Delete.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/Images/CalendarIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/Images/SuccessProjectIcon.svg";
import { swalConfirmationMessage } from "../../../../Constant/swalMessage";
import {
  converTime,
  getCustomWriteAccess,
  isEmpty,
  PAGE_SIZE_50,
  SlimAlert,
} from "../../CommonComponents/Common";
import CommonModal from "../../CommonComponents/Modal/CommonModal";
import DynamicTable from "../../CommonComponents/Table/DynamicTable";
import {
  checkForCampaignValidationAction,
  deleteCampaignAction,
  favouriteCampaignAction,
  getAllCampaignRunsAction,
  getCampaignAction,
  getCampaignCountAction,
  triggerCampaignAction,
} from "../../ProjectSection/CampaignSection/Store/Campaign.action";
import InsightsModal from "./InsightsModal";
import BaseButton from "../../Common/Buttons/BaseButton";
import { StarCheckBox } from "../../CommonComponents/CommonComponents";
import NoticeAlert from "../../Common/Alert/NoticeAlert";
import DeletePopup from "../../CommonComponents/PopUpCards/DeletePopUp";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";
import CommonPopup from "../../CommonComponents/PopUpCards/CommonPopup";

function CampaignList({
  run,
  countSpin,
  pageIndex,
  star,
  handlePageNavigation,
  searchText,
}): JSX.Element {
  const history = useHistory();
  const reducer: any = useSelector((state) => state);
  const dispatch: Dispatch<any> = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();

  const {
    CampaignReducer: { campaigns, campaignsCount },
    AccountReducer: { selectedAccount },
  } = reducer;

  const [state, setState] = useState<any>({
    campaignsList: [],
    selectedId: "",
    show: false,
    disabledCampaign: "",
    insightsPopup: false,
    selectedForInsights: {},
    rangePopup: false,
    start: "",
    end: "",
    campaignPopupData: {},
    selecetedIdx: "",
  });
  const {
    campaignsList,
    show,
    selectedId,
    disabledCampaign,
    insightsPopup,
    selectedForInsights,
    rangePopup,
    start,
    end,
    campaignPopupData,
    selecetedIdx,
  } = state;
  const [count, setCount] = useState(0);
  const [campaignRunList, setCampaignRunList] = useState<any>([]);
  const [rangeError, setRangeError] = useState(false);
  const [errorObj, setErrorObj] = useState({
    start: false,
    end: false,
  });
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [allColumns, setAllColumns] = useState([]);
  const [rangeSpin, setRangeSpin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showInsightPopup, setShowInsightPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);

  useEffect(() => {
    if (Array.isArray(campaigns)) {
      setState((prevState) => ({
        ...prevState,
        campaignsList: campaigns,
      }));
    }
  }, [campaigns]);

  const toggleFavourite = () => {
    setShowPopUp((prev) => !prev);
  };
  const toggleInsight = () => {
    setShowInsightPopup((prev) => !prev);
  };
  const toggleDeletePopup = () => {
    setDeletePopup((prev) => !prev);
  };

  useEffect(() => {
    const columns: any = [];

    if (getCustomWriteAccess(reducer) === "write") {
      columns.push({
        label: "",
        render: (campaign) => {
          return (
            <Box className="flex-xy-center" pl={1.5}>
              {getCustomWriteAccess(reducer) === "write" && (
                <StarCheckBox
                  id={`ind1${campaign._id}`}
                  onChange={() => favouriteCampaign(campaign)}
                  checked={campaign.star}
                />
              )}
            </Box>
          );
        },
      });
    }

    columns.push(
      {
        label: "CAMPAIGN NAME",
        field: "campaignName",
        styles: { padding: 2 },
      },
      {
        label: "AUDIENCE LIST NAME",
        render: (campaign) => {
          const data = getName(campaign?.AudiencesName);
          return data?.name || "--";
        },
      },
      {
        label: "AUDIENCE COUNT",
        render: (campaign) => {
          const data = getName(campaign?.AudiencesName);
          return data?.count;
        },
      }
    );
    if (
      getCustomWriteAccess(reducer) === "write" ||
      getCustomWriteAccess(reducer, "camp")
    ) {
      columns.push({
        label: "RUN",
        render: (campaign, ind) => (
          <>
            {getCustomWriteAccess(reducer) === "write" &&
              getCustomWriteAccess(reducer) !== "read" && (
                <BaseButton
                  title="Run Campaign"
                  handleClick={() => rangeOpen(campaign, ind)}
                  disabled={
                    !!(
                      run ||
                      selectedAccount?.isPaused ||
                      ind?.toString() === disabledCampaign.toString()
                    )
                  }
                  startIcon={<PlayIcon />}
                />
              )}
            {getCustomWriteAccess(reducer) !== "write" &&
              getCustomWriteAccess(reducer, "camp") && (
                <BaseButton
                  title="Run Campaign"
                  handleClick={() => rangeOpen(campaign, ind)}
                  disabled={
                    !!(
                      run ||
                      selectedAccount?.isPaused ||
                      ind?.toString() === disabledCampaign.toString()
                    )
                  }
                  startIcon={<PlayIcon />}
                />
              )}
          </>
        ),
      });
    }

    columns.push({
      label: "ACTIONS",
      render: (campaign) => (
        <>
          {getCustomWriteAccess(reducer) === "write" && (
            <>
              <BaseButton
                title="Create Insight"
                handleClick={() => goToSaveInsightsCampaignRunList(campaign)}
                styles={{ color: "34280FF" }}
              />
              {getCustomWriteAccess(reducer, "camp") && (
                <IconButton onClick={() => goToSchedule(campaign)}>
                  <CalendarIcon title="Schedule Campaign" />
                </IconButton>
              )}
              <IconButton onClick={() => handleDelete(campaign._id)}>
                <DeleteIcon title="Delete Campaign" />
              </IconButton>
            </>
          )}
          {(getCustomWriteAccess(reducer) === "write" ||
            getCustomWriteAccess(reducer) === "read" ||
            getCustomWriteAccess(reducer, "camp")) && (
            <IconButton onClick={() => goToCampaignRunList(campaign)}>
              <RedirectIcon title="Redirect" />
            </IconButton>
          )}
        </>
      ),
    });
    setAllColumns(columns);
  }, [searchText]);

  useEffect(() => {
    if (!isEmpty(campaignRunList)) {
      let matchStr = `${start} - ${end}`;
      const find = campaignRunList.find((cam) => cam.range === matchStr);
      find ? setRangeError(true) : setRangeError(false);
    }
    if (count > 0) {
      if (!start.trim() || !end.trim()) {
        setErrorObj(() => ({
          start: start ? false : true,
          end: end ? false : true,
        }));
        return;
      } else if (Number(start) === 0 || Number(end) === 0) {
        setErrorObj(() => ({
          start: start == 0 ? true : false,
          end: end == 0 ? true : false,
        }));
        return;
      } else if (
        Number(start) < 0 ||
        Number(end) < 0 ||
        Number(end) < Number(start)
      ) {
        setErrorObj(() => ({
          start: Number(start) < 0 ? true : false,
          end: Number(end) < 0 || Number(end) < Number(start) ? true : false,
        }));
        return;
      } else {
        setErrorObj(() => ({
          start: false,
          end: false,
        }));
      }
    }
  }, [start, end]);

  const handleDelete = (id) => {
    setState((prev) => ({
      ...prev,
      selectedId: id,
      show: true,
    }));
    setIsConfirmDisabled(false);
  };

  const confirmDelete = async () => {
    setIsConfirmDisabled(true);
    setLoading(true);
    let res: any = await dispatch(deleteCampaignAction(selectedId));
    setState((prev) => ({
      ...prev,
      selectedId: "",
      show: false,
    }));
    setLoading(false);
    if (res) {
      setDeletePopup(true);
    }

    let obj = {
      pageIndex: localStorage.getItem("campaignpageIndex") || 1,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };

    if (star) {
      obj["star"] = "true";
      obj["pageIndex"] = 1;
    }
    await dispatch(getCampaignAction(projectId, obj));
    dispatch(getCampaignCountAction(projectId, obj));
  };

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      show: !prev.show,
    }));
  };

  const getName = (audiences) => {
    let name: any = [];
    let count = 0;
    audiences?.forEach((aud) => {
      name.push(aud?.audienceName);
      count = count + aud?.audienceCount;
    });
    return { name: name.join(","), count };
  };

  const finalCampaignRun1 = async (campaign, idx) => {
    setRangeSpin(true);
    setState((prev) => ({
      ...prev,
      disabledCampaign: idx,
    }));
    let obj = {
      campaignId: campaign?._id,
      projectId,
    };
    let postObj = {
      start,
      end,
      consentForCalls: true,
    };
    let res: any = await dispatch(triggerCampaignAction(obj, postObj));
    if (res) {
      let obj = {
        type: "main",
        timeStamp: res?.timeStamp,
      };
      localStorage.setItem("campaign", JSON.stringify(campaign));
      localStorage.setItem("campaignRuns", JSON.stringify(obj));
      history.push(
        `/account/project/${projectId}/campaign-runs-details?campaignid=${campaign?._id}&campaignRuns=${res?.campaignRunId}&live=true`
      );
    } else {
      setState((prev) => ({
        ...prev,
        buttonDisable: false,
      }));
      setRangeSpin(false);
    }
    setState((prev) => ({
      ...prev,
      disabledCampaign: "",
    }));
  };

  const finalCampaignRun = async (campaign, idx) => {
    swalConfirmationMessage(
      "Initiating this campaign implies you have the consent to call the provided numbers, and acceptance of our terms and conditions. Proceed only if you agree.",
      "Run",
      campaignToggle,
      async () => await finalCampaignRun1(campaign, idx)
    );
  };

  const campaignToggle = () => {
    setState((prev) => ({
      ...prev,
      disabledCampaign: "",
    }));
    setRangeSpin(false);
  };

  const rangeOpen = async (campaign, idx) => {
    if (campaign?.range === true) {
      setState((prev) => ({
        ...prev,
        rangePopup: true,
        campaignPopupData: campaign,
        selecetedIdx: idx,
        disabledCampaign: idx,
      }));
      const obj = {
        pageIndex: 1,
        pageSize: 1000,
      };
      let res = await dispatch(
        getAllCampaignRunsAction(projectId, campaign?._id, obj)
      );
      setCampaignRunList(res);
    } else {
      runCampaign("", campaign, idx, false);
    }
  };

  const runCampaign = async (e, camp, id, fromRange) => {
    if (fromRange) {
      if (!start.trim() || !end.trim()) {
        setErrorObj(() => ({
          start: start ? false : true,
          end: end ? false : true,
        }));
        return;
      }
      if (Number(start) === 0 || Number(end) === 0) {
        setErrorObj(() => ({
          start: start == 0 ? true : false,
          end: end == 0 ? true : false,
        }));
        return;
      }
      if (Number(start) < 0 || Number(end) < 0 || Number(end) < Number(start)) {
        setErrorObj(() => ({
          start: Number(start) < 0 ? true : false,
          end: Number(end) < 0 || Number(end) < Number(start) ? true : false,
        }));
        return;
      }
    }
    setRangeSpin(true);
    let campaignFin = !isEmpty(camp) ? camp : campaignPopupData;
    let id1 = id ? id : selecetedIdx;

    if (fromRange) {
      await finalCampaignRun(campaignFin, id1);
    } else {
      let res: any = await dispatch(
        checkForCampaignValidationAction(campaignFin._id)
      );

      if (res) {
        let text = "";
        if (res.callerIdPresent && res?.campaignRan) {
          text =
            "List contains Caller Id number and Campaign is already run, Do you want to proceed?";
        } else if (res?.callerIdPresent) {
          text = "List contains Caller Id number, Do you want to proceed?";
        } else if (res?.campaignRan) {
          text = "This Campaign is already run, Do you want to proceed?";
        }

        if (text) {
          swalConfirmationMessage(
            text,
            "Proceed Anyway!",
            campaignToggle,
            async () => await finalCampaignRun(campaignFin, id1)
          );
        } else {
          await finalCampaignRun(campaignFin, id1);
        }
      }
    }
  };

  const goToCampaignRunList = async (campaign) => {
    let obj = {
      campaignName: campaign?.campaignName,
      _id: campaign?._id,
      range: campaign?.range,
    };
    localStorage.setItem("campaign", JSON.stringify(obj));
    history.push(
      `/account/project/${projectId}/campaign-runs?campaignid=${campaign?._id}`
    );
  };
  const goToSaveInsightsCampaignRunList = async (campaign) => {
    setState((prev) => ({
      ...prev,
      selectedForInsights: campaign,
      insightsPopup: true,
    }));
  };
  const goToSchedule = (data) => {
    let obj = {
      campaignName: data?.campaignName,
      _id: data?._id,
      range: data?.range,
    };
    localStorage.setItem("scheduleCampaignData", JSON.stringify(obj));
    history.push(
      `/account/project/${projectId}/schedule-campaign?campaignid=${data?._id}`
    );
  };

  const toggleRangeModal = () => {
    setState((prev) => ({
      ...prev,
      rangePopup: !prev.rangePopup,
      disabledCampaign: "",
      campaignPopupData: {},
      start: "",
      end: "",
    }));
    setCampaignRunList([]);
    setRangeError(false);
  };

  const onChange = (e) => {
    setCount(count + 1);
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const favouriteCampaign = async (camp) => {
    let obj = {
      star: !camp?.star,
    };
    let res: any = await dispatch(favouriteCampaignAction(obj, camp._id));
    if (res) {
      setShowPopUp(true);
    }

    let obj1 = {
      pageIndex: localStorage.getItem("campaignpageIndex") || 1,
      pageSize: PAGE_SIZE_50,
      searchText: searchText,
    };
    const starred = Boolean(localStorage.getItem("starred"));

    if (starred) {
      obj1["star"] = "true";
      obj1["pageIndex"] = 1;
    }
    await dispatch(getCampaignAction(projectId, obj1));
    dispatch(getCampaignCountAction(projectId, obj1));
  };

  return (
    <Box>
      <DynamicTable
        columns={allColumns}
        data={campaignsList}
        showTotalRows={true}
        loading={countSpin}
        totalPages={Math.ceil(Number(campaignsCount) / PAGE_SIZE_50)}
        currentPage={pageIndex}
        handlePageChange={(event, newPage) => handlePageNavigation(newPage)}
        handlePrevious={() => handlePageNavigation(pageIndex - 1)}
        handleNext={() => handlePageNavigation(pageIndex + 1)}
        noDataAvailableMessage={"No Campaigns Available"}
        rowCount={campaignsList?.length}
        totalRowCount={campaignsCount}
      />

      {show && (
        <DeletePopup
          show={show}
          toggle={toggle}
          message={"Are you sure you want to delete the selected Campaign ?"}
          heading={"Delete Campaign"}
          handleDelete={confirmDelete}
          spin={loading}
        />
      )}

      {rangePopup && (
        <CommonModal
          show={rangePopup}
          toggle={toggleRangeModal}
          heading={`Range Data`}
          body={
            <>
              {campaignRunList?.length > 0 && (
                <Box
                  mb={1}
                  style={{
                    maxHeight: "200px",
                    overflow: "auto",
                    marginBottom: 1.5,
                  }}
                >
                  <DynamicTable
                    columns={[
                      {
                        label: "Started At",
                        render: (campaign) => converTime(campaign?.createdAt),
                      },
                      {
                        label: "Range",
                        field: "range",
                      },
                    ]}
                    data={campaignRunList}
                    showTotalRows={true}
                    handlePageChange={() => {}}
                    rowCount={campaignRunList?.length}
                  />
                </Box>
              )}

              <NoticeAlert
                title="Tips"
                variant="warning"
                content="In the “Start” box, please mention from where the audience should begin. In the “End” box, put the final number for the audience range. Example: If you want run first 100 numbers write 1 in Start box and 100 in End box."
              />
              <Box className="flex-between" gap={2} pb={1}>
                <Box flex={1}>
                  <Typography className="font-14-grey mb-2">From</Typography>
                  <TextField
                    name="start"
                    value={start}
                    onChange={onChange}
                    error={!!errorObj.start}
                    helperText={
                      errorObj.start ? "Please enter a valid start value" : ""
                    }
                    variant="outlined"
                    fullWidth
                    placeholder="Start Range Number"
                    inputProps={{
                      type: "number",
                      min: 1,
                    }}
                    sx={commonStyles.inputField}
                  />
                </Box>
                <Box flex={1}>
                  <Typography className="font-14-grey mb-2">To</Typography>
                  <TextField
                    name="end"
                    value={end}
                    onChange={onChange}
                    error={!!errorObj.end}
                    helperText={
                      errorObj.end ? "Please enter a valid end value" : ""
                    }
                    variant="outlined"
                    fullWidth
                    placeholder="End Range Number"
                    inputProps={{
                      type: "number",
                      min: 1 + Number(start),
                    }}
                    sx={commonStyles.inputField}
                  />
                </Box>
              </Box>

              {rangeError && (
                <SlimAlert
                  data="The mentioned range has already been processed; however, if you wish, you can run it again."
                  variant={"warning"}
                />
              )}
            </>
          }
          size={600}
          handleSave={() => runCampaign("", "", "", true)}
          spin={rangeSpin}
        />
      )}

      {insightsPopup && (
        <InsightsModal
          insightsPopup={insightsPopup}
          toggle={() => setState((prev) => ({ ...prev, insightsPopup: false }))}
          selectedForInsights={selectedForInsights}
          setShowPopUp={setShowInsightPopup}
        />
      )}
      {showPopUp && (
        <CommonPopup
          message={""}
          show={showPopUp}
          toggle={toggleFavourite}
          Icon={SuccessIcon}
          heading="Favourites updated successfully"
        />
      )}
      {showInsightPopup && (
        <CommonPopup
          message={""}
          show={showInsightPopup}
          toggle={toggleInsight}
          Icon={SuccessIcon}
          heading="Insights saved successfully"
        />
      )}
      {deletePopup && (
        <CommonPopup
          message={`Campaign deleted successfully`}
          show={deletePopup}
          toggle={toggleDeletePopup}
          Icon={SuccessIcon}
          heading="Campaign Deleted"
        />
      )}
    </Box>
  );
}

export default CampaignList;
