import {
  API,
  BASE_URL_AI,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const verifyEmailWithToken = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.VERIFY_EMAIL}`,
      { token: obj.token, currency: obj.currency }
    );
    return res?.data;
  } catch (err) {
    console.log(err, "verifyEmailWithToken-Service");
  }
};
export const verifyGhlWithToken = async (obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CRM.ACCOUNT_CHECK}`,
      { params: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err, "verifyGhlWithToken-Service");
  }
};

export const registerNewAccount = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.USER}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "registerNewAccount-Service");
  }
};

export const loginService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.LOGIN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "loginService-Service");
  }
};

export const directLoginService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.CRM.LOGIN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "loginService-Service");
  }
};
export const saveAccountNameService = async (data) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_API_V1}${API.V1.ACCOUNT}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "loginService-Service");
  }
};

export const checkAccountNameExistService = async (data) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CHECK_ACCOUNT_NAME}/${data}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "checkAccountNameExistService-Service");
  }
};

export const getUerAccountsDetailsService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.GET_USER_ACCOUNTS_DETAILS}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getUerAccountsDetailsService-Service");
  }
};

export const getConstantDataService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.SYSTEM_CONSTANT}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getUerAccountsDetailsService-Service");
  }
};

export const saveUserDetailsService = async (data) => {
  try {
    let res = await axiosClient.patch(
      `${BASE_URL_API_V1}${API.V1.COMMON.USER}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "loginService-Service");
  }
};

export const createPaymentOrderService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PAYMENT.ORDER}`,
      data
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const profileCheckService = async (data) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.PROFILE_CHECK}`
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const verifyOrderService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PAYMENT.VERIFY}`,
      data
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDashboardDataService = async (params) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.DASHBOARD}`,
      { params }
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getDashboardChartService = async (params) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.DASHBOARD_CHART}`,
      { params }
    );
    if (res?.status === 200) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const stripePaymentService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PAYMENT.SAVE_PAYMENT}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateStripeAccountService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PAYMENT.UPDATE_PAYMENT}`,
      { ...data }
    );
    if (res?.status === 200) {
      return res?.data;
    }
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const validateAccountService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.CHECK_AUTH}`,
      { ...data }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const resetPasswordService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.RESET_PASSWORD}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "ResetPassword-Service");
  }
};

export const changePasswordSerivce = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.UPDATE_PASSWORD}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "ResetPassword-Service");
  }
};

export const googleLoginSerivce = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.GOOGLE_LOGIN}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "ResetPassword-Service");
  }
};

export const getWhiteLabelDetailsSerivce = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CRM.WHITELABELDETAILS}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "ResetPassword-Service");
  }
};

export const getEncryptionPublicKeySerivce = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}/public-key`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "Encryption public key");
  }
};


export const lastLoginTrackService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.LAST_LOGIN_TRACK}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getUerAccountsDetailsService-Service");
  }
};

export const saveTwillioSidService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.TWILLIO.SAVE_SID}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err, "saveTwillioSidService-Service");
  }
};

export const checkGhlConnectionService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.CRM.CONNECTION_CHECK}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "saveTwillioSidService-Service");
  }
};

export const paymentHistoryCaptureService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.COMMON.PAYMENT_ENQ}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "ResetPassword-Service");
  }
};

export const createTrunkService = async (selectedAuth, obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.TWILLIO.CREATE_TRUNK}/${selectedAuth}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err, "createTrunkService-Service");
  }
};
export const getCountriesListService = async () => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.TWILLIO.COUNTRIES_LIST}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getCountriesListService-Service");
  }
};

export const getPhoneNumberService = async (friendly_name, obj) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.TWILLIO.LIST_NUMBER_TO_BUY}/${friendly_name}`,
      { params: { ...obj } }
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getPhoneNumberService-Service");
  }
};

export const buyPhoneNumberService = async (obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.TWILLIO.PURCHASE_PHONE_NUMBER}/${obj.friendly_name}/${obj.phone_number}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "buyPhoneNumberService-Service");
  }
};

export const getAlreadyAssignedNumberService = async (friendly_name) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.TWILLIO.LIST_BUYED_NUMBERS}/${friendly_name}`
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getCountriesListService-Service");
  }
};

export const setDefaultGatewayForAccountService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.DEFAULT_GATEWAY}`,
      { ...data }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const addWhatsappService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.WHATSAPP.ADD_WHATSAPP_ACCOUNT}`,
      { ...data }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getAllWhatsappService = async (ids) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.WHATSAPP.GET_WHATSAPP_ACCOUNTS}?${ids}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const getWhatsappDetailByIdService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_AI}${API.V1.WHATSAPP.GET_WHATSAPP_ACCOUNT}?id=${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const createWhatsappTemplateService = async (id, obj) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_AI}${API.V1.WHATSAPP.CREATE_WHATSAPP_TEMPLATE}/${id}`,
      { ...obj }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveFeedbackService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.FEEDBACK}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "getUerAccountsDetailsService-Service");
  }
};

export const generateVerificationCodeService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.GENERATE_CODE}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "generateVerificationCodeService-Service");
  }
};

export const verifyVerificationCodeService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.COMMON.VERIFY_CODE}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err, "verifyVerificationCodeService-Service");
  }
};

export const redirectService = async (code, projectId, type) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.GOOGLE_INTEGRATION}?code=${code}&projectId=${projectId}&type=${type}`
    );
    return res?.data;
  } catch (err) {
    console.log("Integration error: ", err);
  }
};
