import React, { KeyboardEventHandler } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { ReactComponent as ExpandIcon } from "../../../assets/Images/ExpandIcon.svg";
import {
  isVodexLoggedin,
  LoadingSlides,
  renderThumb,
} from "../CommonComponents/Common";
import ReactMarkdown from "react-markdown";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import { useHistory, useLocation } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { styles } from "../../../assets/MUIStyles/onboarding";
import { ReactComponent as SendIcon } from "../../../assets/Images/send.svg";
import { ReactComponent as VodexChatIcon } from "../../../assets/Images/VodexChatIcon.svg";
import UserIcon from "../../../assets/icons/UserIcon.png";
import OnboardingContainer from "./Container";
import { commonStyles } from "../../../assets/MUIStyles/commonStyles";
import "../../../assets/css/page/Onboarding.scss";

const ChatSlide = ({ obj }) => {
  const {
    messagesContainerRef1,
    handleSendMessage,
    messages,
    inputRef,
    textSpin,
    mainState,
    inputText,
    setInputText,
    activeIndex,
    projectId,
    messagesContainerRef,
    save,
    next,
    previous,
    spin,
  } = obj;
  const { context, agentId } = mainState;
  const location = useLocation();
  const locSearch = new URLSearchParams(location.search);
  const historyPush = useHistory();
  let from = locSearch.get("from");

  const adjustTextareaHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    }
  };

  function closeNav(id) {
    const myNav = document.getElementById(`myNav${id}`) as HTMLElement;
    if (myNav) {
      myNav.style.width = "0%";
    }
  }

  const handleKeyPressForMessage: KeyboardEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage(false);
    }
  };

  function openNav(id) {
    const myNav = document.getElementById(`myNav${id}`) as HTMLElement;
    if (myNav) {
      myNav.style.width = "100%";
    }
  }

  const openBigTextArea = (closeNav, id) => {
    return (
      <Box id={`myNav${id}`} className="overlay1 big-area-container">
        <Box className="overlay-content1 big-inner-container">
          <i
            className="fas fa-close rounded-circle cursor-pointer big-close-icon "
            onClick={closeNav}
          ></i>

          <Box sx={commonStyles.hideScroll} className="big-area-main">
            <Typography className="font-18-darkGrey" textAlign={"left"}>
              Train your AI Agent
            </Typography>

            <Typography className="font-14-darkGrey" mt={1} textAlign={"left"}>
              Your AI's got the brains, but it needs your guidance. Train your
              agent to sell like a pro
            </Typography>
            <Scrollbars
              ref={messagesContainerRef1}
              className="big-scroll-style"
              renderThumbVertical={renderThumb}
              autoHide={true}
            >
              <Box p="10px">
                {messages.map((message, ind) => (
                  <Message
                    key={ind}
                    message={message}
                    isUser={message.isUser}
                    index={ind}
                    big={true}
                  />
                ))}
              </Box>
            </Scrollbars>
            {textSpin && (
              <Box pb={0.5} className="flex-xy-center">
                <LoadingSlides bgColor="rgba(255, 94, 0, 0.9)" />
              </Box>
            )}
            {!context && (
              <form onSubmit={handleSendMessage}>
                <Box className="flex-between big-textarea">
                  <Box
                    width={"100%"}
                    maxHeight="100px"
                    sx={commonStyles.hideScroll}
                  >
                    <TextField
                      id="chat-focus"
                      fullWidth
                      multiline
                      value={inputText}
                      onChange={(e) => {
                        setInputText(e.target.value);
                        adjustTextareaHeight();
                      }}
                      inputProps={{
                        ref: inputRef,
                        onKeyDown: handleKeyPressForMessage,
                        style: { fontSize: "13px" },
                        placeholder: "Message",
                      }}
                      disabled={textSpin}
                      sx={styles.textArea}
                    />
                  </Box>
                  <button
                    className="chat-button"
                    title="Send message"
                    type="submit"
                  >
                    <SendIcon />
                  </button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <OnboardingContainer>
      <Box mx={2}>
        {openBigTextArea(
          () => closeNav(1),

          1
        )}
        <Box className="flex-between">
          <Typography className="font-18-darkGrey" textAlign={"center"}>
            Train your AI Agent
          </Typography>
          <ExpandIcon className="cursor-pointer" onClick={() => openNav(1)} />
        </Box>
        <Typography className="font-14-darkGrey" mt={1}>
          Your AI's got the brains, but it needs your guidance. Train your agent
          to sell like a pro
        </Typography>

        <Box>
          <Scrollbars
            ref={messagesContainerRef}
            className="scroll-container"
            renderThumbVertical={renderThumb}
            autoHide={true}
          >
            <Box p="10px">
              {messages.map((message, ind) => (
                <Message
                  key={ind}
                  message={message}
                  isUser={message.isUser}
                  index={ind}
                />
              ))}
            </Box>
          </Scrollbars>
        </Box>
        {textSpin && (
          <Box py={1.5} className="flex-xy-center">
            <LoadingSlides bgColor="rgba(255, 94, 0, 0.9)" />
          </Box>
        )}
        <Box>
          <Box>
            {!context && (
              <form onSubmit={handleSendMessage}>
                <Box className="flex-between small-area-container">
                  <Box
                    width={"100%"}
                    maxHeight="100px"
                    sx={commonStyles.hideScroll}
                  >
                    <TextField
                      id="chat-focus"
                      fullWidth
                      multiline
                      value={inputText}
                      onChange={(e) => {
                        setInputText(e.target.value);
                        adjustTextareaHeight();
                      }}
                      placeholder="Message"
                      inputProps={{
                        ref: inputRef,
                        onKeyDown: handleKeyPressForMessage,
                        style: { fontSize: "13px" },
                        placeholder: "Message",
                      }}
                      disabled={textSpin}
                      sx={styles.textArea}
                    />
                  </Box>
                  <button
                    className="chat-button"
                    title="Send message"
                    type="submit"
                  >
                    <SendIcon />
                  </button>
                </Box>
              </form>
            )}
            <Box
              bgcolor={"#FFFFFF"}
              borderRadius={"8px"}
              mt={2}
              mx={{ xs: 0, sm: 4 }}
            >
              {activeIndex !== 3 && (
                <Grid container spacing={2}>
                  {activeIndex !== 0 && (
                    <Grid item xs={12} sm={6}>
                      <Button
                        sx={{ ...commonStyles.clearButton, width: "100%" }}
                        onClick={previous}
                      >
                        Previous
                      </Button>
                    </Grid>
                  )}

                  {from === "dashboard" ? (
                    <Grid item xs={12} sm={6}>
                      <Button
                        disabled={spin}
                        onClick={async () => {
                          if (context.trim()) {
                            if (!agentId) {
                              await save();
                              historyPush.push(
                                `/account/project/${projectId}/agents`
                              );
                            }
                          } else {
                            swalWarningMessage(
                              "Please complete the chat first"
                            );
                            return;
                          }
                        }}
                        sx={{ ...commonStyles.saveButton, width: "100%" }}
                      >
                        {spin ? (
                          <LoadingSlides bgColor="white" />
                        ) : (
                          "Continue to Project"
                        )}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6}>
                      <Button
                        sx={{ ...commonStyles.saveButton, width: "100%" }}
                        onClick={next}
                      >
                        Save & Next
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </OnboardingContainer>
  );
};

export default ChatSlide;

const Message = ({ message, isUser, index, big = false }) => {
  return (
    <>
      <Box
        sx={{
          textAlign: isUser ? "right" : "left",
          margin: "8px 0",
        }}
      >
        <Box display="inline-block">
          <Box className="flex-row">
            {!isUser &&
              (isVodexLoggedin() ? (
                <VodexChatIcon className="mr-2" />
              ) : (
                <i
                  className="fas fa-robot rounded-circle mr-2 "
                  style={{ color: "#3A416F", fontSize: "25px" }}
                ></i>
              ))}
            <Box className="message-box">
              <Typography
                sx={{
                  margin: "0",
                  maxWidth: big ? "40vw !important" : "400px",
                  textAlign: "left",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                }}
              >
                <Box component={"span"}>
                  <ReactMarkdown>{message.text}</ReactMarkdown>
                </Box>
              </Typography>
              <small style={{ color: "black" }}>{message.timestamp}</small>
            </Box>
            {isUser && (
              <Box
                className="flex-xy-center"
                sx={{
                  bgcolor: "#F2F2F2",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  marginLeft: "10px",
                }}
              >
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={UserIcon}
                  alt=""
                />
              </Box>
            )}
          </Box>
        </Box>{" "}
        <br />
      </Box>
    </>
  );
};
