import { projectsType } from "./projects.type";

const initialState = {
  projectList: [],
  selectedProject: {},
  advanceSetting: {},
  projectAccess: [],
  leadsList: [],
};

function ProjectReducer(state = initialState, action) {
  switch (action.type) {
    case projectsType.GET_PROJECTS_LIST: {
      return { ...state, projectList: action.payload };
    }
    case projectsType.GET_SELECTED_PROJECT: {
      return { ...state, selectedProject: action.payload[0] };
    }
    case projectsType.GET_ADVANCE_SETTING: {
      return { ...state, advanceSetting: action.payload };
    }
    case projectsType.CLEAR_PROJECT_REDUCER: {
      return { ...initialState };
    }
    case projectsType.PROJECT_ACCESS: {
      return { ...state, projectAccess: action.payload };
    }
    case projectsType.GET_LEADS_LIST: {
      return { ...state, leadsList: action.payload };
    }

    default:
      return state;
  }
}

export default ProjectReducer;
