import {
  API,
  BASE_URL_API_V1,
  axiosClient,
} from "../../../Constant/apiConstant";

export const getProjectsService = async () => {
  try {
    let res = await axiosClient.get(`${BASE_URL_API_V1}${API.V1.PROJECT}`);
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectByIdService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveProjectsService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.PROJECT}`,
      data
    );

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const editProjectsService = async (data, id) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const deleteProjectsService = async (id) => {
  try {
    let res = await axiosClient.delete(
      `${BASE_URL_API_V1}${API.V1.PROJECT}/${id}`,
      {
        params: { projectId: id },
      }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getAdvanceSettingsService = async (id) => {
  try {
    let res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.ADVANCE_SETTINGS}/${id}`
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const saveAdvanceSettingsService = async (data, id) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.ADVANCE_SETTINGS}/${id}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectSheetsService = async (data) => {
  try {
    const res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.GOOGLE_SHEETS.GET_SHEET_DATA}`,
      data
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getProjectSheetsDataService = async (
  paramsdata,
  projectId,
  filters
) => {
  try {
    const res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.GOOGLE_SHEETS.GET_DATA}/${projectId}?pageIndex=${paramsdata.pageIndex}&pageSize=${paramsdata.pageSize}`,
      { filters }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const AddLeadsService = async (data) => {
  try {
    let res = await axiosClient.post(
      `${BASE_URL_API_V1}${API.V1.LEADS}`,
      data
    );

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const updateLeadsService = async (id, data) => {
  try {
    let res = await axiosClient.put(
      `${BASE_URL_API_V1}${API.V1.LEADS}/${id}`,
      data
    );

    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};


export const getLeadDataService = async (params) => {
  try {
    const res = await axiosClient.get(
      `${BASE_URL_API_V1}${API.V1.LEADS}`,
      { params: params }
    );
    return res?.data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};