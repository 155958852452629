import React, { Dispatch, useEffect, useState } from "react";
import { Box, Radio, TextField, Typography } from "@mui/material";
import Multiselect from "multiselect-react-dropdown";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getAudienceForSelectAction } from "../../ProjectSection/AudienceSection/Store/Audience.action";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";

const CampaignForm = ({ obj }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { projectId } = useParams<{ projectId: string }>();
  const { formState, setFormState } = obj;
  const [audienceArray, setAudienceArray] = useState([]);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    const init = async () => {
      const obj1 = {
        pageIndex: 1,
        pageSize: 100000,
      };
      const res: any = await dispatch(
        getAudienceForSelectAction(projectId, obj1)
      );

      setAudienceArray(res);
    };
    init();
  }, []);

  function hasFormValueChanged(e) {
    if (
      e.target.name === "name" &&
      /[^a-zA-Z0-9\&\ /]/.test(e.target.value.toString())
    ) {
      setMsg("Name can only contain alphanumeric characters, ampersand(&)");
      setTimeout(() => {
        setMsg("");
      }, 5000);
    }

    setFormState({
      ...formState,
      [e.target.name]: { value: e.target.value },
    });
  }

  const handleChangeList = (selectedValues) => {
    setFormState((prev) => ({
      ...prev,
      selectedAudiences: selectedValues,
    }));
  };

  return (
    <Box minHeight={300}>
      <Box>
        <Box>
          <Typography className="font-14-grey">Name</Typography>
          <TextField
            name="name"
            value={formState.name.value}
            onChange={hasFormValueChanged}
            variant="outlined"
            fullWidth
            placeholder="Example it can be “Real Estate Campaign”"
            sx={commonStyles.inputField}
            inputProps={{ maxLength: 50 }}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Typography className="font-14-grey mb-2">Audience</Typography>
        <Multiselect
          className="multi-select"
          options={audienceArray}
          selectedValues={formState?.selectedAudiences}
          onSelect={handleChangeList}
          onRemove={handleChangeList}
          displayValue="audienceName"
          placeholder="Select Audiences"
          avoidHighlightFirstOption={true}
          closeIcon={"circle2"}
          style={styles.multiSelect}
        />
      </Box>
      <Box mt={2}>
        <Typography className="font-14-grey mb-2">Campaign Type :</Typography>
        <Box className="flex-row" gap={1}>
          <Box className="flex-xy-center" gap={0.5}>
            <Radio
              checked={!formState?.range}
              onChange={() => {
                setFormState((prev) => ({
                  ...prev,
                  range: false,
                }));
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography className="font-14-grey" mb={0}>
              Default
            </Typography>
          </Box>
          <Box className="flex-xy-center" gap={1}>
            <Radio
              checked={formState?.range}
              onChange={() => {
                setFormState((prev) => ({
                  ...prev,
                  range: true,
                }));
              }}
              sx={{
                "& .MuiSvgIcon-root": {
                  width: 16,
                  height: 16,
                },
              }}
            />
            <Typography className="font-14-grey" mb={0}>
              Range
            </Typography>
          </Box>
        </Box>
      </Box>

      <p style={{ color: "red" }}>{msg}</p>
    </Box>
  );
};

export default CampaignForm;

const styles = {
  label: {
    color: "#1A1D23",
    fontSize: 14,
    fontWeight: "medium",
    mb: 1,
  },
  textInput: {
    "& .MuiOutlinedInput-root": {
      fontSize: 13,
      fontWeight: 400,
      borderRadius: 2,
      color: "#666666",
      borderColor: "#CCD2DE",
      borderWidth: "1px",
      transition: "border-color 0.3s ease",
    },
    "& .MuiOutlinedInput-input": {
      p: 1.5,
      "-webkit-text-fill-color": "#000 !important",
      "&::placeholder": {
        color: "#555555",
        fontSize: 13,
        fontWeight: 400,
      },
    },
  },
  listItemText: {
    color: "#555555",
    fontSize: 14,
    fontWeight: 400,
  },
  selectMenuProps: {
    PaperProps: {
      style: {
        maxHeight: 200,
        overflow: "auto",
        boxShadow: "none",
        border: "1px solid #CCD2DE",
        borderRadius: 8,
      },
    },
  },
  selectField: {
    fontSize: 13,
    fontWeight: 500,
    borderColor: "#CCD2DE",
    borderRadius: 1,
    color: "#333333",
    "& .MuiSelect-select": {
      padding: "12px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 2,
    },
  },
  multiSelect: {
    chips: {
      fontSize: "13px",
    },
    searchBox: {
      border: "1px solid #ccc",
      borderRadius: "8px",
      fontSize: "13px",
      padding: "9px 10px",
    },
    option: {
      fontSize: "13px",
    },
    optionContainer: {
      border: "1px solid #ccc",
      borderRadius: "8px",
    },
  },
};
