import { Box } from "@mui/material";
import React from "react";
import { swalConfirmationMessage } from "../../../Constant/swalMessage";
import { checkRolesAccess } from "../CommonComponents/Common";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const OnboardingContainer = ({ children }) => {
  const reducer: any = useSelector((state) => state);
  const {
    AccountReducer: { accountRoles },
  } = reducer;
  const historyPush = useHistory();

  const crossButton = () => (
    <span
      className="fas fa-times-circle"
      title="Close"
      style={{
        position: "absolute",
        right: "16px",
        top: "20px",
        cursor: "pointer",
        fontSize: "28px",
      }}
      onClick={() => {
        swalConfirmationMessage(
          "Are you sure you want to close this tab? All your progress will be lost.",
          checkRolesAccess(accountRoles) === "dashboard"
            ? "Continue to Dashboard"
            : "Continue to Projects",
          () => {},
          () => {
            if (checkRolesAccess(accountRoles) === "dashboard") {
              historyPush.push("/account/dashboard");
              localStorage.removeItem("currentSlideData");
            } else {
              historyPush.push("/account/projects");
              localStorage.removeItem("currentSlideData");
            }
          }
        );
      }}
    ></span>
  );
  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "100px auto",
      }}
    >
      {crossButton()}
      {children}
    </Box>
  );
};

export default OnboardingContainer;
