import React, { useState } from "react";
import { Box, Link, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as RedirectIcon } from "../../../assets/icons/external-link.svg";
import { ReactComponent as PlayIcon } from "../../../assets/Images/PlayIcon.svg";
import { ReactComponent as DotIcon } from "../../../assets/Images/Text.svg";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import {
  checkForCampaignValidationAction,
  triggerCampaignAction,
} from "../ProjectSection/CampaignSection/Store/Campaign.action";
import {
  swalConfirmationMessage,
  swalWarningMessage,
} from "../../../Constant/swalMessage";
import { getCustomWriteAccess, PAGE_SIZE_50 } from "../CommonComponents/Common";
import BaseButton from "../Common/Buttons/BaseButton";
import CustomScrollbars from "../CommonComponents/CustomScrollbars";

const formattedDate = (date) =>
  moment(date, "ddd, MMM D, YYYY hh:mm A").format("YYYY-MM-DD");
const formattedTime = (date) =>
  moment(date, "ddd, MMM D, YYYY hh:mm A").format("hh:mm a");

const CampaignList = ({
  lastCampaignListData,
  currentPage,
  handlePageChange,
  totalCampaigns,
  tableLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reducer: any = useSelector((state) => state);

  const [state, setState] = useState<any>({
    start: "",
    end: "",
  });
  const { start, end } = state;

  const handleRedirect = (campData) => {
    if (campData?.isDeleted) {
      swalWarningMessage("Project is Deleted.");
      return;
    } else if (campData?.campaignDeleted) {
      swalWarningMessage("Campaign is deleted.");
      return;
    } else {
      history.push(
        `/account/project/${campData?.projectId}/campaign-runs?campaignid=${campData?._id}`
      );
    }
  };

  const campaignToggle = () => {
    setState((prev) => ({
      ...prev,
      disabledCampaign: "",
    }));
  };

  const finalCampaignRun1 = async (campaign, idx) => {
    let obj = {
      campaignId: campaign?._id,
      projectId: campaign?.projectId,
    };
    let postObj = {
      start,
      end,
      consentForCalls: true,
    };
    await dispatch(triggerCampaignAction(obj, postObj));
  };

  const finalCampaignRun = async (campaign, idx) => {
    swalConfirmationMessage(
      "Initiating this campaign implies you have the consent to call the provided numbers, and acceptance of our terms and conditions. Proceed only if you agree.",
      "Run",
      campaignToggle,
      async () => await finalCampaignRun1(campaign, idx)
    );
  };

  const runCampaign = async (e, camp, id) => {
    let campaignFin = camp;
    let id1 = id;
    let res: any = await dispatch(
      checkForCampaignValidationAction(campaignFin._id)
    );

    if (res) {
      let text = "";
      if (res.callerIdPresent && res?.campaignRan) {
        text =
          "List contains Caller Id number and Campaign is already run, Do you want to proceed?";
      } else if (res?.callerIdPresent) {
        text = "List contains Caller Id number, Do you want to proceed?";
      } else if (res?.campaignRan) {
        text = "This Campaign is already running, Do you want to proceed?";
      }

      if (text) {
        swalConfirmationMessage(
          text,
          "Proceed Anyway!",
          campaignToggle,
          async () => await finalCampaignRun(campaignFin, id1)
        );
      } else {
        await finalCampaignRun(campaignFin, id1);
      }
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #E0E0E0",
        borderRadius: 2,
        padding: 2,
      }}
    >
      <Box mb={1}>
        <Typography className="font-14-grey" marginBottom={2}>
          Overall Campaign List
        </Typography>
      </Box>
      <CustomScrollbars height="50vh">
        <DynamicTable
          columns={[
            {
              label: "Campaign Name",
              field: "campaignName",
            },
            {
              label: "Last campaign Run",
              render: (campData) => {
                if (!campData?.lastCampaignRun) return "--";

                return (
                  <Box className="flex-xy-center" gap={0.5}>
                    {formattedDate(campData?.lastCampaignRun)}
                    <DotIcon />
                    {formattedTime(campData?.lastCampaignRun)}
                  </Box>
                );
              },
              styles: { textTransform: "none", textWrap: "nowrap" },
            },
            {
              label: "Project Name",
              render: (campData) => {
                if (campData?.isDeleted) {
                  return campData?.projectName;
                }
                return (
                  <Link
                    href={`/account/project/${campData?.projectId}/agents`}
                    rel="noopener noreferrer"
                    underline="hover"
                    color="#181818"
                  >
                    {campData?.projectName || "No Project Name"}
                  </Link>
                );
              },
            },
            {
              label: "Actions",
              render: (row, ind) => (
                <Box py={0.5}>
                  {(getCustomWriteAccess(reducer) === "write" ||
                    getCustomWriteAccess(reducer, "camp")) && (
                    <BaseButton
                      title="Start Campaign"
                      handleClick={() => runCampaign("", row, ind)}
                      startIcon={<PlayIcon />}
                    />
                  )}
                  <BaseButton
                    title="View List"
                    handleClick={() => handleRedirect(row)}
                    styles={{
                      color: "#666",
                      fontSize: 12,
                    }}
                    endIcon={<RedirectIcon />}
                  />
                </Box>
              ),
            },
          ]}
          showTotalRows={true}
          loading={tableLoading}
          data={lastCampaignListData}
          totalPages={Math.ceil(totalCampaigns / PAGE_SIZE_50)}
          currentPage={currentPage}
          handlePageChange={(event, newPage) => handlePageChange(newPage)}
          handlePrevious={() => handlePageChange(currentPage - 1)}
          handleNext={() => handlePageChange(currentPage + 1)}
          noDataAvailableMessage="No Campaigns  Available"
          rowCount={lastCampaignListData?.length}
          totalRowCount={totalCampaigns}
        />
      </CustomScrollbars>
    </Box>
  );
};

export default CampaignList;
