export const styles = {
    tableContainer: {
      "&.MuiPaper-rounded": {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
      boxShadow: "none",
      border: 1,
      borderBottom: 0,
      borderRadius: 2,
      borderColor: "#CCD2DE",
      maxHeight: "67vh",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
    },
    tableCell: {
      p: 1,
      textAlign: "center",
      color: "#333333",
      fontSize: 12,
      fontWeight: 500,
      textTransform: "capitalize",
      textWrap: "nowrap",
    },
  
    tableHeader: {
      p: 1.5,
      textTransform: "uppercase",
      textAlign: "center",
      color: "#666666",
      fontSize: 12,
      fontWeight: 500,
      backgroundColor: "#F8FAFE",
      textWrap: "nowrap",
    },
    callStatusButton: (lighterColor, statusColor) => {
      return {
        fontSize: 12,
        fontWeight: 500,
        textTransform: "capitalize",
        borderRadius: 2,
        backgroundColor: lighterColor,
        border: "none",
        color: statusColor,
        textWrap: "nowrap",
        "&:hover": {
          cursor: "default",
          backgroundColor: lighterColor,
          border: "none",
          color: statusColor || "#333333",
        },
      };
    },
    callStatusBox: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      mr: 0.75,
    },
    searchInput: {
      maxWidth: 230,
      "& .MuiOutlinedInput-root": {
        borderRadius: 2,
        borderWidth: "1px",
        fontSize: 14,
        fontWeight: 400,
        height: 40,
        "& fieldset": {
          borderWidth: "1px",
        },
        "&:hover fieldset": {
          borderColor: "#2F353E",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#2F353E",
          borderWidth: "1px",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: 1,
        "&::placeholder": {
          color: "#555555",
          fontSize: 13,
          fontWeight: 400,
        },
      },
    },
  
    buttons: {
      fontSize: 13,
      fontWeight: 500,
      borderRadius: 2,
      textTransform: "capitalize",
      boxShadow: "none",
      height: "40px",
      width: "40px",
      px: 0.5,
      color: "#D1D9EF",
      border: 1,
      "&:hover": {
        borderColor: "#666",
      },
    },
    commonInboundOutbound: {
      fontSize: "14px",
      fontWeight: 400,
      borderRadius: "6px",
      textTransform: "capitalize",
      boxShadow: "none",
      padding: "3px 20px",
    },
    bottomContainer: {
      p: 1.5,
      paddingLeft: 3,
      border: "1px solid #CCD2DE",
      gap: 2,
      justifyContent: "space-between",
      alignItems: "center",
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        borderRadius: "8px",
        color: "#666666",
        fontWeight: 500,
        padding: 0,
        "&.Mui-selected": {
          backgroundColor: "#F1F4FA",
          color: "#666666",
          border: 0,
          padding: 0,
        },
      },
    },
    paginationButtons: {
      textTransform: "capitalize",
      borderColor: "#CCD2DE",
      color: "#666666",
      fontWeight: 500,
      fontSize: 12,
      borderRadius: 2,
      padding: "4px 20px",
      "&:hover": { borderColor: "#5AA552" },
    },
    menu: {
      marginTop: 1,
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      minWidth: "180px",
      minHeight: "110px",
      borderRadius: "8px",
      border: "1px solid #CCD2DE",
    },
    menuItem: {
      m: 0.5,
      height: "40px",
      borderRadius: "8px",
      fontWeight: 400,
      color: "#333333",
      fontSize: "14px",
      "&:hover": {
        background: "#DDE8FF",
      },
    },
    noDataContainer: {
      my: 1,
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      justifyContent: "center",
      alignItems: "center",
      gap: 2,
      p: 3,
      position: "relative",
    },
    noDataText: {
      fontSize: 20,
      fontWeight: 500,
      color: "#555555",
      textTransform: "capitalize",
      textAlign: "center",
      position: "absolute",
      top: 90,
      left: "50%",
  
      transform: "translateX(-50%)",
    },
    live: {
      fontSize: 12,
      fontWeight: 400,
      textTransform: "none",
      borderRadius: 2,
      backgroundColor: "#249422",
      boxShadow: "none",
      minWidth: "auto",
      height: "auto",
      border: "none",
      color: "#fff",
      padding: "1px 8px",
      display: "flex",
      "&:hover": {
        cursor: "default",
        backgroundColor: "#249422",
        boxShadow: "none",
        border: "none",
        color: "#fff",
      },
    },
    iconBtn: {
      minWidth: "auto",
      p: "10px",
      borderColor: "#D1D9EF",
      "&:hover": {
        borderColor: "#666",
      },
    },
    loaderContainer: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      zIndex: 10,
    },
  };
  
  