import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import logo from "../../../assets/Images/LoginLogo.svg";
import tickIcon from "../../../assets/icons/tick.svg";
import "../../../assets/css/page/Auth.scss";
const benefits = [
  { text: "Scalable Outreach" },
  { text: "Superior Efficiency" },
  { text: "Dedicated support" },
];

const AuthLayout = ({ children }) => {
  return (
    <Grid container className="auth-container">
      <Grid item md={6.5} className="left-section">
        <Box component="img" src={logo} alt="Logo" className="logo" />

        <Box className="text-box">
          <Typography
            color="white"
            fontSize={{ xs: 30, lg: 40 }}
            fontWeight={500}
          >
            AI Voice Agents
          </Typography>
          <Typography
            color="white"
            fontSize={{ xs: 30, lg: 40 }}
            fontWeight={500}
          >
            Redefining Enterprise Outreach.
          </Typography>

          <Box className="border-bottom" />

          <Box className="benefits-list">
            {benefits.map((benefit, index) => (
              <Typography key={index} fontSize={14} className="benefit-item">
                <img src={tickIcon} height={16} width={16} alt="tick" />
                {benefit.text}
              </Typography>
            ))}
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={5.5} className="right-section">
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
