import React from "react";
import { FilterList } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as ExportIcon } from "../../../assets/icons/export.svg";
import BaseButton from "../Common/Buttons/BaseButton";

const PatientTableHeader = ({
  handlePopoverOpen,
  exportToCSV,
  exportLoading,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={1}
    >
      <Typography className="font-14-grey">Overall Leads List</Typography>
      <Box display="flex" gap={1}>
        <BaseButton
          title="Export"
          variant="outlined"
          startIcon={<ExportIcon height={14} width={14} />}
          loading={exportLoading}
          handleClick={exportToCSV}
          styles={{ px: 2, fontSize: 12 }}
        />

        <IconButton onClick={handlePopoverOpen}>
          <FilterList />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PatientTableHeader;
