import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Button, IconButton } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/Images/Delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/Images/EditIcon.svg";
import { ReactComponent as DownLoadIcon } from "../../../assets/Images/DownloadIcon.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/Images/SuccessProjectIcon.svg";
import {
  createExcel,
  getCustomWriteAccess,
  isEmpty,
  PAGE_SIZE_50,
} from "../CommonComponents/Common";
import DynamicTable from "../CommonComponents/Table/DynamicTable";
import {
  deleteAudienceAction,
  editAudienceAction,
  getAudienceAction,
} from "../ProjectSection/AudienceSection/Store/Audience.action";
import ViewAudience from "./ViewAudience";
import { swalWarningMessage } from "../../../Constant/swalMessage";
import DeletePopup from "../CommonComponents/PopUpCards/DeletePopUp";
import { AudienceType } from "../ProjectSection/AudienceSection/Store/Audience.type";
import CommonPopup from "../CommonComponents/PopUpCards/CommonPopup";

type Audience = {
  _id: string;
  audienceName: string;
  audienceList: Array<{
    firstName: string;
    lastName: string;
    phone: string;
  }>;
  source: string;
  audienceHeader: any;
};

type AudienceTableSectionProps = {
  setFormState: (formState: any) => void;
  setMainState: (mainState: any) => void;
  viewListShow: boolean;
  handlePageNavigation: (pageIndex: number) => void;
  pageIndex: number;
};

type State = {
  audienceList: Audience[];
  selectedId: string;
  show: boolean;
  spin: boolean;
  selectedAud: Audience | null;
};

function AudienceTableSection({
  setFormState,
  setMainState,
  viewListShow,
  handlePageNavigation,
  pageIndex,
}: AudienceTableSectionProps): JSX.Element {
  const reducer: any = useSelector((state: any) => state);
  const dispatch: Dispatch<any> = useDispatch();
  const {
    AudienceReducer: { audienceCount, audiences },
  } = reducer;
  const { projectId } = useParams<{ projectId: string }>();

  const [data, setData] = useState<string[]>([]);
  const [headerList, setHeaderList] = useState<any>([]);

  const [state, setState] = useState<State>({
    audienceList: [],
    selectedId: "",
    show: false,
    spin: false,
    selectedAud: null,
  });
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteContactPopup, setDeleteContactPopup] = useState(false);
  const [showContactDelete, setShowContactDelete] = useState(false);

  const { audienceList, show, selectedId, spin, selectedAud } = state;

  const audienceData = selectedAud?.audienceList || [];

  useEffect(() => {
    if (Array.isArray(audiences)) {
      setState((prevState) => ({
        ...prevState,
        audienceList: audiences,
      }));
    }
  }, [audiences]);

  useEffect(() => {
    if (!isEmpty(audienceData)) {
      let header = audienceData[0];
      let headerListLocal: any = [];
      let arr = [
        "firstName",
        "lastName",
        "FirstName",
        "LastName",
        "phone",
        "PhoneNum",
      ];
      if (selectedAud?.audienceHeader) {
        for (const key of selectedAud?.audienceHeader) {
          if (!arr.includes(key)) {
            headerListLocal.push(key);
          }
        }
      } else {
        for (const key in header) {
          if (!arr.includes(key)) {
            headerListLocal.push(key);
          }
        }
      }
      setHeaderList(headerListLocal);
    }
    return () => {};
  }, [selectedAud]);

  const deleteData = (checked: boolean, phone: string) => {
    const dataCopy = [...data];
    if (checked) {
      dataCopy.push(phone);
    } else {
      const index = dataCopy.findIndex((ph) => ph === phone);
      if (index !== -1) dataCopy.splice(index, 1);
    }
    setData(dataCopy);
  };

  const saveDeletedData = async () => {
    setShowContactDelete(false);
    if (data?.length === audienceData?.length) {
      swalWarningMessage("Cannot delete all the contacts");
      return;
    }

    if (data.length > 0) {
      const newData = audienceData.filter((aud) => !data.includes(aud.phone));
      const updatedAudience = {
        audienceListName: selectedAud?.audienceName || "",
        audienceList: newData,
        source: selectedAud?.source || "",
        projectId: projectId || "",
      };

      const res: any = await dispatch(
        editAudienceAction(selectedAud?._id, updatedAudience)
      );
      if (res) setDeleteContactPopup(true);

      const paginationObj = {
        pageIndex: Number(localStorage.getItem("audiencePageIndex")) || 1,
        pageSize: PAGE_SIZE_50,
      };

      await dispatch(getAudienceAction(projectId || "", paginationObj));

      setMainState((prev) => ({
        ...prev,
        viewListShow: false,
      }));

      setState((prev) => ({
        ...prev,
        selectedId: "",
        spin: false,
        show: false,
      }));
      setData([]);
    } else {
      swalWarningMessage("No contacts selected");
    }
  };

  const handleDelete = (id: string) => {
    setState((prev) => ({
      ...prev,
      selectedId: id,
      show: true,
    }));
  };

  const confirmDelete = async () => {
    setState((prev) => ({
      ...prev,
      spin: true,
    }));

    let res: any = await dispatch(deleteAudienceAction(selectedId));

    setState((prev) => ({
      ...prev,
      selectedId: "",
      spin: false,
      show: false,
    }));

    if (res) setDeletePopup(true);

    const paginationObj = {
      pageIndex: Number(localStorage.getItem("audiencePageIndex")) || 1,
      pageSize: PAGE_SIZE_50,
      searchText: localStorage.getItem("searchTextAud") || "",
    };

    await dispatch(getAudienceAction(projectId || "", paginationObj));
  };

  const handleView = async (aud: Audience) => {
    setData([]);
    const formObj = {
      name: { error: "", value: aud.audienceName },
      audienceData:
        aud.audienceList.length > 0
          ? aud.audienceList
          : [{ firstName: "", lastName: "", phone: "" }],
      source: { error: "", value: aud.source },
    };
    setFormState(formObj);
    setMainState((prev) => ({
      ...prev,
      viewListShow: true,
    }));
    setState((prev) => ({
      ...prev,
      selectedAud: aud,
    }));

    await dispatch({
      type: AudienceType.GET_AUDIENCE_DATA,
      payload: {
        totalCount: aud?.audienceList?.length || 0,
      },
    });
  };

  const handleEdit = (aud: Audience) => {
    localStorage.setItem("fromDuplicate", "false");
    localStorage.setItem("edit", "true");

    const formObj = {
      name: { error: "", value: aud.audienceName },
      audienceData:
        aud.audienceList.length > 0
          ? aud.audienceList
          : [{ firstName: "", lastName: "", phone: "" }],
      source: { error: "", value: aud.source },
    };

    setFormState(formObj);
    setMainState((prev) => ({
      ...prev,
      editedAudience: aud,
      show: true,
    }));
  };

  const downloadExcel = async (row) => {
    let copyList = row?.audienceList;
    if (copyList?.length > 0) {
      let final: any = [];
      for (const item of copyList) {
        let obj: any = {
          FirstName: item.firstName,
          LastName: item.lastName,
          PhoneNum: item.phone,
        };

        obj = { ...obj, ...item };
        delete obj.firstName;
        delete obj.lastName;
        delete obj.phone;
        final.push(obj);
      }

      await createExcel(final, `Audience List file`);
    }
  };

  const toggle = () => {
    setState((prev) => ({
      ...prev,
      show: !prev.show,
    }));
  };

  const toggleDeletePopup = () => setDeletePopup((prev) => !prev);

  const toggleViewList = async () => {
    const paginationObj = {
      pageIndex: Number(localStorage.getItem("audiencePageIndex")) || 1,
      pageSize: PAGE_SIZE_50,
    };

    await dispatch(getAudienceAction(projectId || "", paginationObj));
    setMainState((prev) => ({
      ...prev,
      viewListShow: false,
    }));
    setData([]);
  };

  return (
    <div>
      {viewListShow ? (
        <ViewAudience
          toggleViewList={toggleViewList}
          deleteData={deleteData}
          setShowContactDelete={setShowContactDelete}
          audienceData={audienceData}
          headerList={headerList}
        />
      ) : (
        <DynamicTable
          columns={[
            {
              label: "NAME",
              field: "audienceName",
            },
            {
              label: "Total Contacts",
              render: (row: Audience) => row?.audienceList?.length || "--",
            },
            {
              label: "View",
              render: (row: Audience) => {
                return (
                  <Button
                    sx={{
                      color: "#4280FF",
                      fontSize: 12,
                      fontWeight: 500,
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleView(row)}
                  >
                    View List
                  </Button>
                );
              },
            },
            {
              label: "ACTIONS",
              render: (row: Audience) => (
                <>
                  <Box className="flex-xy-center" gap={0.5}>
                    <IconButton
                      sx={{ padding: 0, gap: 1 }}
                      disableRipple
                      disableFocusRipple
                      style={{ outline: "none" }}
                      onClick={() => downloadExcel(row)}
                    >
                      <DownLoadIcon />
                      <Box
                        component="span"
                        color="#333"
                        fontSize={12}
                        fontWeight={500}
                      >
                        Download
                      </Box>
                    </IconButton>
                    {getCustomWriteAccess(reducer) === "write" && (
                      <>
                        <IconButton title="Edit Audience" onClick={() => handleEdit(row)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton title="Delete Audience" onClick={() => handleDelete(row._id)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </>
              ),
            },
          ]}
          data={audienceList}
          showTotalRows={true}
          loading={spin}
          totalPages={Math.ceil(Number(audienceCount) / PAGE_SIZE_50)}
          currentPage={Number(pageIndex)}
          handlePageChange={(event, newPage) => handlePageNavigation(newPage)}
          handlePrevious={() => handlePageNavigation(pageIndex - 1)}
          handleNext={() => handlePageNavigation(pageIndex + 1)}
          noDataAvailableMessage={"No Audiences Available"}
          rowCount={audienceList?.length}
          totalRowCount={audienceCount}
        />
      )}

      {show && (
        <DeletePopup
          show={show}
          toggle={toggle}
          message={"Are you sure you want to delete the selected Audience ?"}
          heading={"Delete Audience"}
          handleDelete={confirmDelete}
          spin={spin}
        />
      )}
      {deletePopup && (
        <CommonPopup
          message={`Audience deleted successfully`}
          show={deletePopup}
          toggle={toggleDeletePopup}
          Icon={SuccessIcon}
          heading="Audience Deleted"
        />
      )}

      {showContactDelete && (
        <DeletePopup
          show={showContactDelete}
          toggle={() => setShowContactDelete(false)}
          message={"Are you sure you want to delete the selected Contacts ?"}
          heading={"Delete Contact"}
          handleDelete={saveDeletedData}
          spin={spin}
        />
      )}
      {deleteContactPopup && (
        <CommonPopup
          message={`Contacts deleted successfully`}
          show={deleteContactPopup}
          toggle={() => setDeleteContactPopup(false)}
          Icon={SuccessIcon}
          heading="Contacts Deleted"
        />
      )}
    </div>
  );
}

export default AudienceTableSection;
