import React from "react";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import { ReactComponent as ArrowBack } from "../../../../assets/icons/arrow-back.svg";
import { ReactComponent as Dot } from "../../../../assets/Images/Text.svg";
import BaseButton from "../../Common/Buttons/BaseButton";
import { converTime } from "../../CommonComponents/Common";
import { shortenString } from "../../../../Constant/helper";

const CampaignRunsHeader = ({ campaign, handleBack, handleViewAllRuns }) => {
  const campaignDate = converTime(campaign?.updatedAt);

  return (
    <Box className="flex-between">
      <Box className="flex-column" gap={1}>
        <Typography className="font-20-grey">Campaigns Runs</Typography>
        <Box mt={0.5} className="flex-align-center">
          <Typography
            className="font-16-lightgrey-2"
            textTransform={"capitalize"}
          >
            {shortenString(campaign?.campaignName, 30)} -
          </Typography>
          <Typography
            component={"span"}
            className="ml-1 flex-align-center font-400-14-lightgrey"
          >
            {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("Do MMM YYYY")}
            <Dot className="ml-1 mr-1" />
            {moment(campaignDate, "YYYY-MM-DD hh:mm a").format("hh:mm a")}
          </Typography>
        </Box>
      </Box>
      <Box className="flex-xy-center" alignContent="justify-start" gap={1.5}>
        <BaseButton
          title="View all runs"
          variant="outlined"
          handleClick={handleViewAllRuns}
          styles={{ color: "34280FF" }}
        />
        <BaseButton
          title="Back"
          variant="contained"
          handleClick={handleBack}
          startIcon={<ArrowBack className="back-icon" />}
        />
      </Box>
    </Box>
  );
};

export default CampaignRunsHeader;
