import React, { useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Box, ListItem, Typography } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import { commonStyles } from "../../../../assets/MUIStyles/commonStyles";
import { useSelector } from "react-redux";

const SearchBar = ({ obj }) => {
  const {
    handleSearchBar,
    isVisible,
    options,
    setInputValue,
    inputValue,
    setShowSettings,
    setShowAdmin,
    visitedItems,
    setVisitedItems,
    setSearchModal,
  } = obj;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const reducer = useSelector((reducer: any) => reducer);
  const {
    ProjectReducer: { projectList },
  } = reducer;

  useEffect(() => {
    const visitedLoc: any = localStorage.getItem("visitedItems");
    const visitedItemsArr = JSON.parse(visitedLoc) || [];
    setVisitedItems(visitedItemsArr);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        handleSearchBar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (e) => {
    if (e) {
      if (e.value === "/account/access") {
        setShowSettings(true);
        setShowAdmin(false);
      } else if (e.value === "/account/admin") {
        setShowAdmin(true);
        setShowSettings(false);
      } else {
        setShowSettings(false);
        setShowAdmin(false);
      }

      let visitedLoc: any = localStorage.getItem("visitedItems");
      let visitedItems = JSON.parse(visitedLoc) || [];

      const newItem = { name: e.value, label: e.label };

      if (!visitedItems.some((item) => item.name === newItem.name)) {
        visitedItems.push(newItem);

        localStorage.setItem("visitedItems", JSON.stringify(visitedItems));
        setVisitedItems(visitedItems);
      }

      history.push(e.value);
      setSearchModal(false);
    }
  };

  return createPortal(
    <Box
      ref={containerRef}
      className="main-searchbar-container"
      sx={{ opacity: isVisible ? 1 : 0, boxShadow: 24 }}
    >
      <Box height="365px" sx={commonStyles.hideScroll}>
        <Select
          options={options}
          isSearchable={true}
          inputValue={inputValue}
          onInputChange={(value) => {
            if (value.length > 20) {
              return;
            }
            setInputValue(value);
          }}
          value={options?.find((option) => option?.value === inputValue)}
          placeholder="Search..."
          className="basic-single project-select"
          classNamePrefix="select"
          getOptionLabel={(e) => e?.label}
          getOptionValue={(e) => e?.value}
          onChange={(e) => handleSelect(e)}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          styles={commonStyles.customSelect}
        />
        <Box mb={1.5} className="flex-between" mt={2}>
          <Box className="flex-xy-center">
            <HistoryIcon
              sx={{ height: "15px", width: "15px" }}
              className="mr-2"
            />
            <Typography className="font-14-grey" ml={1}>
              Recently Visited
            </Typography>
          </Box>
          <Box
            component={"span"}
            onClick={() => {
              localStorage.removeItem("visitedItems");
              setVisitedItems([]);
            }}
            className="font-13-darkgrey cursor-pointer"
            ml={1}
          >
            Clear All
          </Box>
        </Box>
        <Box component={"span"} className="font-13-darkgrey">
          {visitedItems?.length > 0 ? (
            visitedItems.map((item, index) => {
              const projectId = item.name.split("/")[3];
              let displayLabel = item.label;

              if (projectId) {
                const project = projectList?.find((p) => p?._id === projectId);
                if (project) {
                  displayLabel = `${item.label} (${project.projectName})`;
                }
              }
              return (
                <ListItem
                  key={index}
                  className="cursor-pointer"
                  onClick={() => history.push(item.name)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                      transition: "background-color 0.3s ease",
                      borderRadius: 2,
                    },
                  }}
                >
                  {displayLabel}
                </ListItem>
              );
            })
          ) : (
            <Box
              mt={1.5}
              className="font-13-darkgrey flex-xy-center no-activity"
              component={"span"}
            >
              No recent activity
            </Box>
          )}
        </Box>
      </Box>
    </Box>,
    document.body
  );
};

export default SearchBar;
